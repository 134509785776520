import React, { Component } from 'react'
import { Row, Col, PopoverBody, Button, Popover, Input } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

class HedgingPlanPopover extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: true,
      value: props.isMax ? props.segment.max : props.segment.min,
    }
  }

  setSegment = (e) => {
    e.preventDefault()
    const segment = this.props.segment
    if (this.props.isMax) {
      segment.max = +this.state.value
    } else {
      segment.min = +this.state.value
    }
    this.props.setSegment(segment)
    this.props.togglePopover()
  }

  deleteSegment = () => {
    this.props.deleteSegment(this.props.segment)
    this.props.togglePopover()
  }

  onChangeValue = (e) => {
    const value = e.target.value
    let isValid = true

    // Find closest segment to check if the input value is valid or not
    const closest = {
      closestPreviousSegment: null,
      closestNextSegment: null,
    }
    this.props.segmentList.forEach((segment) => {
      if (segment.moisRelatif < this.props.segment.moisRelatif) {
        closest.closestPreviousSegment =
          !closest.closestPreviousSegment ||
          closest.closestPreviousSegment.moisRelatif < segment.moisRelatif
            ? segment
            : closest.closestPreviousSegment
      } else if (segment.moisRelatif > this.props.segment.moisRelatif) {
        closest.closestNextSegment =
          !closest.closestNextSegment ||
          closest.closestNextSegment.moisRelatif > segment.moisRelatif
            ? segment
            : closest.closestNextSegment
      }
    })

    if (this.props.isMax) {
      isValid =
        (!closest.closestPreviousSegment || value >= closest.closestPreviousSegment.max) &&
        (!closest.closestNextSegment || value <= closest.closestNextSegment.max)
    } else {
      isValid =
        (!closest.closestPreviousSegment || value >= closest.closestPreviousSegment.min) &&
        (!closest.closestNextSegment || value <= closest.closestNextSegment.min)
    }

    this.setState({
      isValid,
      value,
    })
  }

  render() {
    return (
      <Popover
        placement="left"
        isOpen={this.props.isOpen}
        target={`hedgingPlanPoint-${this.props.segment.id}-${this.props.isMax ? 'max' : 'min'}`}
        toggle={this.props.togglePopover}
      >
        <PopoverBody>
          <form onSubmit={this.setSegment}>
            <Row>
              <Col>
                <Button close onClick={this.props.togglePopover} />
                <label className="title">
                  {this.props.t('strategy.hedgingPlanPopover.value')}{' '}
                  {this.props.isMax
                    ? this.props.t('strategy.hedgingPlanPopover.max')
                    : this.props.t('strategy.hedgingPlanPopover.min')}
                </label>
                <Input
                  value={this.state.value}
                  type="number"
                  step="any"
                  min="0"
                  max="100"
                  // type="text"
                  name="value"
                  onChange={this.onChangeValue}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  disabled={!this.state.isValid}
                  className="float-right rounded"
                  color="primary"
                  type="submit"
                >
                  {this.props.t('strategy.hedgingPlanPopover.edit')}
                </Button>
                <Button
                  disabled={!this.state.isValid}
                  className="float-right rounded"
                  color="danger"
                  onClick={this.deleteSegment}
                >
                  {this.props.t('strategy.hedgingPlanPopover.delete')}
                </Button>
              </Col>
            </Row>
          </form>
        </PopoverBody>
      </Popover>
    )
  }
}

HedgingPlanPopover.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  togglePopover: PropTypes.func.isRequired,
  setSegment: PropTypes.func.isRequired,
  deleteSegment: PropTypes.func.isRequired,
  segment: PropTypes.object,
  segmentList: PropTypes.array.isRequired,
  isMax: PropTypes.bool,
}

export default withTranslation()(HedgingPlanPopover)
