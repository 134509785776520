import React from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import HighchartsReact from 'highcharts-react-official'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { Highcharts } from 'helpers/highcharts'
import colors from 'colors'

const config = ({
  currentSelectionMonthlyContractCoverage: data,
  t,
  print = false,
  height = null,
}) => {
  const monthlyData = data.histo.series[0].data

  const coverageFixed = monthlyData.map((cov) => [
    cov.x,
    cov.pourcentageFermeFixe - cov.pourcentageFermeFixeArenh,
  ])

  const coverageFixedArenh = monthlyData.map((cov) => [cov.x, cov.pourcentageFermeFixeArenh])

  const coverageFloating = monthlyData.map((cov) => [cov.x, cov.pourcentageFermeFlottant])

  const coverageTotal = monthlyData.map((cov) => [
    cov.x,
    cov.pourcentageFermeFlottant + cov.pourcentageFermeFixe,
  ])

  const coverageFill = coverageTotal.map((av) => [av[0], 100 - av[1]])

  let series = [
    {
      name: t('execution.monthlyCoverageGraph.opened'),
      data: coverageFill,
      type: 'column',
      color: '#A8C11F',
      borderWidth: 0,
      borderColor: colors.color6,
      zIndex: 0,
      dataLabels: {
        enabled: false,
      },
    },
    {
      name: t('execution.monthlyCoverageGraph.coveredFloating'),
      data: coverageFloating,
      type: 'column',
      opacity: 1,
      color: '#6C6B6A',
      borderWidth: 0,
      borderColor: colors.color6,
      zIndex: 0,
      dataLabels: {
        enabled: false,
      },
    },
    {
      name: t('execution.monthlyCoverageGraph.coveredFixed'),
      data: coverageFixed,
      type: 'column',
      opacity: 1,
      color: '#A81815',
      borderWidth: 0,
      borderColor: colors.color6,
      zIndex: 0,
      dataLabels: {
        enabled: false,
      },
    },
  ]

  const hasArenh = get(data, 'syntheseTotal.pourcentageFermeFixeArenh', 0) !== 0

  if (hasArenh) {
    series.push({
      name: `${t('execution.monthlyCoverageGraph.coveredFixed')} Arenh`,
      data: coverageFixedArenh,
      type: 'column',
      opacity: 1,
      color: {
        pattern: {
          path: {
            d:
              'M -1 -1 L 11 11 L 11 -1 Z M -1 -1 L 11 11 L -1 11 Z M 9 -1 L 11 1 Z M -1 9 L 1 11 Z',
            stroke: colors.color8,
            strokeWidth: 1,
            fill: colors.color6,
          },
          width: 10,
          height: 10,
        },
      },
      borderWidth: 0,
      borderColor: colors.color8,
      zIndex: 0,
      dataLabels: {
        enabled: false,
      },
    })
  }

  return {
    chart: {
      height,
      style: {
        fontFamily: 'Muli, sans-serif',
        fontSize: print ? 10 : 12,
      },
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: !print,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: print ? '10px' : '12px',
        },
      },
      ...(print && {
        tickInterval: 30 * 60 * 60 * 24 * 1000,
        labels: {
          step: 1,
          // rotation: -45,
          formatter: function () {
            const m = moment(this.value)
            return `${m.format('MM')}<br />${m.format('YY')}`
          },
          style: {
            fontSize: '8px',
          },
        },
      }),
    },
    yAxis: [
      {
        tickAmount: 6,
        min: 0,
        max: 100,
        title: {
          enabled: !print,
          text: `${t('execution.monthlyCoverageGraph.coverage')} %`,
        },
        labels: {
          formatter: function () {
            return print ? `${this.value}%` : this.value
          },
          style: {
            fontSize: print ? '10px' : '12px',
          },
        },
      },
    ],
    tooltip: {
      pointFormat: '<b>{point.series.name}</b> : {point.y:.2f}<br/>',
      crosshairs: true,
      shared: true,
    },
    legend: {
      enabled: true,
      margin: print ? 10 : 30,
      squareSymbol: false,
      symbolRadius: 0,
      symbolWidth: print ? 10 : 26,
      symbolHeight: print ? 6 : 18,
      symbolPadding: print ? 4 : 10,
      itemStyle: {
        fontSize: print ? '8px' : '12px',
        fontWeight: 'normal',
        color: '#666666',
        transform: print ? 'none' : 'translateY(-3px)',
      },
      itemDistance: print ? 10 : undefined,
    },
    plotOptions: {
      series: {
        animation: false,
        enableMouseTracking: !print,
      },
      column: {
        stacking: 'normal',
        pointPadding: print ? -0.1 : 0,
        groupPadding: print ? 0.2 : 0.1,
        fillOpacity: print ? 0.5 : 1,
      },
    },
    series,
  }
}

const MonthlyCoverageGraph = (props) => {
  if (isEmpty(props.currentSelectionMonthlyContractCoverage)) return null
  const options = config(props)
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default withTranslation()(MonthlyCoverageGraph)
