import React, { Component } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import HedgingPlan from './HedgingPlan'
import { fetchHedgingPlan, addHedgingPlan } from '../../../actions/hedgingPlan'
import { getHedgingPlans } from '../../../reducers/hedgingPlan'

class HedgingPlans extends Component {
  componentDidMount() {
    this.props.fetchHedgingPlan(this.props.parcel.id)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldProps = this.props

    if (
      (oldProps.parcel && newProps.parcel && oldProps.parcel.id !== newProps.parcel.id) ||
      (!oldProps.parcel && newProps.parcel)
    ) {
      this.props.fetchHedgingPlan(newProps.parcel.id)
    }
  }

  addNewHedgingPlan = () => {
    this.props.addHedgingPlan(this.props.parcel.id)
    this.props.setIsAddingNewHedgingPlan(true)
  }

  closeAddPlan = () => {
    this.props.setIsAddingNewHedgingPlan(false)
  }

  getProgress = () => {
    const { hedges } = this.props
    return hedges.reduce((x, h) => (h.planCouvertureId ? x + 1 : x), 0)
  }

  render() {
    const { contract, parcel, hedgingPlans, isAddingNewHedgingPlan, hedges, readOnly } = this.props
    const count = this.getProgress()
    const total = hedges.length

    return (
      <div id="hedgingPlans">
        <section>
          <Row>
            <Col>
              <h4>{this.props.t('header.strategy.hedgingPlan')}</h4>
            </Col>
          </Row>

          {hedgingPlans.map((hedgingPlan, index) => (
            <HedgingPlan
              contract={contract}
              parcel={parcel}
              hedgingPlan={hedgingPlan}
              key={`hedging-plan-${hedgingPlan.id}`}
              {...(!isAddingNewHedgingPlan || index !== hedgingPlans.length - 1
                ? {}
                : {
                    closeAddPlan: this.closeAddPlan,
                  })}
              readOnly={readOnly}
            />
          ))}

          {!readOnly && (count < total || count === 0) && (
            <Row className="add-hedging-plan">
              <Col className="add-hedging-plan-progress">
                {this.props.t('strategy.hedgingPlan.progress', {
                  count,
                  plural: count > 1 ? 's' : '',
                  total,
                })}
              </Col>
              <Col className="text-right">
                {!isAddingNewHedgingPlan && (
                  <Button color="secondary" className="rounded" onClick={this.addNewHedgingPlan}>
                    {this.props.t('strategy.hedgingPlan.add')}
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </section>
      </div>
    )
  }
}

HedgingPlans.propTypes = {
  contract: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { contract, parcel }) => ({
  hedgingPlans: getHedgingPlans(state, parcel.id) || [],
  hedges: state.hedgingProfile.parcelHedges[parcel.id] || [],
})

const mapDispatchToProps = (dispatch) => ({
  fetchHedgingPlan: (parcelId) => {
    dispatch(fetchHedgingPlan(parcelId))
  },
  addHedgingPlan: (parcelId) => {
    dispatch(addHedgingPlan(parcelId, 12))
  },
})

export default compose(
  withTranslation(),
  withState('isAddingNewHedgingPlan', 'setIsAddingNewHedgingPlan', false),
  connect(mapStateToProps, mapDispatchToProps),
)(HedgingPlans)
