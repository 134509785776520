/** @jsxImportSource @emotion/react */
import { useState, useCallback, useRef, useMemo } from 'react'
import { Label, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactTable from 'react-table'
import selectTableHOC from 'react-table/lib/hoc/selectTable'

import {
  toggleByParcel,
  getByParcel,
  getStartYear,
  getEndYear,
  setSelection,
  getSelection,
} from 'store/reporting'
import { getContractList } from 'reducers/contracts'
import { TableSpinner } from 'components/common/Spinner'

const SelectTreeTable = selectTableHOC(ReactTable)

const SelectInputComponent = ({ selectType, onClick, id, row, checked }) => {
  const isHeader = !row
  return (
    <Label
      className={`form-check-label ${checked ? ' checked' : ''}`}
      css={{
        fontWeight: 400,
        textTransform: 'lowerCase',
        '&:before': { marginRight: 0 },
      }}
    >
      <Input type={selectType} onChange={() => onClick(isHeader ? id : row.id)} checked={checked} />
    </Label>
  )
}

const getNodes = (data, node = []) => {
  data.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, '_subRows') && item._subRows) {
      node = getNodes(item._subRows, node)
    } else {
      node.push(item._original)
    }
  })
  return node
}

export const ReportingSelection = () => {
  const dispatch = useDispatch()
  const startYear = useSelector(getStartYear)
  const endYear = useSelector(getEndYear)
  const contracts = useSelector(getContractList)
  const byParcel = useSelector(getByParcel)
  const selection = useSelector(getSelection)

  const tableRef = useRef(null)
  const [selectAll, setSelectAll] = useState(false)

  const toggle = useCallback((id) => () => dispatch(toggleByParcel(id)), [dispatch])

  const isSelected = useCallback((key) => selection.includes(key), [selection])

  const toggleAll = useCallback(() => {
    if (selectAll) {
      dispatch(setSelection([]))
    } else {
      const wrappedInstance = tableRef.current.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      const nodes = getNodes(currentRecords)
      dispatch(setSelection(nodes.map((item) => item.id)))
    }
    setSelectAll((selectAll) => !selectAll)
  }, [dispatch, selectAll])

  const toggleSelection = useCallback(
    (key) => {
      const i = selection.indexOf(key)
      const newSelection =
        i >= 0 ? [...selection.slice(0, i), ...selection.slice(i + 1)] : [...selection, key]
      dispatch(setSelection(newSelection))
    },
    [dispatch, selection],
  )

  const selectTableProps = { isSelected, selectAll, toggleAll, toggleSelection }

  const columns = useMemo(
    () => [
      {
        accessor: 'CtrNrjId',
        width: 30,
        resizable: false,
        Header: () => {
          return (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <i className="icon-elec" />
            </div>
          )
        },
        Cell: (row) => {
          const isElec = row.value === 2
          return (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <i
                className={isElec ? 'icon-elec' : 'icon-gaz'}
                css={(theme) => ({ color: isElec ? theme.elec : theme.gas })}
              />
            </div>
          )
        },
      },
      {
        Header: 'Contrat',
        accessor: 'CtrNomCourt',
      },
      {
        Header: 'Pays',
        accessor: 'CtrLibellePays',
      },
      {
        Header: 'Fournisseur',
        accessor: 'CtrFournisseur',
        width: 250,
      },
      {
        Header: 'Début',
        accessor: 'CtrDateDebut',
        width: 110,
        resizable: false,
      },
      {
        Header: 'Fin',
        accessor: 'CtrDateFin',
        width: 110,
        resizable: false,
      },
      {
        Header: 'Par lot',
        sortable: false,
        resizable: false,
        width: 75,
        Cell: (row) => {
          if (row.aggregated) return null
          const { id } = row.original
          return (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Label
                className={`form-check-label${byParcel.includes(id) ? ' checked' : ''}`}
                css={{ '&:before': { marginRight: 0 } }}
              >
                <Input type="checkbox" className="form-check-input" onChange={toggle(id)} />
              </Label>
            </div>
          )
        },
      },
    ],
    [byParcel, toggle],
  )

  const data = useMemo(
    () =>
      contracts.filter(
        (c) => c.CtrDateFin.split('/')[2] >= startYear && c.CtrDateDebut.split('/')[2] <= endYear,
      ),
    [contracts, endYear, startYear],
  )

  const maxRows = (window.innerHeight * 0.85 - 142) / 33

  return (
    <SelectTreeTable
      columns={columns}
      data={data}
      ref={tableRef}
      keyField="id"
      showPagination={false}
      filterable={false}
      pageSize={data.length}
      defaultSorted={[
        { id: 'CtrLibellePays', desc: false },
        { id: 'CtrNrjId', desc: true },
        { id: 'CtrFournisseur', desc: true },
        { id: 'CtrNomCourt', desc: true },
      ]}
      className="-highlight"
      collapseOnDataChange={false}
      collapseOnSortingChange={false}
      {...selectTableProps}
      selectType="checkbox"
      SelectInputComponent={SelectInputComponent}
      SelectAllInputComponent={SelectInputComponent}
      css={{
        margin: 30,
        height: data.length > maxRows ? 'calc(100% - 140px)' : 'unset',
        '.rt-th': {
          fontWeight: 'bold',
          textAlign: 'left',
          textTransform: 'uppercase',
          padding: '8px 8px !important',
        },
      }}
      LoadingComponent={TableSpinner}
    />
  )
}
