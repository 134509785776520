const filterContracts = (contracts, searchFilters) => {
  return contracts.filter(
    (x) =>
      searchFilters.energyType.includes(x.CtrNrjId) &&
      (searchFilters.providers.length === 0 ||
        searchFilters.providers.includes(x.CtrIdFournisseur)) &&
      (searchFilters.countries.length === 0 || searchFilters.countries.includes(x.CtrIdPays)) &&
      searchFilters.coverage.includes(x.CtrEtatCouverture) &&
      searchFilters.configuration.includes(x.CtrEtatConfiguration) &&
      (!searchFilters.startYear || x.CtrDateFin.split('/')[2] >= searchFilters.startYear) &&
      (!searchFilters.endYear || x.CtrDateDebut.split('/')[2] <= searchFilters.endYear),
  )
}

export default filterContracts
