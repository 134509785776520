import React, { Component } from 'react'
import { Row, Col, FormGroup, Input, Label } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import HedgingPlanGraph from './HedgingPlanGraph'
import {
  addHedgingPlanSegment,
  setHedgingPlanLength,
  updateHedgingPlanSegment,
  deleteHedgingPlanSegment,
  addHedgingPlan,
  deleteHedgingPlan,
} from '../../../actions/hedgingPlan'
import { getHedgingPlanSegments } from '../../../reducers/hedgingPlan'
import HedgingPlanPopover from './HedgingPlanPopover'
import HedgingProfileGraph from '../hedgingProfile/HedgingProfileGraph'
import { updateParcelHedging } from '../../../actions/hedgingProfile'

const availableCoverLength = [1, 6, 12, 18, 24]

class HedgingPlan extends Component {
  constructor(props) {
    super(props)
    this.debounceSetLength = 0
    this.state = {
      popoverOpen: false,
      popoverSegment: null,
      popoverSegmentIsMax: null,
      hedgingLength: 12,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps, oldProps) {
    if (newProps.hedgingPlan) {
      this.setState({ hedgingLength: newProps.hedgingPlan.duree })
    }
  }

  togglePopover = (segment = null, isMax = null) => () => {
    this.setState({
      popoverSegment: segment,
      popoverSegmentIsMax: isMax,
      popoverOpen: !this.state.popoverOpen,
    })
  }

  setPlanLength = (hedgingLength) => () => {
    if (hedgingLength === this.state.hedgingLength) return
    this.setState({ hedgingLength })
    if (!this.props.hedgingPlan) {
      this.props.setHedgingPlanLength(hedgingLength)
    } else if (window.confirm(this.props.t('strategy.hedgingPlan.confirmation'))) {
      this.props.setHedgingPlanLength(hedgingLength)
    } else {
      this.setState({ hedgingLength: this.props.hedgingPlan.duree })
    }
  }

  setPlanLengthWithInput = (e) => {
    const hedgingLength = e.target.value && parseInt(e.target.value, 10)
    this.setState({ hedgingLength })
    if (hedgingLength) {
      clearTimeout(this.debounceSetLength)
      this.debounceSetLength = setTimeout(() => {
        if (!this.props.hedgingPlan) {
          this.props.setHedgingPlanLength(hedgingLength)
        } else if (window.confirm(this.props.t('strategy.hedgingPlan.confirmation'))) {
          this.props.setHedgingPlanLength(hedgingLength)
        } else {
          this.setState({ hedgingLength: this.props.hedgingPlan.duree })
        }
      }, 500)
    }
  }

  setSegment = (segment) => {
    this.props.setSegment(segment)
  }

  deleteSegment = (segment) => {
    this.props.deleteSegment(segment)
  }

  isProfileSelected = () => {
    const { hedgingPlan, hedges } = this.props
    return hedgingPlan && hedges.some((h) => h.planCouvertureId === hedgingPlan.id)
  }

  render() {
    const {
      hedgingPlan,
      hedges,
      parcel,
      segments,
      t,
      toggleLinkParcelHedging,
      readOnly,
    } = this.props

    return (
      <div className="hedgingPlan">
        {!readOnly && (
          <Row className="delete-hedging-plan">
            <i className="icon-ico-trash" onClick={this.props.deleteHedgingPlan} />
          </Row>
        )}
        <Row>
          <Col className="col-4">
            <div>
              <h6>
                1.{' '}
                {t(
                  readOnly
                    ? 'strategy.hedgingPlan.blocksTitleReadOnly'
                    : 'strategy.hedgingPlan.blocksTitle',
                )}
              </h6>
              <HedgingProfileGraph
                onClick={readOnly ? () => { } : toggleLinkParcelHedging}
                products={hedges}
                startDate={parcel.dateDebut}
                endDate={parcel.dateFin}
                unit={parcel.uniteDeal}
                selectionId={hedgingPlan.id}
              />
            </div>
            {this.isProfileSelected() && (
              <div>
                <h6 className="title inline">
                  2.{' '}
                  {t(
                    readOnly
                      ? 'strategy.hedgingPlan.hedgingLengthReadOnly'
                      : 'strategy.hedgingPlan.hedgingLength',
                  )}
                </h6>
                <div className="choice-group">
                  {availableCoverLength.map((l) => (
                    <FormGroup
                      key={`l-${l}`}
                      check
                      className={hedgingPlan && hedgingPlan.duree === l ? 'checked' : ''}
                      style={{
                        pointerEvents: readOnly ? 'none' : 'auto',
                      }}
                    >
                      <Label check>
                        <Input
                          name="radio1"
                          onClick={readOnly ? () => { } : this.setPlanLength(l)}
                        />
                        {l} {t('strategy.hedgingPlan.month')}
                      </Label>
                    </FormGroup>
                  ))}

                  {readOnly ? (
                    availableCoverLength.includes(hedgingPlan.duree) ? null : (
                      <FormGroup
                        check
                        style={{
                          pointerEvents: 'none',
                        }}
                      >
                        <Label check className="checked">
                          <Input name="radio1" onClick={() => { }} />
                          {this.state.hedgingLength} {t('strategy.hedgingPlan.month')}
                        </Label>
                      </FormGroup>
                    )
                  ) : (
                    <FormGroup className="hedging-plan-length-input">
                      <Input
                        value={this.state.hedgingLength}
                        type="number"
                        name="value"
                        min="1"
                        max="48"
                        onChange={readOnly ? () => { } : this.setPlanLengthWithInput}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>
            )}
          </Col>
          {this.state.popoverOpen && (
            <HedgingPlanPopover
              isOpen={this.state.popoverOpen}
              togglePopover={this.togglePopover()}
              segment={this.state.popoverSegment}
              segmentList={segments}
              isMax={this.state.popoverSegmentIsMax}
              setSegment={this.setSegment}
              deleteSegment={this.deleteSegment}
            />
          )}
          <Col className="col-8" >
            {this.isProfileSelected() && hedgingPlan && (
              <div>
                <h6>
                  3.{' '}
                  {t(
                    readOnly
                      ? 'strategy.hedgingPlan.coverageLimitsReadOnly'
                      : 'strategy.hedgingPlan.coverageLimits',
                  )}
                </h6>
                <HedgingPlanGraph
                  length={hedgingPlan.duree}
                  segments={segments}
                  setSegment={this.setSegment}
                  onClick={readOnly ? () => { } : this.togglePopover}
                  readOnly={readOnly}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

HedgingPlan.propTypes = {
  contract: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  hedgingPlan: PropTypes.object,
  closeAddPlan: PropTypes.func,
}

const mapStateToProps = (state, { hedgingPlan, parcel }) => ({
  segments: hedgingPlan ? getHedgingPlanSegments(state, hedgingPlan.id) : [],
  hedges: state.hedgingProfile.parcelHedges[parcel.id] || [],
})

const mapDispatchToProps = (dispatch, { contract, hedgingPlan, parcel, closeAddPlan }) => ({
  setHedgingPlanLength: (length) => {
    if (hedgingPlan) {
      dispatch(setHedgingPlanLength(parcel.id, hedgingPlan.id, length))
    } else {
      dispatch(addHedgingPlan(parcel.id, length))
    }
  },
  setSegment: (segment) => {
    if (segment.id) {
      dispatch(updateHedgingPlanSegment(hedgingPlan.id, segment))
    } else {
      dispatch(addHedgingPlanSegment(hedgingPlan.id, segment))
    }
  },
  deleteSegment: (segment) => {
    dispatch(deleteHedgingPlanSegment(hedgingPlan.id, segment))
  }, //PlanCouvertureId
  toggleLinkParcelHedging: (hedging) => {
    dispatch(
      updateParcelHedging(parcel.id, {
        ...hedging,
        planCouvertureId: hedging.planCouvertureId ? null : hedgingPlan.id,
      }),
    )
    if (closeAddPlan) {
      closeAddPlan()
    }
  },
  deleteHedgingPlan: () => {
    if (hedgingPlan) {
      dispatch(deleteHedgingPlan(hedgingPlan.id, parcel.id, contract.id))
    }

    if (closeAddPlan) {
      closeAddPlan()
    }
  },
})

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(HedgingPlan)
