import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { withTranslation } from 'react-i18next'
import { ButtonGroup, Button } from 'reactstrap'

import CurrentSelectionItem from './CurrentSelectionItem'
import { getCountriesByCode } from '../../../reducers/countries'
import { hideContracts, showContracts, removeContracts } from '../../../actions/contractSelection'

const CurrentSelection = ({
  contractsByCountryCode,
  countries,
  removeContracts,
  showContracts,
  hideContracts,
  visibleContracts,
}) => (
  <div>
    <ul className="current-selection-sections">
      {Object.entries(contractsByCountryCode).map((contract, index) => {
        const hiddenContractIds = contract[1]
          .filter((c) => !visibleContracts.find((vc) => vc === c.id))
          .map((c) => c.id)
        return (
          <li
            key={index}
            className={`country-selection${hiddenContractIds.length === 0 ? ' visible' : ''}`}
          >
            <span className="current-selection-label">
              {countries[contract[0]] ? countries[contract[0]].libelle : ''}
              <ButtonGroup className="ml-auto">
                <div className="btn btn-secondary">
                  <Button
                    onClick={() => {
                      removeContracts(contract[1].map((c) => c.id))
                    }}
                  >
                    <i className="icon-cross" />
                  </Button>
                </div>
                <div className="btn btn-secondary">
                  <Button
                    onClick={() => {
                      if (hiddenContractIds.length > 0) {
                        showContracts(hiddenContractIds)
                      } else {
                        hideContracts(contract[1].map((c) => c.id))
                      }
                    }}
                  >
                    <i className="icon-eye" />
                  </Button>
                </div>
              </ButtonGroup>
            </span>

            <ul className="current-selection-item-list">
              {contract[1].map((i, index) => (
                <CurrentSelectionItem key={index} contract={i} />
              ))}
            </ul>
          </li>
        )
      })}
    </ul>
  </div>
)

CurrentSelection.propTypes = {
  contractsByCountryCode: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  contracts: state.contracts.contractList,
  countries: getCountriesByCode(state),
  selectedContracts: state.contracts.contractSelection,
  visibleContracts: state.contracts.visibleContracts,
})

const mapDispatchToProps = (dispatch) => ({
  hideContracts: (contractIds) => {
    dispatch(hideContracts(contractIds))
  },
  showContracts: (contractIds) => {
    dispatch(showContracts(contractIds))
  },
  removeContracts: (contractIds) => {
    dispatch(removeContracts(contractIds))
  },
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ contracts = [], selectedContracts }) => ({
    filteredContracts: contracts.filter((c) => selectedContracts.includes(c.id)),
  })),
  withProps(({ filteredContracts = [] }) => ({
    contractsByCountryCode: filteredContracts.reduce((rv, x) => {
      ;(rv[x['CtrCodePays']] = rv[x['CtrCodePays']] || []).push(x)
      return rv
    }, {}),
  })),
)(CurrentSelection)
