import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'

import { isSuperUser, isAdmin } from 'selectors/user'
import { getContractList } from 'reducers/contracts'
import ReportingButton from './reporting/ReportingButton'
import Strategy from 'components/strategy/Strategy'
import { fetchHedgingReport, setByParcel, setSelection } from 'store/reporting'

const Admin = () => {
  const dispatch = useDispatch()
  const superUser = useSelector(isSuperUser)
  const admin = useSelector(isAdmin)

  const contracts = useSelector(getContractList)
  const contractIds = contracts.map((c) => c.id)
  const saved = useQuery(
    ['hedgingreport/load', { contractIds }],
    () => fetchHedgingReport({ contractIds }),
    {
      enabled: contractIds.length > 0,
    },
  )
  React.useEffect(() => {
    if (!saved.data) return
    const selection = saved.data.filter((x) => x.EstInclus).map((x) => x.CtrId)
    const byParcel = saved.data.filter((x) => x.Niveau === 1).map((x) => x.CtrId)
    dispatch(setSelection(selection))
    dispatch(setByParcel(byParcel))
  }, [dispatch, saved.data])

  return (
    <div>
      {(superUser || admin) && (
        <section className="mt-4">
          <ReportingButton />
        </section>
      )}

      <Strategy />
    </div>
  )
}

export default Admin
