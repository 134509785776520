/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import pick from 'lodash/pick'

import theme from 'colors'
import { setUniqueContractVisible } from 'actions/contractSelection'
import { isAdmin } from 'selectors/user'
import { getCountriesById } from 'reducers/countries'
import Error from 'components/common/Error'

const sliceObj = (obj, start, end) => pick(obj, Object.keys(obj).slice(start, end))

const getCoveragePerCountry = (state) =>
  state.contracts.currentSelectionMonthlyContractCoveragePerCountry

const i18n = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 0,
})

const months = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']

const svgSizeX = 1300
const headerSize = 100
const noTouchOffset = 2 // Borders between elements shouldn't touch
const blockOffset = 15 // Interval between two countries
const contractBlockHeight = 70
const contractTextPadding = 20
const coverageBlockHeight = 150

const PortfolioGraph = (props) => {
  const { callback, print, visibleContracts, contractListObjects, marginSize = 100 } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const countryMarginSize = marginSize
  const contractDrawSize = svgSizeX - countryMarginSize

  const admin = useSelector(isAdmin)
  const coveragePerCountry = useSelector(getCoveragePerCountry)
  const error = useSelector((state) => state.contracts.currentSelectionMonthlyContractCoverageError)
  const countriesById = useSelector(getCountriesById)

  const setVisible = React.useCallback(
    (contract, readonly) => () => {
      dispatch(setUniqueContractVisible(contract, readonly))
    },
    [dispatch],
  )

  React.useEffect(() => {
    if (callback) callback()
  }, [callback])

  if (error) return <Error />
  if (coveragePerCountry === null) return null

  const colors = {
    elec: theme.color8,
    gaz: theme.color2,
    fixed: theme.color5,
    float: theme.color6,
    text: '#666666',
  }

  const getVerticalDivisionCount = () => (props.endYear - props.startYear + 1) * 12

  const getContract = (contract) => visibleContracts.filter((c) => c.id === contract.id)[0]

  const drawHorizontalScale = () => {
    let pointer = new Date(props.startYear, 0, 1)
    const end = new Date(props.endYear, 11, 31)
    const draws = []
    const yearDraws = []
    const yearBarWidth = (contractDrawSize / getVerticalDivisionCount()) * 12 - noTouchOffset * 2
    let year = pointer.getFullYear()
    while (end.getMonth() >= pointer.getMonth() && end.getFullYear() >= pointer.getFullYear()) {
      if (pointer.getMonth() === 0) {
        yearDraws.push(
          <rect
            key={`year-bar-${pointer.getFullYear()}`}
            width={yearBarWidth}
            height={30}
            x={countryMarginSize + (contractDrawSize / getVerticalDivisionCount()) * draws.length}
            y={1}
            fill="whitesmoke"
          />,
        )
      }
      const pointerMonth = pointer.getMonth()
      draws.push(
        <text
          key={`${pointerMonth}-${pointer.getFullYear()}`}
          x={
            countryMarginSize +
            (contractDrawSize / getVerticalDivisionCount()) * (draws.length + 0.5)
          }
          y={headerSize - 50}
          dy=".35em"
          textAnchor="middle"
          fill={colors.text}
        >
          {months[pointerMonth]}
        </text>,
      )
      if (pointerMonth === 5) {
        yearDraws.push(
          <text
            key={`${pointerMonth}-${pointer.getFullYear()}`}
            x={countryMarginSize + (contractDrawSize / getVerticalDivisionCount()) * draws.length}
            y={15}
            dy=".35em"
            dx="-1em"
            fill={colors.text}
          >
            {year}
          </text>,
        )
      }
      if (pointerMonth < 11) {
        pointer.setMonth(pointerMonth + 1)
      } else {
        year++
        pointer.setMonth(0)
        pointer.setFullYear(pointer.getFullYear() + 1)
      }
    }

    return (
      <g className="horizontal-scale">
        {draws}
        {yearDraws}
      </g>
    )
  }

  const getCountryZonesObject = () => {
    const graphStartDate = moment({ year: props.startYear, month: 0 })
    const graphEndDate = moment({ year: props.endYear, month: 11 })

    // add a formated date, which is either the start/end of contract, of the maximum reachable value.
    const enrichedContractsByAllCountry = contractListObjects
      .map((c) => {
        const contractStartDate = moment(c.CtrDateDebut)
        const contractEndDate = moment(c.CtrDateFin)
        const formatedStartDate = moment.max(graphStartDate, contractStartDate)
        const formatedEndDate = moment.min(graphEndDate, contractEndDate)
        return {
          ...c,
          formatedStartDate,
          formatedEndDate,
        }
      })
      // filter if formated start/end diff is 0 (nothing to draw)
      .filter((c) => c.formatedEndDate.diff(c.formatedStartDate, 'months') > 0)
      // Group by country id
      .reduce((rv, x) => {
        ;(rv[x.Pays.Id] = rv[x.Pays.Id] || []).push({
          ...x,
        })
        return rv
      }, {})

    const { length } = enrichedContractsByAllCountry
    const { start = 0, end = length } = props
    const enrichedContractsByCountry = sliceObj(enrichedContractsByAllCountry, start, end)

    // We need to know the height at which to draw the next block;
    let drawnHeight = headerSize + blockOffset

    const draw = []

    for (const country in enrichedContractsByCountry) {
      let newDrawnHeight = drawnHeight

      const isElec = enrichedContractsByCountry[country].filter((c) => c.NrjId === 2).length > 0
      const isGaz = enrichedContractsByCountry[country].filter((c) => c.NrjId === 1).length > 0

      let contractZoneObjectElec = getContractZoneObject(
        enrichedContractsByCountry[country].filter((c) => c.NrjId === 2),
        newDrawnHeight,
      )
      newDrawnHeight = contractZoneObjectElec.drawnHeight + blockOffset

      let countryCoverageObjectElec = null

      if (coveragePerCountry[country]) {
        countryCoverageObjectElec = getCountryCoverageObject(
          coveragePerCountry[country][2],
          newDrawnHeight,
          country,
          2,
        )
        newDrawnHeight = countryCoverageObjectElec.drawnHeight + blockOffset
      }

      let contractZoneObjectGaz = getContractZoneObject(
        enrichedContractsByCountry[country].filter((c) => c.NrjId === 1),
        newDrawnHeight,
      )
      newDrawnHeight = contractZoneObjectGaz.drawnHeight + blockOffset

      let countryCoverageObjectGaz = null
      if (coveragePerCountry[country]) {
        countryCoverageObjectGaz = getCountryCoverageObject(
          coveragePerCountry[country][1],
          newDrawnHeight,
          country,
          1,
        )
        newDrawnHeight = countryCoverageObjectGaz.drawnHeight + blockOffset
      }

      draw.push(
        <g key={`per-country-${country}`} className="per-country">
          <text x={5} y={drawnHeight + 15} dy=".35em" fill={colors.text}>
            {countriesById[country] ? countriesById[country].libelle : null}
          </text>
          {isElec && contractZoneObjectElec.draw}
          {isElec && countryCoverageObjectElec !== null && countryCoverageObjectElec.draw}
          {isGaz && contractZoneObjectGaz.draw}
          {isGaz && countryCoverageObjectGaz !== null && countryCoverageObjectGaz.draw}
          {!print && (
            <line
              x1={noTouchOffset}
              y1={newDrawnHeight}
              x2={svgSizeX - noTouchOffset}
              y2={newDrawnHeight}
              style={{ stroke: '#D8DADF' }}
            />
          )}
        </g>,
      )

      // Adds what has been drawn
      drawnHeight = newDrawnHeight + blockOffset
    }
    return {
      drawnHeight,
      draw,
    }
  }

  const getContractZoneObject = (contracts, drawHeight) => {
    let drawnHeight = drawHeight
    let draw = []

    // Sort by length since we want the longest contracts to be low in the graph
    const sortedContracts = contracts.sort((a, b) => {
      const aStart = a.formatedStartDate
      const aEnd = a.formatedEndDate
      const bStart = b.formatedStartDate
      const bEnd = b.formatedEndDate
      return aEnd.valueOf() - aStart.valueOf() < bEnd.valueOf() - bStart.valueOf()
    })

    const contractLines = []

    // Utility functions to determine if we can add a contract in a drawn line without any superposition
    const canContractFitInLine = (contract, line) => {
      return line.every((otherContract) => {
        const contractStart = contract.formatedStartDate
        const contractEnd = contract.formatedEndDate
        const otherContractStart = otherContract.formatedStartDate
        const otherContractEnd = otherContract.formatedEndDate
        return (
          contractEnd.valueOf() < otherContractStart.valueOf() ||
          contractStart.valueOf() > otherContractEnd.valueOf()
        )
      })
    }

    // Establish the lines to draw;
    sortedContracts.forEach((contract) => {
      const availableLine = contractLines.find((line) => canContractFitInLine(contract, line))
      if (availableLine) {
        availableLine.push(contract)
      } else {
        contractLines.push([contract])
      }
    })

    contractLines.reverse()

    contractLines.forEach((line) => {
      line.forEach((contract, index) => {
        const monthOffsetFromStart = contract.formatedStartDate.diff(
          moment(props.startYear, 'YYYY'),
          'months',
        )
        const contractLength =
          contract.formatedEndDate.diff(contract.formatedStartDate, 'months') + 1

        const contractWidth =
          (contractLength * contractDrawSize) / getVerticalDivisionCount() - 2 * noTouchOffset
        const contractHeight = contractBlockHeight - 2 * noTouchOffset
        const contractStartX =
          countryMarginSize +
          (monthOffsetFromStart * contractDrawSize) / getVerticalDivisionCount() +
          noTouchOffset
        const contractStartY = drawnHeight + noTouchOffset
        draw.push(
          <g
            key={`contract-${contract.id}-${index}`}
            className={contract.NrjId === 1 ? 'gaz' : 'electricity'}
            fill={contract.NrjId === 1 ? colors.gaz : colors.elec}
            stroke={contract.NrjId === 1 ? colors.gaz : colors.elec}
            fillOpacity="0.3"
          >
            <rect
              width={contractWidth}
              height={contractHeight}
              x={contractStartX}
              y={contractStartY}
            />
            <text
              x={contractStartX + contractTextPadding}
              y={contractStartY + contractTextPadding}
              dy=".35em"
            >
              {`${contract.NomCourt} -
              ${moment(contract.CtrDateFin).diff(moment(contract.CtrDateDebut), 'months') + 1} ${t(
                'portfolio.graph.months',
              )}`}
            </text>
            <text
              className="small-text"
              x={contractStartX + contractTextPadding}
              y={contractStartY + contractHeight - contractTextPadding}
              dy=".35em"
            >
              {`${contract.NbPee} ${t('portfolio.graph.sites')} - ${i18n.format(contract.Volume)} ${
                contract.VolumeUnite
              }`}
            </text>
            {!print && (
              <>
                <Link to={'/strategy/' + contract.id}>
                  <text
                    fill={contract.NrjId === 1 ? colors.gaz : colors.elec}
                    className="small-text svg-icon"
                    x={contractStartX - contractTextPadding * 5 + contractWidth}
                    y={contractStartY + contractHeight - contractTextPadding}
                    dy=".35em"
                    textAnchor="end"
                    onClick={setVisible(getContract(contract), true)}
                  >
                    &#57;
                  </text>
                </Link>
                {admin && (
                  <Link to={'/admin/' + contract.id}>
                    <text
                      fill={contract.NrjId === 1 ? colors.gaz : colors.elec}
                      className="small-text svg-icon"
                      x={contractStartX - contractTextPadding * 3 + contractWidth}
                      y={contractStartY + contractHeight - contractTextPadding}
                      dy=".35em"
                      textAnchor="end"
                      onClick={setVisible(getContract(contract))}
                    >
                      &#77;
                    </text>
                  </Link>
                )}
                <Link to={'/exec/' + contract.id}>
                  <text
                    fill={contract.NrjId === 1 ? colors.gaz : colors.elec}
                    className="small-text svg-icon"
                    x={contractStartX - contractTextPadding + contractWidth}
                    y={contractStartY + contractHeight - contractTextPadding}
                    dy=".35em"
                    textAnchor="end"
                    onClick={setVisible(getContract(contract), true)}
                  >
                    &#111;
                  </text>
                </Link>
              </>
            )}
          </g>,
        )
      })
      drawnHeight += contractBlockHeight
    })
    return {
      drawnHeight,
      draw,
    }
  }

  const getCountryCoverageObject = (coverage, drawHeight, countryId, nrjId) => {
    let drawnHeight = drawHeight
    let monthlyCoverage = []

    if (coverage) {
      const startDate = moment(props.startYear, 'YYYY')

      const coverageWidth = ((contractDrawSize / getVerticalDivisionCount()) * 3) / 4
      const coverageHeight = (4 / 6) * coverageBlockHeight
      const coverageStartY = (1 / 6) * coverageBlockHeight + drawnHeight
      const coverageTextY = (11 / 12) * coverageBlockHeight + drawnHeight

      if (coverage.data) {
        monthlyCoverage = coverage.data.histo.series[0].data.map((coverage) => {
          const monthOffsetFromStart = moment(coverage.x).diff(startDate, 'months')
          const coverageStartX =
            countryMarginSize +
            (monthOffsetFromStart * contractDrawSize) / getVerticalDivisionCount() +
            coverageWidth / 6 // coverageWidth/6 to offset 3/4th of coverageWidth

          return (
            <g key={`cov-${countryId}-${coverage.x}`} className="monthly-coverage">
              <rect
                fill="whitesmoke"
                width={coverageWidth}
                height={coverageHeight}
                x={coverageStartX}
                y={coverageStartY}
              />
              <rect
                fillOpacity={0.3}
                fill={colors.fixed}
                stroke={colors.fixed}
                width={coverageWidth}
                height={(coverageHeight * coverage.pourcentageFermeFixe) / 100}
                x={coverageStartX}
                y={coverageStartY + (coverageHeight * (100 - coverage.pourcentageFermeFixe)) / 100}
              />
              <rect
                fillOpacity={0.3}
                fill={colors.float}
                stroke={colors.float}
                width={coverageWidth}
                height={(coverageHeight * coverage.pourcentageFermeFlottant) / 100}
                x={coverageStartX}
                y={
                  coverageStartY -
                  (coverageHeight * coverage.pourcentageFermeFixe) / 100 +
                  (coverageHeight * (100 - coverage.pourcentageFermeFlottant)) / 100
                }
              />
              {coverageWidth > 20 &&
              coverage.pourcentageFermeFixe > 1 &&
              coverage.pourcentageFermeFlottant > 1 ? (
                <text
                  x={coverageStartX + coverageWidth / 2}
                  y={
                    coverageStartY +
                    (coverageHeight * (100 - coverage.pourcentageFermeFixe)) / 100 +
                    (coverageHeight * coverage.pourcentageFermeFixe) / 100 / 2
                  }
                  dy=".35em"
                  fill={colors.text}
                  textAnchor="middle"
                  style={{ fontSize: 11 }}
                >{`${Math.round(coverage.pourcentageFermeFixe)}%`}</text>
              ) : null}
              {coverageWidth > 20 && coverage.pourcentageFermeFlottant > 1 ? (
                <text
                  x={coverageStartX + coverageWidth / 2}
                  y={
                    coverageStartY -
                    (coverageHeight * coverage.pourcentageFermeFixe) / 100 +
                    (coverageHeight * (100 - coverage.pourcentageFermeFlottant)) / 100 +
                    (coverageHeight * coverage.pourcentageFermeFlottant) / 100 / 2
                  }
                  dy=".35em"
                  fill={colors.text}
                  textAnchor="middle"
                  style={{ fontSize: 11 }}
                >{`${Math.round(coverage.pourcentageFermeFlottant)}%`}</text>
              ) : null}
              {coverageWidth > 20 ? (
                <text
                  x={coverageStartX + coverageWidth / 2}
                  y={coverageTextY}
                  dy=".35em"
                  fill={colors.text}
                  textAnchor="middle"
                  style={{ fontSize: 11 }}
                >{`${Math.round(
                  coverage.pourcentageFermeFixe + coverage.pourcentageFermeFlottant,
                )}%`}</text>
              ) : null}
            </g>
          )
        })
      } else {
        monthlyCoverage = [
          <text x={countryMarginSize} y={coverageTextY} dy=".35em" fill="#B9525A">
            {t('global.error')}
          </text>,
        ]
      }

      drawnHeight += coverageBlockHeight
    }

    return {
      drawnHeight,
      draw: (
        <g className="coverage">
          <text
            x={countryMarginSize + noTouchOffset}
            y={drawHeight + noTouchOffset}
            dy=".35em"
            fill={colors.text}
          >
            {t('portfolio.graph.coverage')}{' '}
            {nrjId === 2 ? t('portfolio.graph.elec') : t('portfolio.graph.gas')}
          </text>
          <g>
            <circle
              fillOpacity={0.3}
              fill={colors.fixed}
              stroke={colors.fixed}
              strokeWidth={1}
              cx={svgSizeX - 215}
              cy={drawHeight + noTouchOffset}
              r=".5em"
            />
            <text fill="#8c99a5" x={svgSizeX - 200} y={drawHeight + noTouchOffset} dy=".35em">
              {t('portfolio.graph.fixed')}
            </text>
            <circle
              fillOpacity={0.3}
              fill={colors.float}
              stroke={colors.float}
              strokeWidth={1}
              cx={svgSizeX - 145}
              cy={drawHeight + noTouchOffset}
              r=".5em"
            />
            <text fill="#8c99a5" x={svgSizeX - 130} y={drawHeight + noTouchOffset} dy=".35em">
              {t('portfolio.graph.floatNotOpen')}
            </text>
          </g>
          {monthlyCoverage}
        </g>
      ),
    }
  }

  const countryDrawObject = getCountryZonesObject()
  const width = svgSizeX + 16
  const height = countryDrawObject.drawnHeight
  const ratio = height / width

  return (
    <div
      css={{
        position: 'relative',
        width: '100%',
        height: 0,
        margin: 0,
        padding: 0,
        paddingBottom: `${100 * ratio}%`,
        svg: {
          position: 'absolute',
          height: '100%',
          width: '100%',
          margin: 0,
          left: 0,
          top: 0,
        },
      }}
    >
      <svg
        className="portfolio-graph"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        fontFamily="Muli, sans-serif"
      >
        {drawHorizontalScale()}
        {countryDrawObject.draw}
      </svg>
    </div>
  )
}

export default PortfolioGraph
