import React from 'react'
import { Button, Row, Col } from 'reactstrap'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdAdd as AddIcon } from 'react-icons/io'
import * as cotApi from 'api/quotations'

import {
  fetchDmrQuotations,
  addDmrQuotations,
  toggleDmrQuotation,
  deleteDmrQuotation,
} from 'actions/quotations'

import QuotationMarketPopover from 'components/priceMonitoring/QuotationMarketPopover'

import { fetchAvailableDmrQuotations } from 'actions/quotations'

const i18n = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 2,
})

const DmrCotationsBoard = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isOpen, setOpen] = React.useState(false)
  const toggle = React.useCallback(() => setOpen((x) => !x), [setOpen])

  const quotations = useSelector((state) => state.quotations.dmr.quotations)
  // const quotations = React.useRef([])
  // const availableQuotations = useSelector((state) => state.quotations.dmr.available)
  const [availableQuotations, setAvailableQuotations] = React.useState([])

  const addQuotations = async (cotIds) => {
    dispatch(addDmrQuotations(cotIds.map((c) => c.id)))
  }

  const loadAvailableDmrQuotations = async () => {
    setAvailableQuotations(await cotApi.fetchAvailableDmrQuotations())
  }

  React.useEffect(() => {
    async function fetchData() {
      // await loadDmrQuotations()
      await loadAvailableDmrQuotations()
      dispatch(fetchDmrQuotations())
      dispatch(fetchAvailableDmrQuotations())
    }
    fetchData()
  }, [dispatch])

  return (
    <div className="price-monitoring">
      <section className="mt-4">
        <QuotationMarketPopover
          isOpen={isOpen}
          togglePopover={toggle}
          callbackAdd={addQuotations}
          availableQuotations={availableQuotations}
          onSubmit={async () => {
            await loadAvailableDmrQuotations()
            // dispatch(fetchAvailableDmrQuotations())
          }}
        />
        <Row>
          <Col>
            <h6>{t('admin.cotation.title')}</h6>
          </Col>
          <Col className="text-right">
            <Button style={{ color: 'black', background: '#98D2DD', border: '#98D2DD' }} className="rounded ml-3" onClick={toggle} id="addProduct">
              <AddIcon className="mr-2" />
              {t('strategy.alerts.addProduct')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="transaction-view">
              <div className="transaction-table">
                <Table
                  className="table table-striped table-bordered"
                  noDataText={t('market.quotationBoard.noDataText')}
                  sortable={['product', 'market', 'country']}
                  itemsPerPage={50}
                >
                  <Thead>
                    <Th column="type"> </Th>
                    <Th column="product">{t('market.quotationBoard.product')}</Th>
                    <Th column="market">{t('market.quotationBoard.market')}</Th>
                    <Th column="country">{t('market.quotationBoard.country')}</Th>
                    <Th column="price" className="text-right">
                      {t('market.quotationBoard.price')}
                    </Th>
                    <Th column="currency">{t('market.quotationBoard.currency')}</Th>
                    <Th column="date">{t('market.quotationBoard.date')}</Th>
                    <Th column="active"> </Th>
                    <Th column="delete"> </Th>
                  </Thead>
                  {quotations &&
                    quotations.map((i, index) => (
                      <Tr key={i.id}>
                        <Td column="type" value={i.CotProduit.PdtNrjId}>
                          <i
                            className={`${i.CotProduit.PdtNrjId === 2 ? 'icon-elec' : 'icon-gaz'
                              } left-pad`}
                          ></i>
                        </Td>
                        <Td column="product" value={i.CotProduit.PdtNom}>
                          <strong>{i.CotProduit.PdtNom}</strong>
                        </Td>
                        <Td column="market">{i.CotMarche}</Td>
                        <Td column="country">{i.CotPays}</Td>
                        <Td column="price" className="text-right">
                          {i18n.format(i.CotValeur)}
                        </Td>
                        <Td column="currency">{i.CotUnite}</Td>
                        <Td column="date">{i.CotDate}</Td>
                        <Td column="active" className="text-center">
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              dispatch(toggleDmrQuotation(i.id))
                            }}
                            className="btn-table active"
                          >
                            {i.IsDMRActive === true ? (
                              <i className="icon-ico-alert-mail" />
                            ) : (
                              <i className="icon-ic-add-alert" />
                            )}
                          </Button>
                        </Td>
                        <Td column="delete" className="text-center">
                          {i.IsCotationSuvie === false ? (
                            <Button
                              onClick={(e) => {
                                e.stopPropagation()
                                dispatch(deleteDmrQuotation(i.id))
                              }}
                              className="btn-table active"
                            >
                              <i className="icon-ico-trash" />
                            </Button>
                          ) : null}
                        </Td>
                      </Tr>
                    ))}
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  )
}

export default DmrCotationsBoard
