/** @jsxImportSource @emotion/react */
import React from 'react'
import { useSelector } from 'react-redux'
import { Button, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { keyframes } from '@emotion/react'

import { ReportingSelection } from './ReportingSelection'
import { ReportingFilters } from './ReportingFilters'
import { saveHedgingReport, getSelection, getByParcel } from 'store/reporting'
import { getContractList } from 'reducers/contracts'

const exit = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`

const Error = () => {
  const { t } = useTranslation()
  return <div className="text-danger">{t('global.error')}</div>
}

const Loader = () => {
  return <Spinner size="sm" color="primary" />
}

export const ReportingContracts = ({ toggleModal, openPreview }) => {
  const { t } = useTranslation()
  const mutation = useMutation(saveHedgingReport)

  const contracts = useSelector(getContractList)
  const byParcel = useSelector(getByParcel)
  const selection = useSelector(getSelection)

  const onClick = React.useCallback(() => {
    const payload = contracts.map((c) => ({
      CtrId: c.id,
      EstInclus: selection.includes(c.id),
      Niveau: byParcel.includes(c.id) ? 1 : 0,
    }))
    mutation.mutate({ payload })
  }, [byParcel, contracts, mutation, selection])

  return (
    <>
      <ModalBody
        css={{
          height: '100%',
          overflowY: 'hidden',
          backgroundColor: '#f3f7f9',
          padding: 0,
        }}
      >
        <ReportingFilters />
        <ReportingSelection />
      </ModalBody>

      <ModalFooter
        style={{
          padding: 10,
          backgroundColor: '#fff',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            '>*:not(:last-child)': { marginRight: '1em' },
          }}
        >
          <Button color="secondary" size="sm" onClick={onClick} disabled={mutation.isLoading}>
            <FaCloudUploadAlt
              size={16}
              css={{ marginRight: '0.5em', position: 'relative', top: -1 }}
            />
            Enregistrer la sélection
          </Button>
          {mutation.isLoading ? (
            <Loader />
          ) : mutation.error ? (
            <Error />
          ) : (
            mutation.data && (
              <div
                key={Math.random().toString()}
                css={{
                  animation: `${exit} 1s ease 3s`,
                  animationFillMode: 'forwards',
                }}
              >
                Séléction enregistrée.
              </div>
            )
          )}
        </div>

        <div css={{ display: 'flex' }}>
          <Button color="light" size="sm" onClick={toggleModal} css={{ marginRight: 10 }}>
            <i className="icon-cross" css={{ marginRight: '1em', fontSize: '0.8em' }} />
            {t('reporting.cancel')}
          </Button>

          <Button color="secondary" size="sm" onClick={openPreview}>
            {t('reporting.start')}
            <i
              className="icon-smallarrow-right"
              css={{ marginLeft: '1em', fontSize: '0.8em', position: 'relative', top: -1 }}
            />
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}
