import pMap from '../helpers/p-map'

import {
  fetchAlerts as apiFetchAlerts,
  fetchAlert as apiFetchAlert,
  fetchParcelAlerts as apiFetchParcelAlerts,
  fetchParcelQuotationAlerts as apiFetchParcelQuotationAlerts,
  fetchAlertTypes as apiFetchAlertTypes,
  addAlert as apiAddAlert,
  deleteAlert as apiDeleteAlert,
  updateAlert as apiUpdateAlert,
  updateAlertDailyFlag as apiUpdateAlertDailyFlag,
  updateAlertMailFlag as apiUpdateAlertMailFlag,
  fetchAlertGraph as apiFetchAlertGraph,
  fetchAlertEvents as apiFetchAlertEvents,
} from '../api/alerts'

import { fetchLotQuotationGraph } from './quotations'
import { getParcelAlerts } from '../reducers/alerts'

import {
  FETCH_CONTRACT_ALERTS_REQUEST,
  FETCH_CONTRACT_ALERTS_SUCCESS,
  FETCH_CONTRACT_ALERTS_FAILURE,
  FETCH_ALERT_REQUEST,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_FAILURE,
  FETCH_ALERT_TYPES_REQUEST,
  FETCH_ALERT_TYPES_SUCCESS,
  FETCH_ALERT_TYPES_FAILURE,
  ADD_ALERT_REQUEST,
  ADD_ALERT_SUCCESS,
  ADD_ALERT_FAILURE,
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAILURE,
  UPDATE_ALERT_REQUEST,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAILURE,
  UPDATE_ALERT_TOGGLE_DAILY_REQUEST,
  UPDATE_ALERT_TOGGLE_DAILY_SUCCESS,
  UPDATE_ALERT_TOGGLE_DAILY_FAILURE,
  UPDATE_ALERT_TOGGLE_MAIL_REQUEST,
  UPDATE_ALERT_TOGGLE_MAIL_SUCCESS,
  UPDATE_ALERT_TOGGLE_MAIL_FAILURE,
  FETCH_MULTIPLE_CONTRACT_ALERTS_REQUEST,
  FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS,
  FETCH_MULTIPLE_CONTRACT_ALERTS_FAILURE,
  FETCH_ALERT_GRAPH_REQUEST,
  FETCH_ALERT_GRAPH_SUCCESS,
  FETCH_ALERT_GRAPH_FAILURE,
  FETCH_ALERTS_GRAPH_REQUEST,
  FETCH_ALERTS_GRAPH_SUCCESS,
  FETCH_ALERTS_GRAPH_FAILURE,
  FETCH_PARCEL_ALERTS_REQUEST,
  FETCH_PARCEL_ALERTS_SUCCESS,
  FETCH_PARCEL_ALERTS_FAILURE,
  FETCH_PARCEL_QUOTATION_ALERTS_REQUEST,
  FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS,
  FETCH_PARCEL_QUOTATION_ALERTS_FAILURE,
  FETCH_ALERTS_EVENTS_REQUEST,
  FETCH_ALERTS_EVENTS_SUCCESS,
  FETCH_ALERTS_EVENTS_FAILURE,
} from './'

export const fetchAlerts = (contractId, options) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_ALERTS_REQUEST,
    contractId,
  })
  try {
    dispatch({
      type: FETCH_CONTRACT_ALERTS_SUCCESS,
      alerts: await apiFetchAlerts([contractId], options),
      contractId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_ALERTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchAlertEvents = (alertIds) => async (dispatch) => {
  dispatch({
    type: FETCH_ALERTS_EVENTS_REQUEST,
  })

  if (alertIds.length === 0) {
    dispatch({ type: FETCH_ALERTS_EVENTS_SUCCESS, events: [] })
    return
  }

  try {
    dispatch({
      type: FETCH_ALERTS_EVENTS_SUCCESS,
      events: await apiFetchAlertEvents(alertIds),
    })
  } catch (e) {
    dispatch({
      type: FETCH_ALERTS_EVENTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchAlert = (parcelId, alertId) => async (dispatch) => {
  dispatch({
    type: FETCH_ALERT_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_ALERT_SUCCESS,
      alert: await apiFetchAlert(alertId),
      parcelId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_ALERT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchParcelAlerts = (parcelId) => async (dispatch) => {
  dispatch({
    type: FETCH_PARCEL_ALERTS_REQUEST,
    parcelId,
  })
  try {
    dispatch({
      type: FETCH_PARCEL_ALERTS_SUCCESS,
      alerts: await apiFetchParcelAlerts(parcelId),
      parcelId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_PARCEL_ALERTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchAllParcelAlerts = (parcelIds) => async (dispatch, getState) => {
  await pMap(
    parcelIds
      .filter((id) => !getParcelAlerts(getState(), id))
      .map((id) => () => fetchParcelAlerts(id)(dispatch)),
    (element) => element(),
    { concurrency: 1 },
  )
}

export const fetchParcelQuotationAlerts = (parcelId, quotationId) => async (dispatch) => {
  dispatch({
    type: FETCH_PARCEL_QUOTATION_ALERTS_REQUEST,
    parcelId,
  })
  try {
    dispatch({
      type: FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS,
      alerts: await apiFetchParcelQuotationAlerts(parcelId, quotationId),
      parcelId,
      quotationId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_PARCEL_QUOTATION_ALERTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchAlertTypes = () => async (dispatch) => {
  dispatch({
    type: FETCH_ALERT_TYPES_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_ALERT_TYPES_SUCCESS,
      alerts: await apiFetchAlertTypes(),
    })
  } catch (e) {
    dispatch({
      type: FETCH_ALERT_TYPES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addAlert = (parcelId, alert, quotationId) => async (dispatch) => {
  dispatch({
    type: ADD_ALERT_REQUEST,
  })
  try {
    dispatch({
      type: ADD_ALERT_SUCCESS,
      parcelId,
      alert: await apiAddAlert(alert),
    })

    // Met à jour le graph des alertes
    dispatch(fetchLotQuotationGraph(parcelId, quotationId, true, false))
  } catch (e) {
    dispatch({
      type: ADD_ALERT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteAlert = (parcelId, alertId) => async (dispatch) => {
  dispatch({
    type: DELETE_ALERT_REQUEST,
  })
  try {
    const rq = await apiDeleteAlert(alertId)

    if (rq.status !== 401) {
      dispatch({
        type: DELETE_ALERT_SUCCESS,
        parcelId,
        alertId,
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_ALERT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateAlertToggleMail = (parcelId, alert, toggleOn) => async (dispatch) => {
  dispatch({
    type: UPDATE_ALERT_TOGGLE_MAIL_REQUEST,
    alert: { ...alert, AlerteMail: toggleOn },
    parcelId,
  })
  try {
    await apiUpdateAlertMailFlag(alert, !!toggleOn)
    dispatch({
      type: UPDATE_ALERT_TOGGLE_MAIL_SUCCESS,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: UPDATE_ALERT_TOGGLE_MAIL_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateAlertToggleDaily = (parcelId, alert, toggleOn) => async (dispatch) => {
  dispatch({
    type: UPDATE_ALERT_TOGGLE_DAILY_REQUEST,
    alert: { ...alert, AlerteQuotidienne: toggleOn },
    parcelId,
  })
  try {
    await apiUpdateAlertDailyFlag(alert, !!toggleOn)
    dispatch({
      type: UPDATE_ALERT_TOGGLE_DAILY_SUCCESS,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: UPDATE_ALERT_TOGGLE_DAILY_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateAlert = (parcelId, alert, quotationId) => async (dispatch) => {
  dispatch({
    type: UPDATE_ALERT_REQUEST,
  })
  try {
    const data = await apiUpdateAlert(alert)
    dispatch({
      type: UPDATE_ALERT_SUCCESS,
      parcelId,
      alert: {
        ...data,
        CotationSuivie: alert.CotationSuivie,
      },
    })

    // Met à jour le graph des alertes
    dispatch(fetchLotQuotationGraph(parcelId, quotationId, true, false))

    // Met à jour le tableau des alertes
    dispatch(fetchParcelAlerts(parcelId))
  } catch (e) {
    dispatch({
      type: UPDATE_ALERT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchAlertsOfMultipleContracts = (contractIds, options) => async (dispatch) => {
  dispatch({
    type: FETCH_MULTIPLE_CONTRACT_ALERTS_REQUEST,
    contractIds,
  })
  try {
    dispatch({
      type: FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS,
      alerts: await apiFetchAlerts(contractIds, options),
      contractIds,
    })
  } catch (e) {
    dispatch({
      type: FETCH_MULTIPLE_CONTRACT_ALERTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchAlertsGraph =
  (followedQuotationId, parcelId, quotationId) => async (dispatch) => {
    dispatch({
      type: FETCH_ALERTS_GRAPH_REQUEST,
    })
    try {
      dispatch({
        type: FETCH_ALERTS_GRAPH_SUCCESS,
        alertGraph: await apiFetchAlertGraph(parcelId, quotationId),
        followedQuotationId,
        parcelId,
        quotationId,
      })
    } catch (e) {
      dispatch({
        type: FETCH_ALERTS_GRAPH_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }

// @deprecated
export const fetchAlertGraph = (alertId) => async (dispatch) => {
  dispatch({
    type: FETCH_ALERT_GRAPH_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_ALERT_GRAPH_SUCCESS,
      alertGraph: await apiFetchAlertGraph(alertId),
      alertId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_ALERT_GRAPH_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}
