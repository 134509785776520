/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Component
import QuotationsPopover from './QuotationsPopover'
import AlertPopover from './AlertPopover'
import QuotationGraph from '../../priceMonitoring/QuotationGraph'
import Error from 'components/common/Error'

// Actions
import {
  fetchParcelQuotations,
  deleteQuotation,
  fetchLotQuotationGraph,
  resetLotQuotationGraph,
} from 'actions/quotations'

import {
  fetchParcelAlerts,
  updateAlertToggleMail,
  updateAlertToggleDaily,
  deleteAlert,
} from 'actions/alerts'

// Reducers
import { getParcelAlerts } from 'reducers/alerts'
import { getParcelQuotations, getParcelQuotationsError } from 'reducers/quotations'

const i18nData = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

class Alerts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productPopoverIsOpen: false,
      alertPopoverIsOpen: false,
      selectedContractQuotation: null,
      selectedAlertForEdit: null,
    }
  }

  componentDidMount() {
    this.fetchComponent(this.props.parcel.id)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldProps = this.props

    if (
      (oldProps.parcel && newProps.parcel && oldProps.parcel.id !== newProps.parcel.id) ||
      (!oldProps.parcel && newProps.parcel)
    ) {
      this.fetchComponent(newProps.parcel.id)
      this.setSelectedContractQuotation(null)
    }
  }

  setSelectedContractQuotation(selectedContractQuotation) {
    this.setState({
      selectedContractQuotation: selectedContractQuotation,
    })
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.parcelQuotations &&
      this.props.parcelQuotations.length > 0 &&
      this.state.selectedContractQuotation == null
    ) {
      this.selectContractQuotation(this.props.parcelQuotations[0])()
    }

    if (
      this.props.alerts &&
      this.props.alerts.length !== (oldProps.alerts ? oldProps.alerts.length : 0)
    ) {
      this.reloadGraph()
    }
  }

  reloadGraph = () => {
    if (this.props.parcel && this.state.selectedContractQuotation) {
      this.props.resetLotQuotationGraph(this.props.parcel.id)
      this.props.fetchLotQuotationGraph(
        this.props.parcel.id,
        this.state.selectedContractQuotation.IdCotation,
      )
    }
  }

  fetchComponent(parcelId) {
    this.props.fetchParcelQuotations(parcelId)
    this.props.fetchAlerts(parcelId)
  }

  toggleProductPopover = () => {
    this.setState({
      productPopoverIsOpen: !this.state.productPopoverIsOpen,
    })
  }

  toggleAlertPopoverCreation = (selectedContractQuotation) => (e) => {
    // We don't want to set off "selectContractQuotation" at the same time
    // e.preventDefault();
    // e.stopPropagation();
    this.selectContractQuotation(selectedContractQuotation)
    this.setState({
      selectedAlertForEdit: null,
      selectedContractQuotation,
    })
    this.toggleAlertPopover()
  }

  toggleAlertPopoverEdition = (selectedAlertForEdit) => (e) => {
    // We don't want to set off "selectContractQuotation" at the same time
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      selectedAlertForEdit,
    })
    this.toggleAlertPopover()
  }

  toggleAlertPopover = () => {
    this.setState({
      alertPopoverIsOpen: !this.state.alertPopoverIsOpen,
    })
  }

  deleteContractQuotation = (parcelQuotation) => (e) => {
    this.props.deleteContractQuotation(parcelQuotation)
    this.setState({ selectedContractQuotation: null })
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  selectContractQuotation = (selectedContractQuotation) => () => {
    if (
      !this.state.selectedContractQuotation ||
      this.state.selectedContractQuotation.Id !== selectedContractQuotation.Id
    ) {
      this.props.resetLotQuotationGraph(this.props.parcel.id)
      this.setSelectedContractQuotation(selectedContractQuotation)
      this.props.fetchLotQuotationGraph(this.props.parcel.id, selectedContractQuotation.IdCotation)
    }
  }

  updateAlertToggleMail = (alert) => () => {
    this.props.updateAlertToggleMail(alert, alert.AlerteMail ? 0 : 1)
  }

  updateAlertToggleDaily = (alert, newValue) => () => {
    this.props.updateAlertToggleDaily(alert, alert.AlerteQuotidienne ? 0 : 1)
  }

  deleteAlert = (alertId) => () => {
    this.props.deleteAlert(alertId)
  }

  render() {
    const { parcelQuotationsError } = this.props

    const alerts = this.state.selectedContractQuotation
      ? this.props.alerts.filter(
        (a) => a.CotationSuivie.Cotation.id === this.state.selectedContractQuotation.IdCotation,
      )
      : this.props.alerts
    const {
      t,
      contract,
      parcel,
      lotQuotationGraph,
      parcelQuotations,
      readOnly,
      displayQuotationGraph,
    } = this.props

    // Ugly but reactable fails otherwise
    const quotationHead = readOnly ? (
      <Thead>
        <Th column="nrj">{t('strategy.alerts.commodity')}</Th>
        <Th column="product">{t('strategy.alerts.product')}</Th>
        <Th column="market">{t('strategy.alerts.market')}</Th>
        <Th column="country">{t('strategy.alerts.country')}</Th>
        <Th column="year">{t('strategy.alerts.year')}</Th>
      </Thead>
    ) : (
      <Thead>
        <Th column="nrj">{t('strategy.alerts.commodity')}</Th>
        <Th column="product">{t('strategy.alerts.product')}</Th>
        <Th column="market">{t('strategy.alerts.market')}</Th>
        <Th column="country">{t('strategy.alerts.country')}</Th>
        <Th column="year">{t('strategy.alerts.year')}</Th>
        <Th column="alert" className="text-center">
          <i className="icon-ic-add-alert"></i>
        </Th>
        <Th column="delete" className="text-center">
          <i className="icon-ico-trash"></i>
        </Th>
      </Thead>
    )
    const alertHead = readOnly ? (
      <Thead>
        <Th column="label">{t('strategy.alerts.alerts')}</Th>
        <Th column="product">{t('strategy.alerts.product')}</Th>
        <Th column="value">{t('strategy.alerts.value')}</Th>
        <Th column="period">{t('strategy.alerts.period')}</Th>
        <Th column="daily" className="text-center">
          <i className="icon-ico-alert-daily" />
        </Th>
        <Th column="mail" className="text-center">
          <i className="icon-ico-alert-mail" />
        </Th>
      </Thead>
    ) : (
      <Thead>
        <Th column="label">{t('strategy.alerts.alerts')}</Th>
        <Th column="product">{t('strategy.alerts.product')}</Th>
        <Th column="value">{t('strategy.alerts.value')}</Th>
        <Th column="period">{t('strategy.alerts.period')}</Th>
        <Th column="daily" className="text-center">
          <i className="icon-ico-alert-daily" />
        </Th>
        <Th column="mail" className="text-center">
          <i className="icon-ico-alert-mail" />
        </Th>
        <Th column="edit" className="text-center">
          <i className="icon-ico-edit" />
        </Th>
        <Th column="delete" className="text-center">
          <i className="icon-ico-trash" />
        </Th>
      </Thead>
    )

    return (
      <div id="alerts">
        {this.state.productPopoverIsOpen ? (
          <QuotationsPopover
            isOpen={this.state.productPopoverIsOpen}
            togglePopover={this.toggleProductPopover}
            contract={contract}
            parcel={parcel}
          />
        ) : null}
        {this.state.alertPopoverIsOpen ? (
          <AlertPopover
            isOpen={this.state.alertPopoverIsOpen}
            togglePopover={this.toggleAlertPopover}
            contract={contract}
            parcel={parcel}
            quotationId={
              this.state.selectedContractQuotation ? this.state.selectedContractQuotation.Id : null
            }
            realQuotationId={
              this.state.selectedContractQuotation
                ? this.state.selectedContractQuotation.IdCotation
                : null
            }
            initialAlert={this.state.selectedAlertForEdit}
            reloadGraph={this.props.resetLotQuotationGraph}
          />
        ) : null}
        <Row>
          <Col>
            <section>
              <Row>
                <Col>
                  <h6>{t('header.strategy.alerts')}</h6>
                </Col>
                {readOnly ? null : (
                  <Col className="text-right">
                    <Button
                      style={{ color: 'white', background: '#98D2DD', border: '#98D2DD' }}
                      className="rounded ml-3"
                      onClick={this.toggleProductPopover}
                      id="addProduct"
                    >
                      {t('strategy.alerts.addProduct')}
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <Table
                    className="table table-striped table-bordered mt-4"
                    noDataText={this.props.t('market.quotationBoard.noDataText')}
                    sortable={['label', 'nrj', 'product', 'market', 'country', 'year']}
                    css={{
                      tr: {
                        cursor: 'pointer',
                        '&.active': {
                          border: 'none',
                        },
                      },
                    }}
                  >
                    {quotationHead}
                    {parcelQuotations.map((i, index) => (
                      <Tr
                        key={i.Cotation.id}
                        className={
                          this.state.selectedContractQuotation &&
                            i.Id === this.state.selectedContractQuotation.Id
                            ? 'active'
                            : ''
                        }
                        onClick={this.selectContractQuotation(i)}
                      >
                        <Td column="nrj">{i.Cotation.CotProduit.PdtNrj}</Td>
                        <Td column="product" value={i.Cotation.CotProduit.PdtNom}>
                          <strong>{i.Cotation.CotProduit.PdtNom}</strong>
                        </Td>
                        <Td column="market">{i.Cotation.CotMarche}</Td>
                        <Td column="country">{i.Cotation.CotPays}</Td>
                        <Td column="year">{i.Cotation.CotAnnee}</Td>
                        {readOnly ? null : (
                          <Td column="alert" className="text-center">
                            <Button
                              className="btn-table active"
                              onClick={this.toggleAlertPopoverCreation(i)}
                              id={`addAlert-` + i.Id}
                            >
                              <i className="icon-ic-add-alert"></i>
                            </Button>
                          </Td>
                        )}
                        {readOnly ? null : (
                          <Td column="delete" className="text-center">
                            <Button
                              onClick={this.deleteContractQuotation(i)}
                              className="btn-table active"
                              disabled={i.IsReference}
                              css={{ background: 'none !important' }}
                            >
                              <i className="icon-ico-trash"></i>
                            </Button>
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </Table>
                </Col>
              </Row>

              {parcelQuotationsError ? (
                <Error>{parcelQuotationsError}</Error>
              ) : (
                this.state.selectedContractQuotation && (
                  <div>
                    <Row>
                      <Col>
                        <Table
                          className="table table-striped table-bordered"
                          noDataText={this.props.t('strategy.alerts.noDataText')}
                          sortable={['label', 'product', 'market', 'country', 'type', 'year']}
                        >
                          {alertHead}
                          {alerts.map((i, index) => (
                            <Tr key={index}>
                              <Td column="label">{i.Modele.Titre}</Td>
                              <Td column="product">
                                <strong>{i.CotationSuivie.Cotation.CotProduit.PdtNom}</strong>
                              </Td>
                              <Td column="value">
                                {i.DernieresValeurs
                                  ? i.DernieresValeurs.map((p) => `${i18nData.format(p)}`).join('-')
                                  : ' - '}
                              </Td>
                              <Td column="period">{`${moment(i.DateDebut).format(
                                'DD/MM/YY',
                              )}-${moment(i.DateFin).format('DD/MM/YY')}`}</Td>
                              <Td column="daily" className="text-center">
                                <Button
                                  onClick={this.updateAlertToggleDaily(i)}
                                  className={`alert-icon btn-table${i.AlerteQuotidienne ? ' active' : ''
                                    }`}
                                >
                                  <i className="icon-ico-alert-daily"></i>
                                </Button>
                              </Td>
                              <Td column="mail" className="text-center">
                                <Button
                                  onClick={this.updateAlertToggleMail(i)}
                                  disabled={!i.ListeDiffusion}
                                  className={`alert-icon btn-table${i.AlerteMail ? ' active' : ''}`}
                                >
                                  <i className="icon-ico-alert-mail"></i>
                                </Button>
                              </Td>
                              {readOnly ? null : (
                                <Td column="edit" className="text-center">
                                  <Button
                                    className="btn-table active"
                                    onClick={this.toggleAlertPopoverEdition(i)}
                                    id={`editAlert-` + i.Id}
                                  >
                                    <i className="icon-ico-edit"></i>
                                  </Button>
                                </Td>
                              )}
                              {readOnly ? null : (
                                <Td column="delete" className="text-center">
                                  <Button
                                    className="btn-table active"
                                    onClick={this.deleteAlert(i.Id)}
                                  >
                                    <i className="icon-ico-trash"></i>
                                  </Button>
                                </Td>
                              )}
                            </Tr>
                          ))}
                        </Table>
                      </Col>
                    </Row>

                    {displayQuotationGraph ? (
                      <Row className="alert-graph">
                        <Col>
                          <QuotationGraph
                            lotQuotationGraph={lotQuotationGraph[parcel.id] || []}
                            showAlertes
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                )
              )}
            </section>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state, { contract, parcel }) => ({
  parcelQuotations: (contract ? getParcelQuotations(state, parcel.id) : []) || [],
  parcelQuotationsError: getParcelQuotationsError(state, parcel.id),
  alerts: (contract ? getParcelAlerts(state, parcel.id) : []) || [],
  lotQuotationGraph: state.quotations.lotQuotationGraph,
})

const mapDispatchToProps = (dispatch, { parcel }) => ({
  fetchParcelQuotations: (parcelId) => {
    dispatch(fetchParcelQuotations(parcelId))
  },
  fetchAlerts: (parcelId) => {
    dispatch(fetchParcelAlerts(parcelId))
  },
  fetchLotQuotationGraph: (lotId, quotationId) => {
    dispatch(fetchLotQuotationGraph(lotId, quotationId, true, false))
  },
  deleteContractQuotation: (quotation) => {
    dispatch(deleteQuotation(parcel.id, quotation.Id))
  },
  updateAlertToggleMail: (alert, toggleOn) => {
    dispatch(updateAlertToggleMail(parcel.id, alert, toggleOn))
  },
  updateAlertToggleDaily: (alert, toggleOn) => {
    dispatch(updateAlertToggleDaily(parcel.id, alert, toggleOn))
  },
  deleteAlert: (alertId) => {
    dispatch(deleteAlert(parcel.id, alertId))
  },
  resetLotQuotationGraph: (lotId) => {
    dispatch(resetLotQuotationGraph(lotId))
  },
})

Alerts.propTypes = {
  contract: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  displayQuotationGraph: PropTypes.bool,
}

export default compose(
  withTranslation(),
  // withState('selectedContractQuotation', 'setSelectedContractQuotation', null),
  connect(mapStateToProps, mapDispatchToProps),
)(Alerts)
