import React from 'react'
import { Alert } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const Error = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Alert color="danger" style={{ display: 'inline-block' }}>
      <div>{t('global.error')}</div>
      {children && <div className="mt-2">{children}</div>}
    </Alert>
  )
}
export default Error
