import pMap from '../helpers/p-map'

import {
  fetchContractParcels as apiFetchContractParcels,
  fetchContractParcelDetail as apiFetchContractParcelDetail,
} from '../api/parcels'

import {
  FETCH_CONTRACT_PARCELS_REQUEST,
  FETCH_CONTRACT_PARCELS_FAILURE,
  FETCH_CONTRACT_PARCELS_SUCCESS,
  FETCH_CONTRACT_PARCEL_DETAIL_REQUEST,
  FETCH_CONTRACT_PARCEL_DETAIL_SUCCESS,
  FETCH_CONTRACT_PARCEL_DETAIL_FAILURE,
  FETCH_PARCELS_NAMES_REQUEST,
  FETCH_PARCELS_NAMES_SUCCESS,
  FETCH_PARCELS_NAMES_FAILURE,
} from './'

export const fetchAllContractParcels = (contractIds) => async (dispatch, getState) => {
  const mapper = (contractId) => fetchContractParcels(contractId)(dispatch)
  return pMap(contractIds, mapper, { concurrency: 2 })
}

export const fetchContractParcels = (contractId, debut, fin) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_PARCELS_REQUEST,
    contractId,
  })

  try {
    dispatch({
      type: FETCH_CONTRACT_PARCELS_SUCCESS,
      parcels: await apiFetchContractParcels(contractId, debut, fin),
      contractId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_PARCELS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchParcelDetail = (contractId, parcelId) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_PARCEL_DETAIL_REQUEST,
    parcelId,
  })

  try {
    dispatch({
      type: FETCH_CONTRACT_PARCEL_DETAIL_SUCCESS,
      parcelId,
      parcel: await apiFetchContractParcelDetail(contractId, parcelId),
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_CONTRACT_PARCEL_DETAIL_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchParcelsNames = (contractId) => async (dispatch, getState) => {
  if (getState().parcels.names[contractId]) return

  dispatch({
    type: FETCH_PARCELS_NAMES_REQUEST,
    contractId,
  })

  try {
    const parcels = await apiFetchContractParcels(contractId)
    dispatch({
      type: FETCH_PARCELS_NAMES_SUCCESS,
      parcels: parcels.map(({ id, nom }) => ({ id, nom })),
      contractId,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_PARCELS_NAMES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}
