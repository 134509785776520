import {
  fetchHedgingPlan as apiFetchHedgingPlan,
  addHedgingPlan as apiAddHedgingPlan,
  deleteHedgingPlan as apiDeleteHedgingPlan,
  setHedgingPlanLength as apiSetHedgingPlanLength,
  addHedgingPlanSegment as apiAddHedgingPlanSegment,
  updateHedgingPlanSegment as apiUpdateHedgingPlanSegment,
  deleteHedgingPlanSegment as apiDeleteHedgingPlanSegment,
  fetchHedgingPlanRecap as apiFetchHedgingPlanRecap,
} from '../api/hedgingPlan'

import { fetchParcelHedging } from './hedgingProfile'

import {
  FETCH_HEDGING_PLAN_REQUEST,
  FETCH_HEDGING_PLAN_SUCCESS,
  FETCH_HEDGING_PLAN_FAILURE,
  ADD_HEDGING_PLAN_REQUEST,
  ADD_HEDGING_PLAN_SUCCESS,
  ADD_HEDGING_PLAN_FAILURE,
  SET_HEDGING_PLAN_LENGTH_REQUEST,
  SET_HEDGING_PLAN_LENGTH_SUCCESS,
  SET_HEDGING_PLAN_LENGTH_FAILURE,
  SET_HEDGING_PLAN_SEGMENT_REQUEST,
  SET_HEDGING_PLAN_SEGMENT_SUCCESS,
  SET_HEDGING_PLAN_SEGMENT_FAILURE,
  UPDATE_HEDGING_PLAN_SEGMENT_REQUEST,
  UPDATE_HEDGING_PLAN_SEGMENT_SUCCESS,
  UPDATE_HEDGING_PLAN_SEGMENT_FAILURE,
  DELETE_HEDGING_PLAN_SEGMENT_REQUEST,
  DELETE_HEDGING_PLAN_SEGMENT_SUCCESS,
  DELETE_HEDGING_PLAN_SEGMENT_FAILURE,
  DELETE_HEDGING_PLAN_REQUEST,
  DELETE_HEDGING_PLAN_SUCCESS,
  DELETE_HEDGING_PLAN_FAILURE,
  FETCH_HEDGING_PLAN_RECAP_REQUEST,
  FETCH_HEDGING_PLAN_RECAP_SUCCESS,
  FETCH_HEDGING_PLAN_RECAP_FAILURE,
} from './'

export const fetchHedgingPlan = (parcelId) => async (dispatch) => {
  dispatch({
    type: FETCH_HEDGING_PLAN_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_HEDGING_PLAN_SUCCESS,
      parcelId,
      plans: await apiFetchHedgingPlan(parcelId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_HEDGING_PLAN_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addHedgingPlan = (parcelId, length) => async (dispatch) => {
  dispatch({
    type: ADD_HEDGING_PLAN_REQUEST,
  })
  try {
    dispatch({
      type: ADD_HEDGING_PLAN_SUCCESS,
      parcelId,
      plan: await apiAddHedgingPlan(parcelId, length),
    })
  } catch (e) {
    dispatch({
      type: ADD_HEDGING_PLAN_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteHedgingPlan = (planId, parcelId, length, contractId) => async (dispatch) => {
  dispatch({
    planId,
    parcelId,
    type: DELETE_HEDGING_PLAN_REQUEST,
  })
  try {
    await apiDeleteHedgingPlan(planId, length)
    dispatch({
      planId,
      parcelId,
      type: DELETE_HEDGING_PLAN_SUCCESS,
    })

    // Recharge le profil pour le contrat/lot selectionné afin de libérer les blocs qui étaient associés au plan supprimé
    dispatch(fetchParcelHedging(contractId, parcelId))
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_HEDGING_PLAN_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const setHedgingPlanLength = (parcelId, planId, length) => async (dispatch) => {
  dispatch({
    type: SET_HEDGING_PLAN_LENGTH_REQUEST,
  })
  try {
    dispatch({
      type: SET_HEDGING_PLAN_LENGTH_SUCCESS,
      parcelId,
      plan: await apiSetHedgingPlanLength(planId, length),
    })
  } catch (e) {
    dispatch({
      type: SET_HEDGING_PLAN_LENGTH_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addHedgingPlanSegment = (planId, segment) => async (dispatch) => {
  dispatch({
    type: SET_HEDGING_PLAN_SEGMENT_REQUEST,
  })
  try {
    dispatch({
      type: SET_HEDGING_PLAN_SEGMENT_SUCCESS,
      planId,
      segment: await apiAddHedgingPlanSegment(planId, segment),
    })
  } catch (e) {
    dispatch({
      type: SET_HEDGING_PLAN_SEGMENT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateHedgingPlanSegment = (planId, segment) => async (dispatch) => {
  dispatch({
    type: UPDATE_HEDGING_PLAN_SEGMENT_REQUEST,
    planId,
    segment,
  })
  try {
    dispatch({
      type: UPDATE_HEDGING_PLAN_SEGMENT_SUCCESS,
      planId,
      segment: await apiUpdateHedgingPlanSegment(planId, segment),
    })
  } catch (e) {
    dispatch({
      type: UPDATE_HEDGING_PLAN_SEGMENT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteHedgingPlanSegment = (planId, segment) => async (dispatch) => {
  dispatch({
    type: DELETE_HEDGING_PLAN_SEGMENT_REQUEST,
    planId: planId,
    segment,
  })
  try {
    await apiDeleteHedgingPlanSegment(planId, segment)
    dispatch({
      type: DELETE_HEDGING_PLAN_SEGMENT_SUCCESS,
      planId: planId,
      segmentId: segment.id,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_HEDGING_PLAN_SEGMENT_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchHedgingPlanRecap = (contractIds, startDate, endDate, year, idLot) => async (
  dispatch,
) => {
  dispatch({
    type: FETCH_HEDGING_PLAN_RECAP_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_HEDGING_PLAN_RECAP_SUCCESS,
      contractIds,
      planRecap: await apiFetchHedgingPlanRecap(contractIds, startDate, endDate, year, idLot),
      year,
      idLot,
    })
  } catch (e) {
    dispatch({
      type: FETCH_HEDGING_PLAN_RECAP_FAILURE,
      contractIds,
      message: e.message || 'Something went wrong.',
    })
  }
}
