import React from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import HighchartsReact from 'highcharts-react-official'

import { Highcharts } from 'helpers/highcharts'
import colors from 'colors'

const config = ({ hedgingPlan, hideCoverage, t, print = false, height = null }) => {
  const maxHeight = Math.max(...hedgingPlan.map((e) => e.max))

  const conf = {
    chart: {
      height,
      style: {
        fontFamily: 'Muli, sans-serif',
        fontSize: print ? '10px' : '12px',
      },
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    title: {
      text: null,
    },
    legend: {
      enabled: true,
      margin: print ? 10 : 12,
      symbolWidth: print ? 12 : 16,
      symbolHeight: print ? 6 : 8,
      itemStyle: {
        fontSize: print ? '8px' : '12px',
        fontWeight: 'normal',
        color: '#666666',
      },
    },
    exporting: {
      enabled: !print,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: print ? '10px' : '12px',
        },
      },
      ...(print
        ? {
          tickInterval: 30 * 60 * 60 * 24 * 1000,
          labels: {
            step: 1,
            // rotation: -45,
            formatter: function () {
              const m = moment(this.value)
              return `${m.format('MM')}<br />${m.format('YY')}`
            },
            style: {
              fontSize: '8px',
            },
          },
        }
        : {
          labels: {
            formatter: function () {
              return moment(this.value).format('MM/YY')
            },
          },
        }),
    },
    yAxis: [
      {
        tickAmount: 6,
        min: 0,
        max: maxHeight <= 100 ? 100 : null,
        title: {
          enabled: !print,
          text: `${t('execution.monthlyCoverageGraph.coverage')} %`,
        },
        labels: {
          formatter: function () {
            return print ? `${this.value}%` : this.value
          },
          style: {
            fontSize: print ? '10px' : '12px',
          },
        },
      },
    ],
    tooltip: {
      pointFormat: '<b>{point.series.name}</b> : {point.y:.2f}<br/>',
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      series: {
        animation: false,
        enableMouseTracking: !print,
      },
      column: {
        stacking: 'normal',
        pointPadding: -0.1,
        groupPadding: 0,
        fillOpacity: 1,
        opacity: 1,
        borderWidth: 1,
        borderColor: colors.color6,
        color: colors.color8,
      },
      spline: {
        lineWidth: 3,
        label: {
          enabled: true,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: t('execution.strategyFollowup.maximumToCover'),
        data: hedgingPlan.map((item) => [item.moisAbsolu, item.max]),
        type: 'spline',
        color: '#A81815',
        zIndex: 2,
        label: {
          enabled: false,
        },
        // marker: {
        //   symbol: 'triangle-down',
        //   width: 5,
        // }
      },
      ...(hideCoverage
        ? []
        : [
          {
            name: t('execution.strategyFollowup.covered'),
            data: hedgingPlan
              .filter((i) => i.moisAbsolu < Date.now())
              .map((item) => [item.moisAbsolu, item.tauxCouverture]),
            type: 'column',
            color: '#009BA4',
            zIndex: 1,
            label: {
              enabled: false,
            },
          },
        ]),
      {
        name: t('execution.strategyFollowup.minimumToCover'),
        data: hedgingPlan.map((item) => [item.moisAbsolu, item.min]),
        type: 'spline',
        color: '#F39200',
        zIndex: 2,
        label: {
          enabled: false,
        },
        // marker: {
        //   symbol: 'triangle',
        //   width: 5,
        // }
      },
    ],
  }

  return conf
}

const StrategyFollowupGraph = (props) => {
  const options = config(props)
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default withTranslation()(StrategyFollowupGraph)
