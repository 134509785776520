import { Navbar, Nav, NavItem, Button, Badge } from 'reactstrap'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { setUniqueContractVisible } from 'actions/contractSelection'
import { isAdmin as getIsAdmin } from 'selectors/user'
import { Options } from 'components/options/Options'

const TopNav = ({ setUserPanelOpen, userPanelOpen }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const isAdmin = useSelector(getIsAdmin)

  return (
    <Navbar expand className="topNav" >
      <Nav navbar>
        {paths.map((i, index) => (
          <NavItem key={index}>
            <NavLink
              to={i.dir + i.link}
              activeClassName="active"
              className="nav-link"
              onClick={() => {
                dispatch(setUniqueContractVisible(null))
              }}
              isActive={() =>
                (i.dir && location.pathname.match('^' + i.dir)) ||
                location.pathname.match('^' + i.link)
              }
            >
              <span>
                <i className={i.icon} /> {t(i.text)} {i.nb && <Badge>{i.nb}</Badge>}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Nav className="ml-auto" navbar>
        <Options />
        {isAdmin &&
          pathsAdmin.map((i, index) => (
            <NavItem key={index}>
              <NavLink
                to={i.dir + i.link}
                activeClassName="active"
                className="nav-link"
                onClick={() => {
                  dispatch(setUniqueContractVisible(null))
                }}
                isActive={() =>
                  (i.dir && location.pathname.match('^' + i.dir)) ||
                  location.pathname.match('^' + i.link) ||
                  location.pathname.match('^/transactions')
                }
              >
                <span>
                  <i className={i.icon} /> {t(i.text)} {i.nb && <Badge>{i.nb}</Badge>}
                </span>
              </NavLink>
            </NavItem>
          ))}
        <NavItem className="menu-user">
          <Button color="link" title="" onClick={() => setUserPanelOpen(!userPanelOpen)}>
            <i className="icon-user" />
            <i className="icon-smallarrow-down" />
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  )
}

export default TopNav

export const paths = [
  {
    text: 'topNav.portfolio',
    icon: 'icon-taxes',
    dir: '',
    link: '/portfolio',
  },
  {
    text: 'topNav.execution',
    icon: 'icon-diag',
    dir: '',
    link: '/exec',
  },
  {
    text: 'topNav.market',
    icon: 'icon-ccourbes',
    dir: '',
    link: '/market',
  },
  {
    text: 'topNav.strategy',
    icon: 'icon-ic-diagram',
    dir: '',
    link: '/strategy',
  },
  {
    text: 'topNav.deals',
    icon: 'icon-vallet',
    dir: '',
    link: '/deals',
  },
  {
    text: 'topNav.alerts',
    icon: 'icon-ic-notif',
    dir: '',
    link: '/alerts',
  },
  {
    text: 'Performance',
    icon: 'icon-ico-menu-dashboard',
    dir: '',
    link: '/performance',
  },
]

export const pathsAdmin = [
  {
    text: 'topNav.administration',
    icon: 'icon-ico-menu-parameters',
    dir: '',
    link: '/admin',
  },
]
