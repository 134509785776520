import moment from 'moment-timezone'
import groupBy from 'lodash/groupBy'
import last from 'lodash/last'
import maxBy from 'lodash/maxBy'
import colors from 'colors'
import Highcharts from 'highcharts'

const FHI_ID = 17

const transactionColors = {
  '-1': '#48bb78',
  0: 'crimson',
  1: '#cddc22',
}

const { color5, color2, color4 } = colors
const printColors = [color4, color2, color5]

export const createConfig = (
  data,
  t,
  showAlertes,
  showTransactions,
  zoomOnIndicators,
  showMarkers,
  print,
  height,
  year,
) => {
  // Indicators are supposed to be the curbs enhancing the alert price one (ie: min/max)
  const indicators = (data.seriesAlertes ? [...data.seriesAlertes] : []).filter(
    (indicator) => ![19000, 20000, 21000, 22000].includes(indicator.id),
  )

  const formatedIndicators = indicators.map((indicator) => ({
    ...indicator,
    yAxis: indicator.id === FHI_ID ? 1 : 0,
    data: indicator.data.map((plot) => ({
      ...plot,
      ...(!!plot.marker && {
        marker: {
          enabled: true,
          radius: 6,
          symbol: 'triangle',
        },
      }),
    })),
    tooltip: {
      enabled: !print,
      crosshairs: true,
      shared: true,
      pointFormat: FHI_ID
        ? `<b>{point.series.name}</b> : {point.y:.2f}`
        : `<b>{point.series.name}</b> : {point.y:.2f} ${data.uniteY}`,
    },
    marker: {
      enabled: false,
    },
    label: {
      enabled: false,
    },
  }))

  const quotationCurb = data.serieCotation.data.map((cov) => ({
    x: cov.x,
    y: cov.y,
  }))

  let series = []
  series[0] = {
    name: data.serieCotation.name,
    type: 'area',
    data: quotationCurb,
    lineWidth: 3,
    zIndex: 1,
    connectNulls: true,
    turboThreshold: 0,
    label: {
      enabled: false,
    },
    color: colors.color7,
    showInLegend: !print,
  }

  if (data.serieCalBase) {
    series[1] = {
      name: data.serieCalBase.name,
      data: data.serieCalBase.data.map((cov) => ({
        x: cov.x,
        y: cov.y,
      })),
      lineWidth: 1,
      zIndex: 1,
      connectNulls: true,
      turboThreshold: 0,
      label: {
        enabled: false,
      },
      color: colors.color8,
    }
  }

  const lastQuotation = last(quotationCurb)
  if (lastQuotation) {
    const xMax = lastQuotation.x
    Object.values(formatedIndicators).forEach((series) => {
      if (series.data.length === 2) {
        const lastQuotation = last(series.data)
        lastQuotation.x = Math.min(lastQuotation.x, xMax)
      }
    })
  }

  const getMinMax = () => {
    const unset = { min: undefined, max: undefined }
    if (!zoomOnIndicators || !indicators || indicators.length === 0) return unset
    const minDates = indicators.map(({ data }) =>
      data.reduce((acc, { x }) => (x < acc ? x : acc), Infinity),
    )
    const maxDates = indicators.map(({ data }) =>
      data.reduce((acc, { x }) => (x > acc ? x : acc), -Infinity),
    )
    const min = Math.min(...minDates)
    const max = Math.max(...maxDates)
    if (min === Infinity || max === -Infinity) return unset
    return { min, max }
  }

  const fhiSeries = formatedIndicators.find((s) => s.id === FHI_ID)
  const fhiPointMax = maxBy(fhiSeries?.data, (point) => Math.abs(point.y)) ?? null
  const fhiMax = fhiPointMax === null ? null : Math.abs(fhiPointMax.y)

  let conf = {
    chart: {
      reflow: false,
      zoomType: 'x',
      height,
      style: {
        fontFamily: 'Muli, sans-serif',
      },
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: !print,
    },
    rangeSelector: {
      enabled: !print,
    },
    scrollbar: {
      enabled: !print,
    },
    xAxis: {
      type: 'datetime',
      ...getMinMax(),
      labels: {
        style: {
          fontSize: print ? 8 : 12,
        },
      },
    },
    yAxis: [
      {
        title: {
          enabled: !print,
          text: `${t('execution.alertGraphPopover.price')} (${data.uniteY})`,
        },
        opposite: false,
        labels: {
          style: {
            fontSize: print ? 8 : 12,
          },
        },
        tickPixelInterval: 25,
      },
      ...(fhiSeries
        ? [
            {
              title: {
                enabled: !print,
                text: `FHI`,
              },
              labels: {
                style: {
                  fontSize: print ? 8 : 12,
                },
              },
              tickPixelInterval: 25,
              opposite: true,
              gridLineWidth: 0,
              tickAmount: 12,
              max: fhiMax,
              min: -fhiMax,
              visible: fhiMax !== null,
            },
          ]
        : []),
    ],
    tooltip: {
      enabled: !print,
      crosshairs: true,
      shared: true,
      pointFormat: `<b>{point.series.name}</b> : {point.y:.2f} ${data.uniteY}`,
    },
    legend: {
      enabled: true,
      ...(print
        ? {
            floating: true,
            align: 'left',
            verticalAlign: 'top',
            backgroundColor: '#ffffff99',
            borderColor: '#eeeeee',
            borderWidth: 1,
            layout: 'vertical',
            symbolWidth: 10,
            symbolHeight: 6,
            itemStyle: {
              fontSize: 8,
              fontWeight: 'normal',
              color: '#666666',
            },
            x: 25,
            y: 5,
          }
        : {
            align: 'center',
            verticalAlign: 'bottom',
            symbolWidth: 16,
            symbolHeight: 8,
            itemStyle: {
              fontSize: 12,
              fontWeight: 'normal',
              color: '#666666',
            },
          }),
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        threshold: null,
      },
      series: {
        animation: false,
        lineWidth: 1,
        dataGrouping: {
          enabled: false,
        },
      },
    },
    navigator: {
      enabled: !print,
      series: {
        includeInCSVExport: false,
      },
    },
    colors: printColors,
    series: [...series, ...formatedIndicators],
  }

  // if (showAlertes)
  // {
  // 	conf.series.push(
  // 		{
  // 			name: t('market.QuotationGraph.alerts'),
  // 			data: alertCurb,
  // 			zIndex: 2,
  // 		}
  // 	)
  // }

  if (showTransactions && data.serieTransaction) {
    const transactionsByType = groupBy(data.serieTransaction.data, (p) => p.typeTransaction)
    const transactionSeries = Object.entries(transactionsByType).map(([type, dataTransaction]) => ({
      name: dataTransaction[0].libelleTransaction,
      data: dataTransaction.map(({ x, y }) => ({ x, y })),
      type: 'scatter',
      color: transactionColors[type],
      lineWidth: 0,
      marker: {
        symbol: 'circle',
        radius: print ? 2 : 5,
      },
      zIndex: 5,
      tooltip: {
        pointFormat: `<b>{series.name} - {point.x:%e/%m/%Y} : </b>{point.y:.2f} ${data.uniteY}`,
        crosshairs: true,
        shared: true,
      },
      label: {
        enabled: false,
      },
    }))

    conf.series.push(...transactionSeries)
  }

  return conf
}
