/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { Table } from 'reactstrap'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { decimalFormat, dateFormat } from 'helpers/formater'
import QuotationGraph from 'components/priceMonitoring/QuotationGraph'

const i18n = (n) =>
  new Intl.NumberFormat(navigator.language, {
    maximumFractionDigits: n,
    minimumFractionDigits: n,
  })

const formatUnit = (u) => u.replace(/euro/i, '€').replace(/eur/i, '€')

const ReportingQuotation = ({ unit, name, idLot, idQuotation, year }) => {
  const { t } = useTranslation()

  const graph = useSelector((state) =>
    get(state, ['quotations', 'lotQuotationGraphByQuot', idLot, idQuotation], null),
  )
  const alerts = useSelector((state) =>
    get(state, ['alerts', 'parcelAlertsByQuot', idLot, idQuotation], []),
  )

  const transactions = useSelector((state) =>
    get(state, ['transactions', 'lotTransactionsByQuot', idLot, idQuotation], []),
  )
  const performance = useSelector((state) =>
    get(state, ['performance', 'lotPerformanceByQuot', idLot, idQuotation, 0], null),
  )

  let diffMoyenneMarche, diffMoyenneQuartile1, diffMoyenneQuartile3

  if (performance) {
    diffMoyenneMarche = performance.MoyenneDeals - performance.MoyenneMarche
    diffMoyenneQuartile1 = performance.MoyenneDeals - performance.MoyenneQuartile1
    diffMoyenneQuartile3 = performance.MoyenneDeals - performance.MoyenneQuartile3
  }

  const start = year && moment(year, 'YYYY').startOf('year')
  const end = year && moment(year, 'YYYY').endOf('year')

  const filteredAlerts = alerts
    ? !year
      ? alerts
      : alerts.filter(
          (a) =>
            moment(a.DateDebut).isBetween(start, end) || moment(a.DateFin).isBetween(start, end),
        )
    : []

  return (
    <div>
      <h6>{name}</h6>
      <div
        css={{
          gridArea: 'quotation',
          display: 'grid',
          gridGap: 10,
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridTemplateAreas: `
            'graph graph transactions limits'
          `,
          table: {
            fontSize: 8,
          },
          tr: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          },
          'td, th': {
            padding: '2px 4px',
          },
          h6: {
            borderBottom: 0,
            fontSize: 11,
          },
          '.align-right': {
            textAlign: 'right',
          },
          '.transactions': {
            marginBottom: 10,
          },
        }}
      >
        {graph && (
          <section css={{ gridArea: 'graph' }}>
            <h6>
              {t('reporting.quotGraph')} ({formatUnit(unit)})
            </h6>
            <QuotationGraph
              lotQuotationGraph={graph}
              showAlertes={true}
              showTransactions={true}
              print
              height={230}
              year={year}
            />
          </section>
        )}

        <section css={{ gridArea: 'transactions' }}>
          <h6>{t('reporting.transactions')} </h6>
          <Table size="sm">
            <thead>
              <tr>
                <th>{t('execution.transactionView.date')}</th>
                <th>
                  {t('execution.transactionView.volume')}{' '}
                  {transactions.length > 0 && transactions[0].TrsVolumeUnite}
                </th>
                <th>
                  {t('execution.transactionView.price')}{' '}
                  {transactions.length > 0 && `(${formatUnit(transactions[0].TrsUnite)})`}
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((t) => {
                return (
                  <tr key={t.id}>
                    <td>{moment(t.TrsDate).format('DD/MM/YYYY')}</td>
                    <td className="align-right">{`${decimalFormat(t.TrsVolume, {
                      digitCount: 1,
                    })}`}</td>
                    <td className="align-right">{`${i18n(3).format(t.TrsPrix)}`}</td>
                  </tr>
                )
              })}
              {Array.from({ length: 4 - transactions.length }, (_, index) => (
                <tr key={`blank${index}`}>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>

        <section css={{ gridArea: 'limits' }}>
          <h6>{t('reporting.alerts')}</h6>
          <Table size="sm">
            <thead>
              <tr>
                <th>{t('reporting.indicateur')}</th>
                <th>{t('reporting.price')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredAlerts.map((a) => (
                <tr key={a.Id}>
                  <td>{a.Libelle}</td>
                  <td className="align-right">
                    {a.DernieresValeurs
                      ? a.DernieresValeurs.map((p) => `${i18n(2).format(p)}`).join('-')
                      : ' - '}
                  </td>
                </tr>
              ))}
              {Array.from({ length: 4 - filteredAlerts.length }, (_, index) => (
                <tr key={`blank${index}`}>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h6>
            {t('reporting.performance')} {performance && `(${formatUnit(performance.UniteDeal)})`}
          </h6>

          <Table size="sm" className="transactions">
            <thead>
              <tr>
                <th>{t('reporting.indicateur')}</th>
                <th>{t('reporting.price')}</th>
                <th>{t('reporting.ecart')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('execution.performance.transactionAverage')}</td>
                {performance ? (
                  <>
                    <td className="align-right">
                      {performance.MoyenneDeals
                        ? `${i18n(2).format(performance.MoyenneDeals)}`
                        : '-'}
                    </td>
                    <td>&nbsp;</td>
                  </>
                ) : (
                  <>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </>
                )}
              </tr>
              <tr>
                <td>{t('execution.performance.marketAverage')}</td>
                {performance ? (
                  <>
                    <td className="align-right">{i18n(2).format(performance.MoyenneMarche)}</td>
                    <td className={`align-right ${diffMoyenneMarche <= 0 ? 'green' : 'red'}`}>
                      {performance.MoyenneDeals ? `${i18n(2).format(diffMoyenneMarche)}` : '-'}
                    </td>
                  </>
                ) : (
                  <>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </>
                )}
              </tr>
              <tr>
                <td>{t('execution.performance.firstMarketQuartile')}</td>
                {performance ? (
                  <>
                    <td className="align-right">{i18n(2).format(performance.MoyenneQuartile1)} </td>
                    <td className={`align-right ${diffMoyenneQuartile1 <= 0 ? 'green' : 'red'}`}>
                      {performance.MoyenneDeals ? `${i18n(2).format(diffMoyenneQuartile1)}` : '-'}
                    </td>
                  </>
                ) : (
                  <>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </>
                )}
              </tr>
              <tr>
                <td>{t('execution.performance.thirdMarketQuartile')}</td>
                {performance ? (
                  <>
                    <td className="align-right">{i18n(2).format(performance.MoyenneQuartile3)}</td>
                    <td className={`align-right ${diffMoyenneQuartile3 <= 0 ? 'green' : 'red'}`}>
                      {performance.MoyenneDeals ? `${i18n(2).format(diffMoyenneQuartile3)}` : '-'}
                    </td>
                  </>
                ) : (
                  <>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </>
                )}
              </tr>
            </tbody>
          </Table>
          {performance && (
            <div css={{ fontSize: 8, fontStyle: 'italic', textAlign: 'right' }}>
              {t('execution.performance.periodShort') +
                moment(performance.Debut).format(dateFormat()) +
                t('execution.performance.to') +
                moment(performance.Fin).format(dateFormat())}
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default ReportingQuotation
