import { fetchContracts as apiFetchContracts } from '../api/contracts'
import { fetchProviders as apiFetchProviders } from '../api/providers'
import { fetchCountries as apiFetchCountries } from '../api/countries'

import {
  FETCH_CONTRACTS_REQUEST,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAILURE,
  FETCH_FILTERS_PARAM_REQUEST,
  FETCH_FILTERS_PARAM_SUCCESS,
  FETCH_FILTERS_PARAM_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  ADD_CONTRACTS_TO_SELECTION,
  REMOVE_CONTRACTS_TO_SELECTION,
  SET_CONTRACT_VISIBLE,
  SET_CONTRACT_INVISIBLE,
  SET_UNIQUE_CONTRACT_VISIBLE,
  SET_CONTRACTS_VISIBLE,
  SET_CONTRACTS_INVISIBLE,
  RESET_CONTRACTS_PARCELS,
  RESET_PARCEL_QUOTATIONS,
  EMPTY_SELECTION,
} from './'

import filterContracts from '../helpers/filterContracts'

export const fetchContracts = () => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACTS_REQUEST,
  })

  try {
    dispatch({
      type: FETCH_CONTRACTS_SUCCESS,
      contracts: await apiFetchContracts(),
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_CONTRACTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchCountry = () => async (dispatch) => {
  dispatch({
    type: FETCH_COUNTRIES_REQUEST,
  })

  try {
    dispatch({
      type: FETCH_COUNTRIES_SUCCESS,
      countries: await apiFetchCountries(),
    })
  } catch (e) {
    dispatch({
      type: FETCH_COUNTRIES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchFilters = () => async (dispatch) => {
  dispatch({
    type: FETCH_FILTERS_PARAM_REQUEST,
  })

  try {
    const providers = await apiFetchProviders()
    const countries = await apiFetchCountries()

    dispatch({
      type: FETCH_FILTERS_PARAM_SUCCESS,
      providers,
      countries,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_FILTERS_PARAM_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addContracts = (contractIds) => (dispatch) => {
  dispatch({
    type: ADD_CONTRACTS_TO_SELECTION,
    contractIds,
  })
}

export const resetContractParcels = () => (dispatch) => {
  dispatch({
    type: RESET_CONTRACTS_PARCELS,
  })
}

export const resetParcelQuotations = (parcelId) => (dispatch) => {
  dispatch({
    type: RESET_PARCEL_QUOTATIONS,
    parcelId,
  })
}

export const removeContracts = (contractIds) => (dispatch) => {
  dispatch({
    type: REMOVE_CONTRACTS_TO_SELECTION,
    contractIds,
  })
}

export const showContract = (contractId) => (dispatch) => {
  dispatch({
    type: SET_CONTRACT_VISIBLE,
    contractId,
  })
}

export const hideContract = (contractId) => (dispatch) => {
  dispatch({
    type: SET_CONTRACT_INVISIBLE,
    contractId,
  })
}

export const setUniqueContractVisible = (contract, readonly = false) => (dispatch) => {
  if (contract) {
    contract.readonly = readonly
  }

  dispatch({
    type: SET_UNIQUE_CONTRACT_VISIBLE,
    contract,
  })
}

export const showContracts = (contractsId) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_VISIBLE,
    contractsId,
  })
}

export const hideContracts = (contractsId) => (dispatch) => {
  dispatch({
    type: SET_CONTRACTS_INVISIBLE,
    contractsId,
  })
}

export const emptySelection = (contracts, searchFilters) => (dispatch) => {
  const ids = filterContracts(contracts, searchFilters).map((c) => c.id)
  dispatch(removeContracts(ids))
}

export const selectAll = (contracts, searchFilters) => (dispatch) => {
  const ids = filterContracts(contracts, searchFilters).map((c) => c.id)
  dispatch(addContracts(ids))
}

export const emptyAllSelection = () => (dispatch) => {
  dispatch({
    type: EMPTY_SELECTION,
  })
}
