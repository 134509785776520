import { superFetch } from 'commons'

export const fetchHedgingPlan = (parcelId) => {
  return superFetch({ url: `couvertures/plans/search?lot=${parcelId}` })
}

export const addHedgingPlan = (parcelId, length) => {
  const query = {
    lotId: parcelId,
    duree: length,
  }

  return superFetch({
    url: 'couvertures/plans',
    method: 'POST',
    body: query,
  })
}

export const deleteHedgingPlan = (planId) => {
  return superFetch({
    url: `couvertures/plans/${planId}`,
    method: 'DELETE',
  })
}

export const setHedgingPlanLength = (planId, length) => {
  return superFetch({
    url: `couvertures/plans/${planId}?duree=${length}`,
    method: 'PUT',
  })
}

export const addHedgingPlanSegment = (planId, segment) => {
  return superFetch({
    url: `couvertures/plans/${planId}/segments`,
    method: 'POST',
    body: segment,
  })
}

export const updateHedgingPlanSegment = (planId, segment) => {
  return superFetch({
    url: `couvertures/plans/${planId}/segments/${segment.id}`,
    method: 'PUT',
    body: segment,
  })
}

export const deleteHedgingPlanSegment = (planId, segment) => {
  return superFetch({
    url: `couvertures/plans/${planId}/segments/${segment.id}`,
    method: 'DELETE',
  })
}

export const fetchHedgingPlanRecap = (contractIds, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }
  return superFetch({
    url: 'couvertures/plans/synthese',
    method: 'POST',
    body: query,
  })
}
