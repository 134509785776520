/** @jsxImportSource @emotion/react */
import React from 'react'
import { Button, Modal } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { useClient } from './useClient'
import { ReportingContracts } from './ReportingContracts'
import { ReportingPreview } from './ReportingPreview'

const ReportingButton = () => {
  const { t } = useTranslation()

  const [isModalOpen, setModalOpen] = React.useState(false)
  const [isPreviewOpen, setPreviewOpen] = React.useState(false)

  const openOptions = React.useCallback(() => setPreviewOpen(false), [])
  const openPreview = React.useCallback(() => setPreviewOpen(true), [])

  const toggleModal = React.useCallback(() => {
    openOptions()
    setModalOpen((x) => !x)
  }, [openOptions])

  const { data: client } = useClient()

  if (!client) return null

  return (
    <>
      <Button color="secondary" onClick={toggleModal}>
        <i
          className="icon-file-download"
          css={{
            marginRight: '1em',
            fontSize: '0.8em',
            position: 'relative',
            top: -1,
          }}
        />
        {t('reporting.downloadReporting')}
      </Button>

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        fade={false}
        centered={false}
        size="xl"
        zIndex={3000}
        css={{
          '.modal-content': {
            border: 0,
          },
        }}
      >
        <div
          css={{
            height: 'calc(85vh)',
          }}
        >
          {isPreviewOpen ? (
            <ReportingPreview
              toggleModal={toggleModal}
              openOptions={openOptions}
              clientName={client.Nom}
            />
          ) : (
            <ReportingContracts
              toggleModal={toggleModal}
              openPreview={openPreview}
              clientName={client.Nom}
            />
          )}
        </div>
      </Modal>
    </>
  )
}

export default ReportingButton
