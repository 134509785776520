/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Spinner, Button, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'

import { fetchContractArenh, updateContractArenh } from 'api/contracts'
import { decimalFormat } from 'helpers/formater'

const Error = () => {
  const { t } = useTranslation()
  return <div className="text-danger">{t('global.error')}</div>
}

const Loader = () => {
  return <Spinner size="sm" color="primary" />
}

const Dd = ({ isEdit, value, onChange = () => {}, unit }) => {
  const { t } = useTranslation()
  return (
    <dd>
      {isEdit ? (
        <Input
          bsSize="sm"
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          type="number"
          min={0}
          max={100}
          step={0.5}
          css={{ width: 70 }}
        />
      ) : value === -1 ? (
        t('arenh.toBeConfigured')
      ) : (
        `${decimalFormat(value)} ${unit}`
      )}
    </dd>
  )
}

export const ContractArenh = ({ contractId, parcelId, isAdmin = false }) => {
  const { t } = useTranslation()

  const [isEdit, setEdit] = useState(false)
  const [isTouch, setTouch] = useState(false)
  const toggleEdit = useCallback(() => setEdit((x) => !x), [])

  const query = useQuery(['arenh', { contractId, parcelId }], () =>
    fetchContractArenh({ contractId, parcelId }),
  )
  const mutation = useMutation(updateContractArenh)

  const error = mutation.error || query.error
  const isLoading = mutation.isLoading || query.isLoading
  const data = mutation.data || query.data

  const [formData, setFormData] = useState(data)

  useEffect(() => {
    setFormData(data)
  }, [data])

  const onChange = useCallback((prop, value) => {
    setFormData((formData) => ({ ...formData, [prop]: value }))
    setTouch(true)
  }, [])

  const onSubmit = useCallback(() => {
    mutation.mutate({ contractId, body: formData })
    toggleEdit()
    setTouch(false)
  }, [contractId, formData, mutation, toggleEdit])

  if (error) return <Error />

  if (isLoading)
    return (
      <div css={{ height: 114, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    )

  const droitArenh = get(query.data, 'DroitArenh', null)

  return (
    <>
      <dl
        css={{
          marginTop: '1rem',
          display: 'flex',
          flexWrap: 'wrap ',
          alignItems: 'center',
          dt: { width: '60%' },
          dd: { width: '40%' },
          'dt, dd': {
            margin: 0,
            height: 38,
            borderBottom: '1px solid rgb(215, 222, 228)',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {droitArenh !== null && (
          <>
            <dt>{t('arenh.droitArenh')}</dt>
            <Dd isEdit={false} value={droitArenh} unit={get(formData, 'UniteVolArenh', null)} />
          </>
        )}

        <dt>{t('arenh.tauxEcretement')}</dt>
        <Dd
          isEdit={isEdit}
          value={get(formData, 'TauxEcretement', null)}
          onChange={(value) => onChange('TauxEcretement', value)}
          unit="%"
        />

        <dt>{t('arenh.tauxDemande')}</dt>
        <Dd
          isEdit={isEdit}
          value={get(formData, 'TauxDemande', null)}
          onChange={(value) => onChange('TauxDemande', value)}
          unit="%"
        />
      </dl>

      {isAdmin && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            '>*:not(:last-child)': { marginRight: '0.5rem' },
          }}
        >
          {isEdit ? (
            <>
              <Button size="sm" color="primary" onClick={onSubmit} disabled={!isTouch}>
                Valider
              </Button>
              <Button size="sm" onClick={toggleEdit}>
                Annuler
              </Button>
            </>
          ) : (
            <Button size="sm" onClick={toggleEdit}>
              Modifer
            </Button>
          )}
        </div>
      )}
    </>
  )
}
