/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import range from 'lodash/range'

import calculateStartEndDates from 'helpers/calculateStartEndDates'
import { getParcelHedgesLoading } from 'reducers/hedgingProfile'

const WIDTH = 1300
const YAXIS_WIDTH = 100
const GRAPH_WIDTH = WIDTH - YAXIS_WIDTH

const HEIGHT = 600
const XAXIS_HEIGHT = 42
const GRAPH_HEIGHT = HEIGHT - XAXIS_HEIGHT

const MARGIN_TOP = 10

const availableUnit = {
  percent: '%',
}

const parseDate = (d) => moment(d, ['DD/MM/YYYY', 'YYYY-MM-DDThh:mm:ss'])

const formatYValue = (x) => {
  return x < 1000
    ? parseInt(x, 10) === x
      ? Math.round(x)
      : Math.round(x * 10) / 10
    : `${Math.round(x / 1000)}K`
}

const Loader = () => {
  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        height: '100%',
        width: 'calc(100% - 30px)',
        paddingLeft: 55,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner color="primary" />
    </div>
  )
}

const HorizontalScale = ({ startDate, endDate, vTotalDiv, topHeight }) => {
  const m1 = moment(startDate)
  const m2 = moment(endDate)
  const monthCount = Math.ceil(m2.diff(m1, 'months', true))
  const months = range(monthCount).map((i) => m1.clone().add(i, 'month'))

  return (
    <g className="horizontal-scale">
      {months.map((month, i) => (
        <text
          key={i}
          x={(GRAPH_WIDTH / vTotalDiv) * (i + 0.5) + YAXIS_WIDTH}
          y={MARGIN_TOP + topHeight + XAXIS_HEIGHT / 2}
          dy=".35em"
          fill="#A3A8B5"
        >
          {month.format('MMM')[0].toUpperCase()}
        </text>
      ))}
    </g>
  )
}

const VerticalScale = ({ unit, divisionNum, divisionNumNeg, divisionUnit, selectionId }) => {
  const { t } = useTranslation()
  const divTotal = divisionNum - divisionNumNeg

  return (
    <g>
      {!selectionId && (
        <text
          x={-HEIGHT + XAXIS_HEIGHT + 20}
          y="0"
          dy="1em"
          fill={'#A3A8B5'}
          transform="rotate(-90 0 0)"
        >
          {t('strategy.graph.hedgingHeight')} ({unit})
        </text>
      )}
      {range(divisionNumNeg, divisionNum + 1).map((x, i) => {
        const offset = MARGIN_TOP + (x === 0 ? XAXIS_HEIGHT / 2 : x < 0 ? XAXIS_HEIGHT : 0)
        const y = GRAPH_HEIGHT - i * (GRAPH_HEIGHT / divTotal)
        return (
          <g key={i}>
            {!selectionId && (
              <text
                textAnchor="middle"
                x={YAXIS_WIDTH - 30}
                y={y - divTotal / 2 + offset}
                dy=".35em"
                fill={'#A3A8B5'}
              >
                {formatYValue(x * divisionUnit)}
              </text>
            )}
            {x !== 0 && (
              <line x1={YAXIS_WIDTH} y1={y + offset} x2={WIDTH} y2={y + offset} stroke="#D8DADF" />
            )}
          </g>
        )
      })}
    </g>
  )
}

const nbMonthsInPeriod = (startDate, endDate) => {
  const start = parseDate(startDate)
  const end = parseDate(endDate)
  const nbMonth = Math.round(end.diff(start, 'months', true))
  return nbMonth
}

const Hedges = ({
  onClick,
  products,
  startDate,
  endDate,
  vTotalDiv,
  hTotalDiv,
  hTotalDivNeg,
  heightScale,
  selectionId,
  topHeight,
}) => {
  const start = parseDate(startDate).toDate()

  // Keep the highest height reached per month
  const heightByMonth = new Array(vTotalDiv).fill(topHeight)
  const negativeHeightByMonth = new Array(vTotalDiv).fill(topHeight)

  // For any hedge, return the right rectangle
  const hedges = products.map((p) => {
    const height = ((topHeight * heightScale) / hTotalDiv) * p.valeur
    const selected = p.planCouvertureId === selectionId
    const disabled = p.planCouvertureId && selectionId && !selected

    let width, x, y, className, offset

    switch (p.perTypeLongueur) {
      case 'Y': {
        let nbMonth = nbMonthsInPeriod(start, endDate)
        if (nbMonth > 12) nbMonth = 12
        width = (GRAPH_WIDTH / vTotalDiv) * nbMonth
        const type = p.isArenh ? 'arenh' : 'cal'
        className = `hedging-${type}${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`
        // year offset - first year month's offset
        offset = nbMonth < 12 ? 0 : (p.perAnnee - start.getFullYear()) * 12 - start.getMonth()
        x = 100 + (offset * GRAPH_WIDTH) / vTotalDiv
        y = height < 0 ? negativeHeightByMonth[offset] : heightByMonth[offset] - height

        if (height < 0) {
          negativeHeightByMonth.fill(y - height, offset, offset + nbMonth)
        } else {
          heightByMonth.fill(y, offset, offset + nbMonth)
        }

        break
      }
      case 'Q': {
        width = (GRAPH_WIDTH / vTotalDiv) * 3
        className = `hedging-quarter${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`
        offset =
          (p.perAnnee - start.getFullYear()) * 12 + ((p.perTypeNum - 1) * 3 - start.getMonth())
        x = 100 + (offset * GRAPH_WIDTH) / vTotalDiv
        y = height < 0 ? negativeHeightByMonth[offset] : heightByMonth[offset] - height
        if (height < 0) {
          negativeHeightByMonth.fill(y - height, offset, offset + 3)
        } else {
          heightByMonth.fill(y, offset, offset + 3)
        }
        break
      }
      case 'M': {
        width = GRAPH_WIDTH / vTotalDiv
        className = `hedging-month${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`
        // number of month since start
        offset = (p.perAnnee - start.getFullYear()) * 12 + (p.perTypeNum - 1 - start.getMonth())
        x = 100 + (offset * GRAPH_WIDTH) / vTotalDiv
        y = height < 0 ? negativeHeightByMonth[offset] : heightByMonth[offset] - height
        if (height < 0) {
          negativeHeightByMonth[offset] = y - height
        } else {
          heightByMonth[offset] = y
        }
        break
      }
      default:
        break
    }

    if (!width || !height || width <= 0 || x <= 0 || y < -3) {
      console.error(`Error with: width:${width}, height:${height}, x:${x}, y:${y}`, p)
      console.error('Check any issue with heightByMonth:', heightByMonth, `or hTotalDiv:${hTotalDiv}`)
      return null
    }

    // Dividing contracts vertically, depending on the 'step' parameter Dividing contracts vertically, depending on the 'step' parameter
    const len = Math.abs(p.valeur / p.pas)
    const rect = []
    for (let i = 0; i < len; i++) {
      const offset = height < 0 ? 40 : 0
      rect.push(
        <rect
          onClick={disabled ? null : () => onClick(p)}
          key={`${x}-${y}-${i}`}
          width={width - 2}
          height={Math.abs(height / len)}
          x={x + 1}
          y={y + Math.abs(height / len) * i + 1 + offset + MARGIN_TOP}
          className={className}
        />,
      )
      // Push text only if it'll be big enough
      if (!selectionId) {
        rect.push(
          <text
            onClick={disabled ? null : () => onClick(p)}
            key={`${x}-${y}-${i}-t`}
            x={x + width / 2}
            y={y + Math.abs(height / len) * (1 / 2 + i) + offset + MARGIN_TOP}
            textAnchor="middle"
            dy=".35em"
            className={className}
          >
            {p.isArenh ? 'ARENH' : p.perTypeCode}
          </text>,
        )
      }
    }
    return (
      <g key={`${x}-${y}`}>
        <title>{`${p.isArenh ? 'ARENH' : p.perTypeCode} - ${p.pas}`}</title>
        {rect}
      </g>
    )
  })

  return <g className="hedges">{hedges}</g>
}

const isInPeriod = (startDate, endDate) => (product) => {
  const start = parseDate(startDate)
  const end = parseDate(endDate)
  const periode = calculateStartEndDates(product.perAnnee, product.perTypeCode)
  return periode.startDate.isBefore(end) && periode.endDate.isAfter(start)
}

const compareProducts = (p1, p2) => {
  let res = 0
  if (p1.perTypeLongueur === 'M' || (p1.perTypeLongueur === 'Q' && p2.perTypeLongueur === 'Y')) {
    res = 1
  } else if (
    p2.perTypeLongueur === 'M' ||
    (p2.perTypeLongueur === 'Q' && p1.perTypeLongueur === 'Y')
  ) {
    res = -1
  }
  if (res === 0) {
    if (p1.pas > p2.pas) {
      res = -1
    } else if (p1.pas < p2.pas) {
      res = 1
    }
  }
  return res
}

// Calcul l'interval entre deux ticks sur un graph
const calculateTicks = (range, targetSteps = 5) => {
  const tempStep = range / targetSteps

  // get the magnitude of the step size
  const mag = parseFloat(Math.floor(Math.log10(tempStep)))
  const magPow = parseFloat(Math.pow(10, mag))

  // calculate most significant digit of the new step size
  let magMsd = parseInt(tempStep / magPow + 0.5, 10)

  return magMsd * magPow
}

const calculateDivisions = (products, unit, startDate, endDate) => {
  const start = parseDate(startDate).toDate()
  const end = parseDate(endDate).toDate()
  const monthCount =
    (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth() + 1

  const maxByMonth = new Array(monthCount).fill(0)
  const minByMonth = new Array(monthCount).fill(0)

  products.forEach((p) => {
    let offset
    switch (p.perTypeLongueur) {
      case 'Y':
        offset =
          (p.perAnnee - start.getFullYear()) * 12 - start.getMonth() + (12 - start.getMonth())
        offset = (p.perAnnee - start.getFullYear()) * 12 - start.getMonth()
        if (p.valeur < 0) {
          minByMonth.fill(minByMonth[offset] + p.valeur, offset, offset + 12)
        } else {
          maxByMonth.fill(maxByMonth[offset] + p.valeur, offset, offset + 12)
        }
        break
      case 'Q':
        offset =
          (p.perAnnee - start.getFullYear()) * 12 + ((p.perTypeNum - 1) * 3 - start.getMonth())
        if (p.valeur < 0) {
          minByMonth.fill(minByMonth[offset] + p.valeur, offset, offset + 3)
        } else {
          maxByMonth.fill(maxByMonth[offset] + p.valeur, offset, offset + 3)
        }
        break
      case 'M':
        offset = (p.perAnnee - start.getFullYear()) * 12 + (p.perTypeNum - 1 - start.getMonth())
        if (p.valeur < 0) {
          minByMonth[offset] = minByMonth[offset] + p.valeur
        } else {
          maxByMonth[offset] = maxByMonth[offset] + p.valeur
        }
        break
      default:
        break
    }
  })

  if (unit === 'Percentage') {
    maxByMonth.push(100)
    //minByMonth.push(100)
  }

  let absoluteMax = Math.max(...maxByMonth)
  let absoluteMin = Math.min(...minByMonth)
  let divisionUnit, divisionNum, divisionNumNeg

  if (!unit || unit === availableUnit.percent) {
    absoluteMax = absoluteMax < 100 ? 100 : absoluteMax
    absoluteMin = absoluteMin < -100 ? absoluteMin : absoluteMin < 0 ? -100 : 0
    divisionUnit = 25
    divisionNum = absoluteMax / divisionUnit
    divisionNumNeg = absoluteMin / divisionUnit
  } else {
    absoluteMax = absoluteMax ? absoluteMax : 5
    absoluteMin = absoluteMin ? absoluteMin : 0
    divisionUnit = calculateTicks(absoluteMax - absoluteMin, 5)
    divisionNum = Math.ceil(absoluteMax / divisionUnit, 10)
    divisionNumNeg = Math.floor(absoluteMin / divisionUnit, 10)
  }

  const heightScale = absoluteMax / (divisionNum * divisionUnit)

  return {
    vTotalDiv: monthCount, // xAxis max (ex: 12 mois)
    hTotalDiv: absoluteMax, // yAxis max (ex: 75 %)
    hTotalDivNeg: absoluteMin, // yAxis max (ex: -25 %)
    divisionUnit, // valeur d'une division (ex: 20 %)
    divisionNum, // nombre de divisions max (ex: 4)
    divisionNumNeg, // nombre de divisions min (ex: -2)
    heightScale,
  }
}

const HedgingProfileGraph = ({ products, unit, startDate, endDate, onClick, selectionId }) => {
  const isLoading = useSelector(getParcelHedgesLoading)

  const filteredProducts = React.useMemo(() => {
    return products.filter(isInPeriod(startDate, endDate)).sort(compareProducts)
  }, [products, startDate, endDate])

  const {
    vTotalDiv,
    hTotalDiv,
    hTotalDivNeg,
    divisionUnit,
    divisionNum,
    divisionNumNeg,
    heightScale,
  } = React.useMemo(() => calculateDivisions(filteredProducts, unit, startDate, endDate), [
    filteredProducts,
    unit,
    startDate,
    endDate,
  ])

  const divTotal = divisionNum - divisionNumNeg
  const topHeight = (divisionNum * GRAPH_HEIGHT) / divTotal

  const hedgesProps = {
    onClick,
    startDate,
    endDate,
    vTotalDiv,
    hTotalDiv,
    hTotalDivNeg,
    heightScale,
    selectionId,
    topHeight,
  }

  return (
    <>
      <svg
        className={`hedging-profile-graph${selectionId ? ' selection-mode' : ''}`}
        viewBox={`0 0 ${WIDTH} ${HEIGHT + 50}`}
      >
        <VerticalScale {...{ unit, divisionNum, divisionNumNeg, divisionUnit, selectionId }} />
        <HorizontalScale {...{ startDate, endDate, vTotalDiv, topHeight }} />
        <Hedges products={filteredProducts} {...hedgesProps} />
      </svg>
      {!selectionId && isLoading && <Loader />}
    </>
  )
}

export default HedgingProfileGraph
