import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'

import fillRange from '../../helpers/fillRange'

// function getAvailableTimeframes(smallestTimeframe) {
//   if (smallestTimeframe === 'M') {
//     return ['Y', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12'];
//   } else if (smallestTimeframe === 'Q') {
//     return ['Y', 'Q1', 'Q2', 'Q3', 'Q4'];
//   } else if (smallestTimeframe === 'Y') {
//     return ['Y'];
//   }
// };

class Header extends Component {
  componentDidUpdate(oldProps) {
    if (
      this.props.availableYears.length > 0 &&
      !this.props.availableYears.find((y) => y === this.props.selectedYear)
    ) {
      this.setOptimalYear()
    }
    if (oldProps.granularity !== this.props.granularity) {
      this.props.setSelectedCodeType('Y')
    }
  }

  setOptimalYear = () => {
    const currentYear = moment().year()
    const start = this.props.availableYears.reduce((acc, value) => {
      return value <= currentYear || acc === null ? value : acc
    }, null)
    this.props.setSelectedYear(start)
  }

  render() {
    const {
      history,
      contract,
      setUniqueContractVisible,
      contractParcels,
      setSelectedParcel,
      selectedParcel,
      // availableYears,
      // selectedYear,
      // setSelectedYear,
      // selectedCodeType,
      // setSelectedCodeType,
      // granularity,
      t,
    } = this.props

    return (
      <Col tag="header">
        <Row>
          <Col>
            <h5>
              {t('header.strategy.hedging')} {contract.CtrNom}
            </h5>
          </Col>
          <Col>
            <div
              className="quit-nav"
              onClick={() => {
                setUniqueContractVisible(null)
                setTimeout(() => history.goBack())
              }}
            >
              <i className="icon-cross" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav tabs>
              {contractParcels.map((parcel) => (
                <NavItem key={parcel.id}>
                  <NavLink
                    onClick={() => {
                      setSelectedParcel(parcel)
                    }}
                    active={selectedParcel && parcel.id === selectedParcel.id}
                    href="#"
                  >
                    {parcel.nom}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
      </Col>
    )
  }
}

Header.propTypes = {
  setUniqueContractVisible: PropTypes.func.isRequired,
  setSelectedParcel: PropTypes.func.isRequired,
  selectedParcel: PropTypes.object,
  contractParcels: PropTypes.array,
  contract: PropTypes.object,
  readOnly: PropTypes.bool,
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func,
  selectedCodeType: PropTypes.string,
  setSelectedCodeType: PropTypes.func,
}

const mapStateToProps = (state, { selectedParcel }) => ({
  selectedParcelHedges: selectedParcel
    ? state.hedgingProfile.parcelHedges[selectedParcel.id] || []
    : [],
})

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, undefined),
  withProps(({ selectedParcel, selectedParcelHedges, readOnly }) => ({
    availableYears: selectedParcel
      ? fillRange({
          start: moment(selectedParcel.dateDebut).year(),
          end: moment(selectedParcel.dateFin).year(),
        })
      : [],
    granularity:
      !!readOnly &&
      selectedParcelHedges.reduce((acc, hedge) => {
        if (acc === 'Y' && hedge.perTypeLongueur !== 'Y') {
          return hedge.perTypeLongueur
        } else if (hedge.perTypeLongueur === 'M') {
          return 'M'
        } else {
          return acc
        }
      }, 'Y'),
  })),
)(Header)
