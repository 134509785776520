/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { Row, Col, PopoverBody, Button, Popover, Input, Badge } from 'reactstrap'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { useTranslation } from 'react-i18next'

const QuotationMarketPopover = ({
  isOpen,
  togglePopover,
  callbackAdd,
  availableQuotations,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const [searchFilter, setSearchFilter] = useState('')
  const [selectedQuotations, setSelectedQuotations] = useState([])
  const [filteredQuotations, setFilteredQuotations] = useState(availableQuotations)

  useEffect(() => {
    setFilteredQuotations(availableQuotations)
  }, [availableQuotations])

  const toggleQuotation = (quotation, shouldToggleOn) => () => {
    setSelectedQuotations(
      shouldToggleOn
        ? [...selectedQuotations, quotation]
        : selectedQuotations.filter((p) => p.id !== quotation.id),
    )
  }

  const addQuotations = (e) => {
    e.preventDefault()
    callbackAdd(selectedQuotations)
    togglePopover()
    setSelectedQuotations([])
    if (typeof onSubmit === 'function') onSubmit()
  }

  const onSearchChange = (e) => {
    const searchFilter = e.target.value.toLowerCase()
    const searchStrings = searchFilter.split(' ')
    let filteredQuotations = [...availableQuotations]
    searchStrings.forEach((str) => {
      if (str !== '') {
        filteredQuotations = filteredQuotations.filter(
          (quotation) =>
            quotation.CotProduit.PdtNom.toLowerCase().includes(str) ||
            quotation.CotMarche.toLowerCase().includes(str) ||
            quotation.CotPays.toLowerCase().includes(str) ||
            quotation.CotProduit.PdtNrj.toLowerCase().includes(str),
        )
      }
    })
    setFilteredQuotations(filteredQuotations)
    setSearchFilter(searchFilter)
  }

  return (
    <Popover
      className="quotation-popover"
      placement="left"
      isOpen={isOpen}
      target="addProduct"
      toggle={togglePopover}
    >
      <PopoverBody>
        <form onSubmit={addQuotations}>
          <Row>
            <Col>
              <Button close onClick={togglePopover} />
              <label className="title">{t('strategy.quotationsPopover.search')}</label>
              <Input value={searchFilter} type="text" name="searchBox" onChange={onSearchChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                className="table table-striped table-bordered mt-4"
                sortable={['product', 'market', 'country', 'year']}
                itemsPerPage={15}
              >
                <Thead>
                  <Th column="select" className="quotation-selection">
                    {' '}
                  </Th>
                  <Th column="nrj">{t('strategy.alerts.commodity')}</Th>
                  <Th column="product">{t('strategy.alerts.product')}</Th>
                  <Th column="market">{t('strategy.alerts.market')}</Th>
                  <Th column="country">{t('strategy.alerts.country')}</Th>
                  <Th column="year">{t('strategy.alerts.year')}</Th>
                </Thead>
                {filteredQuotations.map((i, index) => {
                  const isSelected = selectedQuotations.findIndex((p) => p.id === i.id) !== -1
                  return (
                    <Tr key={index}>
                      <Td column="select" className="quotation-selection" value={isSelected}>
                        <Input
                          type="checkbox"
                          checked={isSelected}
                          onChange={toggleQuotation(i, !isSelected)}
                        />
                      </Td>
                      <Td column="nrj">{i.CotProduit.PdtNrj}</Td>
                      <Td column="product" value={i.CotProduit.PdtNom}>
                        <strong>{i.CotProduit.PdtNom}</strong>
                      </Td>
                      <Td column="market">{i.CotMarche}</Td>
                      <Td column="country">{i.CotPays}</Td>
                      <Td column="year">{i.CotAnnee}</Td>
                    </Tr>
                  )
                })}
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="float-right rounded" style={{ color: 'black' }} color="primary" type="submit">
                <span>{t('strategy.quotationsPopover.add')}</span>
                <Badge style={{ color: 'white' }} color="primary" className="ml-2" css={{ padding: '0.3em 0.4em 0.2em' }}>
                  {selectedQuotations.length}
                </Badge>
              </Button>
            </Col>
          </Row>
        </form>
      </PopoverBody>
    </Popover>
  )
}

export default QuotationMarketPopover
