import { createStore, applyMiddleware, compose } from 'redux'
import { loadUser } from 'redux-oidc'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
import { userManager, accessTokenMiddleware, lanId, lngIsoStr, i18n } from 'commons'
import ReactGA from 'react-ga'
import get from 'lodash/get'
import pick from 'lodash/pick'

import { setHighchartsOptions } from 'helpers/highcharts'
import rootReducer from './reducers'
import { loadState, saveState } from './helpers/localStorage'

const getEid = (state) => get(state, 'oidc.user.profile.eid', null)
const getName = (state) => get(state, 'oidc.user.profile.name', null)
const getUor = (state) => get(state, 'oidc.user.profile.uor', null)

const configureStore = () => {
  const middleware = [thunk, accessTokenMiddleware]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

  loadUser(store, userManager)

  const persistedState = loadState()

  let prevEid = null
  let prevUserId = null
  let prevLang = null
  store.subscribe(() => {
    const state = store.getState()
    const eid = getEid(state)
    const name = getName(state)
    const uor = getUor(state)
    const isCallback = window.location.pathname === '/callback'

    // Set UI language
    const lang = Number(lanId(state))
    if (lang !== prevLang) {
      prevLang = lang
      setHighchartsOptions(lang)
      i18n.changeLanguage(lngIsoStr(lang))
    }

    // Load state from localstorage into store
    if (!prevEid && prevEid !== eid && eid === getEid(persistedState) && !isCallback) {
      prevEid = eid
      store.dispatch({ type: 'LOAD_PRESISTED_STATE', persistedState })
    }

    // Set Google Analytics userId when available
    const userId = !name || !uor ? null : `${name}_${uor}`
    if (!prevUserId && prevUserId !== userId && !isCallback) {
      prevUserId = userId
      ReactGA.set({ userId })
    }
  })

  store.subscribe(
    throttle(() => {
      const state = store.getState()
      if (state.oidc.user && !state.oidc.user.expired) {
        saveState({
          oidc: state.oidc,
          countries: state.countries,
          providers: state.providers,
          contracts: state.contracts,
          searchFilters: state.searchFilters,
          reporting: pick(state.reporting, ['startYear', 'endYear']),
          perf: state.perf,
          market: state.market,
          quotations: state.quotations,
        })
      }
    }, 1000),
  )

  return store
}

export default configureStore
