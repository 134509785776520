import React from 'react'
import { compose } from 'recompose'
import { Form, Label } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import InputDatepicker from '../InputDatepicker'

const RangeInputDatePicker = ({ t, min, max, onMinChange, onMaxChange }) => (
  <Form inline className="range-input-datepicker">
    <Label for="rangeMin">{t('market.priceMonitoring.from')}</Label>
    <InputDatepicker id="rangeMin" value={min} onChange={onMinChange} />
    <Label for="rangeMax">{t('market.priceMonitoring.to')}</Label>
    <InputDatepicker id="rangeMax" value={max} onChange={onMaxChange} />
  </Form>
)

export default compose(withTranslation())(RangeInputDatePicker)
