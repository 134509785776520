import moment from 'moment-timezone'
import maxBy from 'lodash/maxBy'
import colors from 'colors'

const FHI_ID = 17

const { color5, color2, color4 } = colors
const printColors = [color4, color2, color5]

export const createConfigRsi = (
  data,
  t,
  showAlertes,
  showTransactions,
  zoomOnIndicators,
  showMarkers,
  print,
  height,
  year,
) => {
  // Indicators are supposed to be the curbs enhancing the alert price one (ie: min/max)
  const indicators = (data.seriesAlertes ? [...data.seriesAlertes] : []).filter((indicator) =>
    [19000, 20000, 21000, 22000].includes(indicator.id),
  )

  const max = indicators[1]?.data[0]?.y ?? 100
  const min = indicators[2]?.data[0]?.y ?? 0

  const formatedIndicators = indicators.map((indicator) => ({
    ...indicator,
    yAxis: indicator.id === FHI_ID ? 1 : 0,
    data: indicator.data.map((plot) => ({
      ...plot,
      ...(!!plot.marker && {
        marker: {
          enabled: true,
          radius: 6,
          symbol: 'triangle',
        },
      }),
    })),
    tooltip: {
      enabled: !print,
      crosshairs: true,
      shared: true,
      pointFormat: FHI_ID
        ? `<b>{point.series.name}</b> : {point.y:.2f}`
        : `<b>{point.series.name}</b> : {point.y:.2f} ${data.uniteY}`,
    },
    marker: {
      enabled: false,
    },
    label: {
      enabled: false,
    },
  }))

  if (formatedIndicators.length === 0) return null

  const getMinMax = () => {
    const unset = { min: undefined, max: undefined }
    if (!zoomOnIndicators || !indicators || indicators.length === 0) return unset
    const minDates = indicators.map(({ data }) =>
      data.reduce((acc, { x }) => (x < acc ? x : acc), Infinity),
    )
    const maxDates = indicators.map(({ data }) =>
      data.reduce((acc, { x }) => (x > acc ? x : acc), -Infinity),
    )
    const min = Math.min(...minDates)
    const max = Math.max(...maxDates)
    if (min === Infinity || max === -Infinity) return unset
    return { min, max }
  }

  const fhiSeries = formatedIndicators.find((s) => s.id === FHI_ID)
  const fhiPointMax = maxBy(fhiSeries?.data, (point) => Math.abs(point.y)) ?? null
  const fhiMax = fhiPointMax === null ? null : Math.abs(fhiPointMax.y)

  let conf = {
    chart: {
      reflow: false,
      zoomType: 'x',
      height,
      style: {
        fontFamily: 'Muli, sans-serif',
      },
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: !print,
    },
    rangeSelector: {
      enabled: !print,
    },
    scrollbar: {
      enabled: !print,
    },
    xAxis: {
      type: 'datetime',
      ...getMinMax(),
      labels: {
        style: {
          fontSize: print ? 8 : 12,
        },
      },
    },
    yAxis: [
      {
        title: {
          enabled: !print,
          text: `${t('execution.alertGraphPopover.price')}`,
        },
        opposite: false,
        labels: {
          style: {
            fontSize: print ? 8 : 12,
          },
        },
        tickPixelInterval: 25,
      },
      ...(fhiSeries
        ? [
            {
              title: {
                enabled: !print,
                text: `FHI`,
              },
              labels: {
                style: {
                  fontSize: print ? 8 : 12,
                },
              },
              tickPixelInterval: 25,
              opposite: true,
              gridLineWidth: 0,
              tickAmount: 12,
              max: fhiMax,
              min: -fhiMax,
              visible: fhiMax !== null,
            },
          ]
        : []),
    ],
    tooltip: {
      enabled: !print,
      crosshairs: true,
      shared: true,
      pointFormat: `<b>{point.series.name}</b> : {point.y:.2f}`,
    },
    legend: {
      enabled: true,
      ...(print
        ? {
            floating: true,
            align: 'left',
            verticalAlign: 'top',
            backgroundColor: '#ffffff99',
            borderColor: '#eeeeee',
            borderWidth: 1,
            layout: 'vertical',
            symbolWidth: 10,
            symbolHeight: 6,
            itemStyle: {
              fontSize: 8,
              fontWeight: 'normal',
              color: '#666666',
            },
            x: 25,
            y: 5,
          }
        : {
            align: 'center',
            verticalAlign: 'bottom',
            symbolWidth: 16,
            symbolHeight: 8,
            itemStyle: {
              fontSize: 12,
              fontWeight: 'normal',
              color: '#666666',
            },
          }),
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 3,
        dataGrouping: {
          enabled: false,
        },
      },
    },
    navigator: {
      enabled: !print,
      series: {
        includeInCSVExport: false,
      },
    },
    colors: printColors,
    series: [
      ...formatedIndicators,
      {
        type: 'arearange',
        includeInDataExport: false,
        // data: formatedIndicators[0].data.map((item, index, items) => {
        //   const next = items[index + 1]
        //   const prev = items[index - 1]
        //   return {
        //     x:
        //       index < items.length - 1 && item.y < max && next.y > max
        //         ? intersectionX(item, next, { x: item.x, y: max }, { x: next.x, y: max })
        //         : index > 0 && item.y < max && prev.y > max
        //         ? intersectionX(item, prev, { x: item.x, y: max }, { x: prev.x, y: max })
        //         : item.x,
        //     low: max,
        //     high: Math.max(item.y, max),
        //   }
        // }),
        data: formatedIndicators[0].data.map((item, index, items) => {
          if (item.y > max) {
            return {
              x: item.x,
              low: max,
              high: item.y,
            }
          }

          return {
            x: item.x,
            low: max,
            high: Math.max(item.y, max),
          }
        }),
        zoneAxis: 'x',
        showInLegend: false,
        includeInCSVExport: false,
        color: printColors[1],
        enableMouseTracking: false,
      },
      {
        type: 'arearange',
        includeInDataExport: false,
        data: formatedIndicators[0].data.map((item) => {
          if (item.y < min) {
            return {
              x: item.x,
              low: item.y,
              high: min,
            }
          }

          return {
            x: item.x,
            low: Math.min(item.y, min),
            high: min,
          }
        }),
        zoneAxis: 'x',
        showInLegend: false,
        includeInCSVExport: false,
        color: printColors[2],
        enableMouseTracking: false,
      },
    ],
  }

  return conf
}

// const intersectionX = (p0, p1, p2, p3) => {
//   console.log({ p0, p1, p2, p3 })
//   var p0_x = p0.x
//   var p0_y = p0.y
//   var p1_x = p1.x
//   var p1_y = p1.y
//   var p2_x = p2.x
//   var p2_y = p2.y
//   var p3_x = p3.x
//   var p3_y = p3.y

//   var s1_x, s1_y, s2_x, s2_y
//   s1_x = p1_x - p0_x
//   s1_y = p1_y - p0_y
//   s2_x = p3_x - p2_x
//   s2_y = p3_y - p2_y

//   var t = (s2_x * (p0_y - p2_y) - s2_y * (p0_x - p2_x)) / (-s2_x * s1_y + s1_x * s2_y)

//   return p0_x + t * s1_x
// }
