import { createSelector } from 'reselect'
import pMap from '../helpers/p-map'
import moment from 'moment'

import { getVisibleContracts, getContracts } from '../reducers/contracts'
import { getTransactions } from '../reducers/transactions'
import { fetchContractTransactions } from '../actions/transactions'
import { dateFormat } from '../helpers/formater'
import { fetchParcelsNames } from '../actions/parcels'
import { getParcelNames } from '../reducers/parcels'

// Actions
const SELECT_CONTRACT = 'esite/deals/SELECT_CONTRACT'
const FETCH_DEALS_REQUEST = 'esite/deals/FETCH_DEALS_REQUEST'
const FETCH_DEALS_FAILURE = 'esite/deals/FETCH_DEALS_FAILURE'
const FETCH_DEALS_SUCCESS = 'esite/deals/FETCH_DEALS_SUCCESS'

// Reducer
const deals = (
  state = {
    selectedContract: null,
    parcels: {},
    isLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case SELECT_CONTRACT:
      return {
        ...state,
        selectedContract: action.contractId,
      }
    case FETCH_DEALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_DEALS_FAILURE:
    case FETCH_DEALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default deals

// Selectors
export const getSelectedContractId = (state) => state.deals.selectedContract
export const getIsLoading = (state) => state.deals.isLoading

export const getTableData = createSelector(
  getTransactions,
  getVisibleContracts,
  getContracts,
  getParcelNames,
  (transactions, visibleContracts, contracts, parcels) => {
    return Object.entries(transactions)
      .filter(([contractId]) => visibleContracts.includes(Number(contractId)))
      .reduce(
        (acc, [contractId, transactions]) => [
          ...acc,
          ...transactions.map((t) => {
            const contract = contracts.find((c) => c.id.toString() === contractId)
            return {
              contract: contract.CtrNomCourt,
              nrjId: contract.CtrNrjId,
              parcel: parcels[t.idLot.toString()],
              parcelId: t.idLot,
              product: t.TrsProduit,
              date: t.TrsDate,
              dateFormatted: moment(t.TrsDate).format(dateFormat()),
              volume: {
                value: t.TrsVolume,
                unit: t.TrsVolumeUnite,
              },
              price: {
                value: t.TrsPrix,
                unit: t.TrsUnite,
              },
              visualize: {
                contractId: contractId,
                lotId: t.idLot.toString(),
                year: t.TrsPerAnnee,
              },
              id: `${contractId}-${t.idLot}-${t.TrsDate}-${t.TrsProduit}`,
            }
          }),
        ],
        [],
      )
  },
)

// Thunks
export const selectContract = (contractId) => (dispatch) => {
  dispatch({ type: SELECT_CONTRACT, contractId })
}

export const fetchContractsTransactions = (contractIds, quotationId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: FETCH_DEALS_REQUEST })

  const actions = contractIds.reduce(
    (acc, id) => [
      ...acc,
      () => fetchContractTransactions(id, quotationId)(dispatch),
      () => fetchParcelsNames(id)(dispatch, getState),
    ],
    [],
  )

  try {
    await pMap(actions, (element) => element(), { concurrency: 4 })
    dispatch({ type: FETCH_DEALS_SUCCESS })
  } catch (error) {
    dispatch({ type: FETCH_DEALS_FAILURE })
  }
}
