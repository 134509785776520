import React from 'react'
import { ModalBody } from 'reactstrap'
import jsPDF from 'jspdf'
import moment from 'moment'
import filenamify from 'filenamify'
import { useTranslation } from 'react-i18next'

import { Footer } from './Footer'
import { Reporting } from './Reporting'

const usePdf = (clientName) => {
  const { t } = useTranslation()
  const pdf = React.useRef(null)
  const [progress, setProgress] = React.useState(0)

  const addImages = React.useCallback((index, images, total) => {
    images.forEach((image) => {
      if (index === 0) {
        pdf.current.addImage(image, 'JPEG', 0, 0, 29.7, 21, '', 'SLOW')
      } else if (index === 1) {
        pdf.current.addPage('a4', 'portrait')
        pdf.current.addImage(image, 'JPEG', 0, 0, 21, 29.7, '', 'SLOW')
      } else if (index > 1) {
        pdf.current.addPage('a4', 'portrait')
        pdf.current.addImage(image, 'JPEG', 0, 0, 21, 29.7, '', 'SLOW')
      }
    })
    setProgress(((index + 1) * 100) / total)
  }, [])

  const createPDF = React.useCallback(() => {
    const date = moment().format('YYYY-MM-DD')
    const title = t('reporting.fileTitle')
    const filename = filenamify(`${clientName}_${title}_${date}.pdf`)
    pdf.current.save(filename)
  }, [clientName, t])

  React.useEffect(() => {
    pdf.current = new jsPDF({ orientation: 'l', unit: 'cm', format: 'a4', compress: true })
  }, [])

  return { createPDF, addImages, progress }
}

export const ReportingPreview = ({ toggleModal, openOptions, clientName }) => {
  const { createPDF, addImages, progress } = usePdf(clientName)

  return (
    <>
      <ModalBody
        style={{
          padding: 0,
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          backgroundColor: '#333',
        }}
      >
        <Reporting addImages={addImages} clientName={clientName} />
      </ModalBody>

      <Footer {...{ openOptions, toggleModal, progress, createPDF }} />
    </>
  )
}
