/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { Spinner } from 'reactstrap'

const isEmpty = (prop) =>
  prop === null ||
  prop === undefined ||
  (Object.prototype.hasOwnProperty.call(prop, 'length') && prop.length === 0) ||
  (prop.constructor === Object && Object.keys(prop).length === 0)

const Loader = (loadingProp) => (WrappedComponent) => {
  return class Loader extends Component {
    render() {
      return (
        <div>
          <WrappedComponent {...this.props} />
          {isEmpty(this.props[loadingProp]) && (
            <div
              css={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(255, 255, 255, 0.5)',
              }}
            >
              <Spinner color="primary" />
            </div>
          )}
        </div>
      )
    }
  }
}

export default Loader
