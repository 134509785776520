import { superFetch } from 'commons'

export const fetchAlerts = (contractIds, { withEvtOnly = false, withLastValues = false } = {}) => {
  return superFetch({
    url: `alertes/search?withEvtOnly=${withEvtOnly}&withLastValues=${withLastValues}`,
    method: 'POST',
    body: contractIds,
  })
}

export const fetchAlert = (alertId) => {
  return superFetch({ url: `alertes/${alertId}` })
}

export const fetchParcelAlerts = (parcelId) => {
  return superFetch({ url: `alertes/lots/${parcelId}` })
}

export const fetchParcelQuotationAlerts = (parcelId, quotationId) => {
  return superFetch({
    url: `lots/${parcelId}/cotation/${quotationId}/alertes`,
  })
}

export const fetchAlertTypes = () => {
  return superFetch({ url: 'alertes/types' })
}

export const addAlert = (alert) => {
  return superFetch({ url: 'alertes', method: 'POST', body: alert })
}

export const deleteAlert = (alertId) => {
  return superFetch({ url: `alertes/${alertId}`, method: 'DELETE' })
}

export const updateAlertDailyFlag = (alert, isDaily) => {
  return superFetch({
    url: `alertes/${alert.Id}/toggledaily?state=${isDaily}`,
    method: 'POST',
  })
}

export const updateAlertMailFlag = (alert, mailEnabled) => {
  return superFetch({
    url: `alertes/${alert.Id}/togglemail?state=${mailEnabled}`,
    method: 'POST',
  })
}

export const updateAlert = (alert) => {
  return superFetch({
    url: `alertes/${alert.Id}`,
    method: 'PUT',
    body: alert,
  })
}

export const fetchAlertsGraph = (quotationId) => {
  return superFetch({ url: `lots/quotation/${quotationId}/graph` })
}

export const fetchAlertGraph = (parcelId, quotationId) => {
  return superFetch({
    url: `lots/${parcelId}/cotation/${quotationId}/graph?showAlertes=true`,
  })
}

export const fetchAlertEvents = (alertIds) => {
  return superFetch({
    url: `alertes/events`,
    method: 'POST',
    body: alertIds,
  })
}
