import React from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import {
  hideContract,
  showContract,
  setUniqueContractVisible,
  removeContracts,
} from 'actions/contractSelection'

import { isAdmin } from 'selectors/user'

const CurrentSelectionItem = (props) => {
  const { contract, visibleContracts, hideContract, showContract } = props

  const toggleContract = React.useCallback(() => {
    if (visibleContracts.includes(contract.id)) {
      hideContract()
    } else {
      showContract()
    }
  }, [contract.id, hideContract, showContract, visibleContracts])

  const location = useLocation()
  const history = useHistory()

  const { CtrNomCourt, CtrNrjId, id } = contract
  const isSelectionItemVisible = visibleContracts.includes(id)

  const isZoomOnContract = props.selectedContract && props.selectedContract.id === id

  const isOnExec = isZoomOnContract && location.pathname.startsWith('/exec')

  const isOnTransactions = isZoomOnContract && location.pathname.startsWith('/transactions')
  const isOnStratRW = isZoomOnContract && location.pathname.startsWith('/admin')

  return (
    <li
      className={
        'd-flex selected-contract' +
        (isSelectionItemVisible ? ' visible' : '') +
        (isZoomOnContract ? ' zoom' : '')
      }
    >
      {CtrNrjId === 2 && <i className="icon-elec" />}
      {CtrNrjId === 1 && <i className="icon-gaz" />}
      <span className="w-100" style={{ marginRight: 8 }}>
        {CtrNomCourt}
      </span>
      <ButtonGroup className="ml-auto">
        {props.isAdmin && (
          <div className={'btn btn-secondary' + (isZoomOnContract ? ' zoom' : '')}>
            <i
              onClick={() => {
                props.setUniqueContractVisible(true)
                history.push(`/transactions/${contract.id}`)
              }}
              className={'icon-collapse-plus' + (isOnTransactions ? ' selected-activity' : '')}
            />
          </div>
        )}

        {props.isAdmin && (
          <div className={'btn btn-secondary' + (isZoomOnContract ? ' zoom' : '')}>
            <i
              onClick={() => {
                props.setUniqueContractVisible(false)
                history.push('/admin/')
              }}
              className={'icon-ico-menu-parameters' + (isOnStratRW ? ' selected-activity' : '')}
            />
          </div>
        )}

        <div className={'btn btn-secondary' + (isZoomOnContract ? ' zoom' : '')}>
          <i
            onClick={() => {
              if (isOnExec && isZoomOnContract) {
                props.removeUniqueContractVisible()
              } else {
                props.setUniqueContractVisible(true)
                history.push('/exec')
              }
            }}
            className={'icon-diag' + (isOnExec ? ' selected-activity' : '')}
          />
        </div>
        <div
          onClick={props.removeContract}
          className={'btn btn-secondary' + (isZoomOnContract ? ' zoom' : '')}
        >
          <i className="icon-cross" />
        </div>
        <div onClick={toggleContract} className="btn btn-secondary">
          <i className="icon-eye" />
        </div>
      </ButtonGroup>
    </li>
  )
}

const mapStateToProps = (state) => ({
  visibleContracts: state.contracts.visibleContracts,
  selectedContract: state.contracts.zoomOnContract,
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = (dispatch, { contract }) => ({
  showContract: () => {
    dispatch(showContract(contract.id))
  },
  hideContract: () => {
    dispatch(hideContract(contract.id))
  },
  setUniqueContractVisible: (readonly) => {
    dispatch(setUniqueContractVisible(contract, readonly))
  },
  removeUniqueContractVisible: () => {
    dispatch(setUniqueContractVisible(null))
  },
  removeContract: () => {
    dispatch(removeContracts([contract.id]))
  },
})

CurrentSelectionItem.propTypes = {
  contract: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentSelectionItem)
