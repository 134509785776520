/** @jsxImportSource @emotion/react */
import React, { useRef, useCallback, useEffect, useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import groupBy from 'lodash/groupBy'

import PortfolioGraph from 'components/portfolio/PortfolioGraph'
import { fetchContractsDetail, fetchMonthlyContractCoveragePerCountry } from 'actions/contract'
import {
  getFilteredSelection,
  getStartYear,
  getEndYear,
  getContractListObjects,
  getContractDetailObjects,
} from 'store/reporting'
import { wait } from 'helpers/promises'
import { PageFooter } from './PageFooter'

const getYear = (date) => moment(date, ['DD/MM/YYYY', 'YYYY-MM-DDThh:mm:ss']).year()

const useYears = (contracts) => {
  const selectedStartYear = useSelector(getStartYear)
  const selectedEndYear = useSelector(getEndYear)

  const startYear = React.useMemo(() => {
    const contractStartYears = contracts.map((c) => getYear(c.CtrDateDebut))
    return Math.max(selectedStartYear, Math.min(...contractStartYears))
  }, [contracts, selectedStartYear])

  const endYear = React.useMemo(() => {
    const contractEndYears = contracts.map((c) => getYear(c.CtrDateFin))
    return Math.min(selectedEndYear, Math.max(...contractEndYears))
  }, [contracts, selectedEndYear])

  return { startYear, endYear }
}

const Page2 = ({ onLoad, pageIndex, total }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const done = useRef(false)
  const onGraphLoad = useCallback(() => {
    if (done.current) return
    done.current = true
    wait(3000).then(() => {
      onLoad()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selection = useSelector(getFilteredSelection)
  const contractListObjects = useSelector(getContractListObjects)
  const visibleContractDetailObjects = useSelector(getContractDetailObjects)

  const { startYear, endYear } = useYears(contractListObjects)

  useEffect(() => {
    if (selection.length === 0) return
    dispatch(fetchContractsDetail(selection))
  }, [dispatch, selection])

  useEffect(() => {
    if (contractListObjects.length === 0) return
    const start = moment({ year: startYear })
    const end = moment({ year: endYear }).endOf('year')
    dispatch(fetchMonthlyContractCoveragePerCountry(contractListObjects, start, end))
  }, [contractListObjects, dispatch, endYear, startYear])

  const pageSize = Object.values(groupBy(contractListObjects, 'CtrIdPays')).some(
    (arr) => arr.length > 5,
  )
    ? 2
    : 3

  const range = useMemo(() => {
    const countryCount = [...new Set(visibleContractDetailObjects.map((c) => c.Pays.Id))].length
    return Array.from({ length: Math.ceil(countryCount / pageSize) }, (_, i) => i * pageSize)
  }, [pageSize, visibleContractDetailObjects])

  return (
    <>
      {range.map((index) => (
        <div
          key={index}
          className="page"
          css={(theme) => ({
            position: 'relative',
            margin: '15px auto',
            fontFamily: theme.fontFamily,
            width: '21cm',
            height: '29.7cm',
            padding: '1cm',
            background: '#fff',
            display: 'grid',
            gridGap: 15,
            gridTemplateRows: 'auto auto 1fr',
            gridTemplateAreas: `
              'header1'
              'header2'
              'portfolio'
            `,
            header: {
              color: '#fff',
              background: theme.blue,
              padding: '4px 8px',
            },
            h5: {
              fontSize: 16,
              marginBottom: 4,
              color: '#fff',
            },
          })}
        >
          <header css={{ gridArea: 'header1' }}>
            <h5>{t('reporting.title')}</h5>
            <div>{moment().format('DD/MM/YYYY')}</div>
          </header>

          <header css={{ gridArea: 'header2' }}>{t('execution.coverageRecap')}</header>

          <section
            css={{
              gridArea: 'portfolio',
              marginTop: 15,
            }}
          >
            <PortfolioGraph
              startYear={startYear}
              endYear={endYear}
              print={true}
              callback={onGraphLoad}
              contractListObjects={visibleContractDetailObjects}
              visibleContracts={contractListObjects}
              start={index}
              end={index + pageSize}
              marginSize={180}
            />
          </section>

          <PageFooter total={total} index={pageIndex + 1} />
        </div>
      ))}
    </>
  )
}

export default Page2
