/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTheme } from '@emotion/react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import flatten from 'lodash/flatten'
import pAll from 'p-all'
import uniqBy from 'lodash/uniqBy'
import flattenDeep from 'lodash/flattenDeep'

import { wait } from 'helpers/promises'

import {
  fetchStrategyFollowup,
  fetchMonthlyContractCoverage,
  fetchMonthlyCoverageFollowup,
} from 'actions/contract'
import { fetchLotQuotationGraph } from 'actions/quotations'
import { fetchLotQuotationTransactions } from 'actions/transactions'
import { fetchLotQuotationPerformance } from 'actions/performance'
import { fetchParcelQuotationAlerts } from 'actions/alerts'
import { getStrategyFollowup } from 'reducers/contracts'

import StrategyFollowupGraph from 'components/execution/StrategyFollowupGraph'
import MonthlyCoverageGraph from 'components/execution/MonthlyCoverageGraph'
import CoverageFollowupGraph from 'components/execution/CoverageFollowupGraph'
import ReportingQuotation from './ReportingQuotation'
import { PageFooter } from './PageFooter'

import { getStartYear, getEndYear } from 'store/reporting'

const splitArray = (a, n) =>
  Array.from({ length: Math.ceil(a.length / n) }, (x, i) => a.slice(i * n, (i + 1) * n))

const ReportingPageContract = ({ parcels, year, contract, index, onLoad, pageIndex, total }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const startYear = useSelector(getStartYear)
  const endYear = useSelector(getEndYear)

  const hedgingPlan = useSelector((state) => getStrategyFollowup(state).planCouverture)

  const monthlyContractCoverage = useSelector(
    (state) => state.contracts.currentSelectionMonthlyContractCoverage,
  )

  const prevIndex = React.useRef(null)

  const quotations = flatten(parcels.map((p) => p.quotations.map((q) => [p.id, q])))

  React.useEffect(() => {
    if (!contract || prevIndex.current === index) return

    const fetchData = async () => {
      try {
        prevIndex.current = index

        const contracts = [contract.id]
        const startDate = moment(year.toString(), 'YYYY').startOf('year')
        const endDate = moment(year.toString(), 'YYYY').endOf('year')
        const actions = flattenDeep([
          // Couverture
          () => dispatch(fetchStrategyFollowup(contracts, startDate, endDate, false)),
          () => dispatch(fetchMonthlyContractCoverage(contracts, startDate, endDate)),
          // Prix
          () => dispatch(fetchMonthlyCoverageFollowup(contracts, startDate, endDate)),
          quotations.map(([parcelId, q]) => {
            return [
              // Graphique des cotations
              () => dispatch(fetchLotQuotationGraph(parcelId, q.IdCotation, true, true)),
              // Limites de prix
              () => dispatch(fetchParcelQuotationAlerts(parcelId, q.IdCotation)),
              // Transactions
              () => dispatch(fetchLotQuotationTransactions(parcelId, q.IdCotation)),
              // Performance
              () => dispatch(fetchLotQuotationPerformance(parcelId, q.IdCotation)),
            ]
          }),
        ])

        await pAll(actions, { concurrency: 4 })

        await wait(5000)
        onLoad()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [index, contract, onLoad, dispatch, startYear, endYear, parcels, quotations, year])

  const allGraphs = useSelector((state) =>
    get(state, ['quotations', 'lotQuotationGraphByQuot'], {}),
  )
  const allAlerts = useSelector((state) => get(state, ['alerts', 'parcelAlertsByQuot'], {}))
  const allTransactions = useSelector((state) =>
    get(state, ['transactions', 'lotTransactionsByQuot'], {}),
  )
  const allPerformances = useSelector((state) =>
    get(state, ['performance', 'lotPerformanceByQuot'], {}),
  )

  const data = quotations.map(([_, { IdLot, IdCotation }]) => ({
    graph: get(allGraphs, [IdLot, IdCotation], null),
    alerts: get(allAlerts, [IdLot, IdCotation], []),
    transactions: get(allTransactions, [IdLot, IdCotation], []),
    performance: get(allPerformances, [IdLot, IdCotation], 0, null),
  }))

  const filteredQuotations = uniqBy(
    quotations
      .map(([_, q]) => q)
      .filter(
        (q, i) =>
          data[i].graph ||
          data[i].performance ||
          data[i].transactions.length > 0 ||
          data[i].alerts.length > 0,
      ),
    'IdCotation',
  )

  const [firstQuotation, ...rest] = filteredQuotations
  const quotationChunks = splitArray(rest, 3)

  if (!contract) return null

  return (
    <div
      css={(theme) => ({
        fontFamily: theme.fontFamily,
        header: {
          color: '#fff',
          background: theme.blue,
          padding: '4px 8px',
        },
        h5: {
          fontSize: 16,
          marginBottom: 4,
          color: '#fff',
        },
        h6: {
          width: '100%',
          fontSize: 12,
          fontWeight: 'bold',
          color: theme.gold,
          borderBottom: `1px solid ${theme.gold}`,
          marginBottom: 10,
        },
        section: {
          width: '100%',
          overflow: 'hidden',
        },
      })}
    >
      <div
        className="page"
        css={{
          position: 'relative',
          margin: '15px auto',
          width: '21cm',
          height: '29.7cm',
          padding: '1cm',
          background: '#fff',
          display: 'grid',
          gridGap: 15,
          gridTemplateRows: 'auto auto auto 2fr 3fr 3fr',
          gridTemplateAreas: `
            'header1'
            'header2'
            'info'
            'couv'
            'price'
            'quotation'
          `,
        }}
      >
        <header css={{ gridArea: 'header1' }}>
          <h5>{t('reporting.title')}</h5>
          <div>{moment().format('DD/MM/YYYY')}</div>
        </header>

        <header
          css={{
            gridArea: 'header2',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {t('reporting.contract')} : {contract.CtrNom} &mdash; {contract.CtrLibellePays} &ndash;{' '}
            {year}
          </div>
          <div></div>
        </header>

        <section css={{ gridArea: 'info' }}>
          <h6>{t('reporting.infos')}</h6>
          <div
            css={{
              padding: '2px 4px',
              background: 'rgba(250, 160, 60, .3)',
              color: theme.gold,
            }}
          >
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {t('reporting.provider')} : {contract.CtrFournisseur}
              </div>
              <div>
                {t('reporting.expiration')}: {contract.CtrDateFin}
              </div>
            </div>
          </div>
        </section>

        <section css={{ gridArea: 'couv' }}>
          <h6>{t('reporting.coverage')}</h6>
          <div css={{ display: 'flex' }}>
            <div css={{ width: '55%' }}>
              {hedgingPlan && (
                <StrategyFollowupGraph hedgingPlan={hedgingPlan} print height={200} />
              )}
            </div>
            <div css={{ width: '45%' }}>
              {monthlyContractCoverage && (
                <MonthlyCoverageGraph
                  currentSelectionMonthlyContractCoverage={monthlyContractCoverage}
                  height={200}
                  print
                />
              )}
            </div>
          </div>
        </section>

        <section css={{ gridArea: 'price' }}>
          <h6>{t('reporting.price')} (€/MWh)</h6>
          <div css={{ display: 'flex' }}>
            <div css={{ width: '88%' }}>
              <CoverageFollowupGraph year={year} />
            </div>
            <div css={{ width: '12%' }}>
              <CoverageFollowupGraph year={year} total />
            </div>
          </div>
        </section>

        {firstQuotation && (
          <ReportingQuotation
            idLot={firstQuotation.IdLot}
            idQuotation={firstQuotation.IdCotation}
            unit={firstQuotation.Cotation.CotUnite}
            name={firstQuotation.Cotation.CotProduit.PdtNom}
          />
        )}

        <PageFooter total={total} index={pageIndex + 1} />
      </div>

      {quotationChunks.map((chunk, index) => {
        return (
          <div
            key={index}
            className="page"
            css={{
              position: 'relative',
              margin: '15px auto',
              width: '21cm',
              height: '29.7cm',
              padding: '1cm',
              background: '#fff',
              '>:not(:last-child)': {
                marginBottom: '1cm',
              },
            }}
          >
            {chunk.map((quot, index) => {
              return (
                <ReportingQuotation
                  key={`${quot.IdCotation}${index}`}
                  idLot={quot.IdLot}
                  idQuotation={quot.IdCotation}
                  unit={quot.Cotation.CotUnite}
                  name={quot.Cotation.CotProduit.PdtNom}
                />
              )
            })}

            <PageFooter total={total} index={pageIndex + index + 2} />
          </div>
        )
      })}
    </div>
  )
}

export default ReportingPageContract
