import React, { Fragment } from 'react'
import { compose, withState, withHandlers, shouldUpdate } from 'recompose'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import HighchartsReact from 'highcharts-react-official'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { createConfig } from './createConfig'
import { createConfigRsi } from './createConfigRsi'

import { Highstock } from 'helpers/highcharts'

//HOC
import Loader from '../../hoc/Loader'

import RangeInputDatepicker from '../common/RangeInputDatepicker'

const chartRef = React.createRef()
const chartRefRsi = React.createRef()

const QuotationGraph = ({ config, configRsi }) => {
  return (
    <>
      {config && (
        <HighchartsReact
          highcharts={Highstock}
          constructorType="stockChart"
          ref={chartRef}
          options={config}
          immutable={true}
        />
      )}

      {configRsi && (
        <HighchartsReact
          highcharts={Highstock}
          constructorType="stockChart"
          ref={chartRefRsi}
          options={configRsi}
          immutable={true}
        />
      )}
    </>
  )
}

const Graph = compose(
  shouldUpdate((props, nextProps) => {
    return props.config.series[0].name !== nextProps.config.series[0].name
  }),
)(QuotationGraph)

export default compose(
  withTranslation(),
  Loader('lotQuotationGraph'),
  withState('dateMin', 'setDateMin', ''),
  withState('dateMax', 'setDateMax', ''),
  withHandlers({
    onDateMinChange: (props) => (date) => {
      const { dateMax, setDateMin } = props
      setDateMin(date)
      if (dateMax) {
        chartRef.current.chart.xAxis[0].setExtremes(
          Number(date.format('x')),
          Number(dateMax.format('x')),
        )
      }
    },
    onDateMaxChange: (props) => (date) => {
      const { dateMin, setDateMax } = props
      setDateMax(date)
      if (dateMin) {
        chartRefRsi.current.chart.xAxis[0].setExtremes(
          Number(dateMin.format('x')),
          Number(date.format('x')),
        )
      }
    },
  }),
)((props) => {
  const {
    lotQuotationGraph,
    t,
    showAlertes,
    showTransactions,
    showMarkers,
    dateMin,
    dateMax,
    setDateMin,
    setDateMax,
    onDateMinChange,
    onDateMaxChange,
    zoomOnIndicators = false,
    print = false,
    height = 500,
    year,
  } = props

  const config =
    lotQuotationGraph && !isEmpty(lotQuotationGraph)
      ? createConfig(
          lotQuotationGraph,
          t,
          showAlertes,
          showTransactions,
          zoomOnIndicators,
          showMarkers,
          print,
          height,
          year,
        )
      : false

  if (!config) return null

  const configRsi =
    lotQuotationGraph && !isEmpty(lotQuotationGraph)
      ? createConfigRsi(
          lotQuotationGraph,
          t,
          showAlertes,
          showTransactions,
          zoomOnIndicators,
          showMarkers,
          print,
          height,
          year,
        )
      : false

  if (config && config.chart) {
    config.chart.events = {
      render: (e) => {
        const { min, max: chartMax } = e.target.xAxis[0]
        const max = Math.min(chartMax, last(lotQuotationGraph.serieCotation.data).x)
        setDateMin(moment(min))
        setDateMax(moment(max))
      },
    }
  }

  return (
    <Fragment>
      {!print && (
        <RangeInputDatepicker
          min={dateMin}
          max={dateMax}
          onMinChange={onDateMinChange}
          onMaxChange={onDateMaxChange}
        />
      )}
      <Graph config={config} configRsi={configRsi} />
    </Fragment>
  )
})
