import { combineReducers } from 'redux'
import get from 'lodash/get'
import set from 'lodash/fp/set'

import {
  FETCH_AVAILABLE_QUOTATIONS_SUCCESS,
  ADD_QUOTATIONS_SUCCESS,
  DELETE_QUOTATIONS_SUCCESS,
  FETCH_LOT_QUOTATION_GRAPH_SUCCESS,
  FETCH_PARCEL_QUOTATIONS_SUCCESS,
  FETCH_PARCEL_QUOTATIONS_FAILURE,
  FETCH_LOT_QUOTATIONS_FOR_DEAL_SUCCESS,
  FETCH_CONTRACTS_QUOTATIONS_SUCCESS,
  RESET_PARCEL_QUOTATIONS,
  RESET_LOT_QUOTATION_GRAPH,
  FETCH_MY_QUOTATIONS_REQUEST,
  FETCH_MY_QUOTATIONS_SUCCESS,
  FETCH_MY_QUOTATIONS_FAILURE,
  ADD_MY_QUOTATIONS_SUCCESS,
  DELETE_MY_QUOTATION_SUCCESS,
  SET_QUOTATION_GRAPH_STEP,
  FETCH_QUOTATION_GRAPH_REQUEST,
  FETCH_QUOTATION_GRAPH_SUCCESS,
  FETCH_QUOTATION_GRAPH_FAILURE,
  FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS,
  FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE,
  FETCH_QUOTATION_AND_PARCELS_SUCCESS,
  FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_SUCCESS,
  FETCH_DMR_QUOTATIONS_SUCCESS,
  ADD_DMR_QUOTATIONS_SUCCESS,
  TOGGLE_DMR_QUOTATION_SUCCESS,
  DELETE_DMR_QUOTATION_SUCCESS,
  FETCH_AVAILABLE_DMR_QUOTATIONS_SUCCESS,
  FETCH_PARCEL_QUOTATIONS_REQUEST,
  TOGGLE_QUOTATION_BOLLINGER,
  TOGGLE_QUOTATION_TUNNEL,
} from '../actions'

const availableQuotations = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_AVAILABLE_QUOTATIONS_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.quotations
      return newState
    default:
      return state
  }
}

const myAvailableQuotations = (state = [], action) => {
  switch (action.type) {
    case FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS:
      return action.quotations
    case FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE:
    default:
      return state
  }
}

const currentlySelectedContractQuotations = (state = [], action) => {
  switch (action.type) {
    case FETCH_CONTRACTS_QUOTATIONS_SUCCESS:
      return action.quotations
    default:
      return state
  }
}

const contractQuotations = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_CONTRACTS_QUOTATIONS_SUCCESS:
      newState = { ...state }
      newState[action.contractId] = action.quotations
      return newState
    default:
      return state
  }
}

const userQuotations = (state = [], action) => {
  let newState
  switch (action.type) {
    case FETCH_MY_QUOTATIONS_SUCCESS:
      newState = action.quotations
      return newState
    case ADD_MY_QUOTATIONS_SUCCESS:
      newState = [...state, ...action.quotations]
      return newState
    case DELETE_MY_QUOTATION_SUCCESS:
      newState = state.filter((q) => q.Id !== action.quotationId)
      return newState
    case FETCH_MY_QUOTATIONS_FAILURE:
      return []
    default:
      return state
  }
}

const userQuotationsLoading = (state = false, action) => {
  switch (action.type) {
    case FETCH_MY_QUOTATIONS_REQUEST:
      return true
    case FETCH_MY_QUOTATIONS_SUCCESS:
    case FETCH_MY_QUOTATIONS_FAILURE:
      return false
    default:
      return state
  }
}

const userQuotationsError = (state = null, action) => {
  switch (action.type) {
    case FETCH_MY_QUOTATIONS_REQUEST:
    case FETCH_MY_QUOTATIONS_SUCCESS:
      return null
    case FETCH_MY_QUOTATIONS_FAILURE:
      return action.message
    default:
      return state
  }
}

const userQuotationOptions = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_QUOTATION_BOLLINGER: {
      const b = get(state, [action.id, 'BollingerBand'], false)
      return set([action.id, 'BollingerBand'], !b)(state)
    }
    case TOGGLE_QUOTATION_TUNNEL: {
      const b = get(state, [action.id, 'TunnelMensuel'], false)
      return set([action.id, 'TunnelMensuel'], !b)(state)
    }
    default:
      return state
  }
}

const userQuotationGraph = (
  state = {
    data: {},
    error: null,
    isLoading: false,
    step: 4,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_QUOTATION_GRAPH_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      }
    case FETCH_QUOTATION_GRAPH_SUCCESS:
      return {
        ...state,
        data: action.graph,
        error: null,
        isLoading: false,
      }
    case FETCH_QUOTATION_GRAPH_FAILURE:
      return {
        ...state,
        error: action.message,
        isLoading: false,
      }
    case SET_QUOTATION_GRAPH_STEP:
      return {
        ...state,
        step: action.payload,
      }
    default:
      return state
  }
}

const parcelQuotationsErrors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PARCEL_QUOTATIONS_REQUEST:
    case FETCH_PARCEL_QUOTATIONS_SUCCESS:
      return {
        ...state,
        [action.parcelId]: null,
      }
    case FETCH_PARCEL_QUOTATIONS_FAILURE:
      return {
        ...state,
        [action.parcelId]: action.message,
      }
    default:
      return state
  }
}

const parcelQuotations = (state = {}, action) => {
  let newState
  let existingQuotationsOfContract
  switch (action.type) {
    case RESET_PARCEL_QUOTATIONS:
      newState = { ...state }
      newState[action.parcelId] = []
      return newState
    case FETCH_PARCEL_QUOTATIONS_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.quotations
      return newState
    case ADD_QUOTATIONS_SUCCESS:
      newState = { ...state }
      existingQuotationsOfContract = newState[action.parcelId] || []
      newState[action.parcelId] = [...existingQuotationsOfContract, ...action.quotations]
      return newState
    case DELETE_QUOTATIONS_SUCCESS:
      newState = { ...state }
      existingQuotationsOfContract = newState[action.parcelId] || []
      newState[action.parcelId] = existingQuotationsOfContract.filter(
        (c) => c.Id !== action.quotationId,
      )
      return newState
    default:
      return state
  }
}

const lotQuotationsForDeals = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_LOT_QUOTATIONS_FOR_DEAL_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.quotations
      return newState
    default:
      return state
  }
}

const lotQuotationGraph = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_LOT_QUOTATION_GRAPH_SUCCESS:
      newState = { ...state }
      newState[action.lotId] = action.lotQuotationGraph
      return newState
    case RESET_LOT_QUOTATION_GRAPH:
      newState = { ...state }
      newState[action.lotId] = []
      return newState
    default:
      return state
  }
}

const lotQuotationGraphByQuot = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOT_QUOTATION_GRAPH_SUCCESS:
      return {
        ...state,
        [action.lotId]: {
          ...state[action.lotId],
          [action.quotationId]: action.lotQuotationGraph,
        },
      }
    default:
      return state
  }
}

const parcelAndQuotations = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_QUOTATION_AND_PARCELS_SUCCESS:
      newState = { ...state }
      newState.quotations = action.quotations
      return newState
    default:
      return state
  }
}

const parcelAndQuotationsByPeriod = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_SUCCESS:
      newState = { ...state }
      newState.quotations = action.quotations
      return newState
    default:
      return state
  }
}

const dmr = (
  state = {
    available: [],
    quotations: [],
  },
  action,
) => {
  switch (action.type) {
    case FETCH_DMR_QUOTATIONS_SUCCESS:
    case ADD_DMR_QUOTATIONS_SUCCESS:
    case TOGGLE_DMR_QUOTATION_SUCCESS:
    case DELETE_DMR_QUOTATION_SUCCESS:
      return {
        ...state,
        quotations: action.quotations,
      }
    case FETCH_AVAILABLE_DMR_QUOTATIONS_SUCCESS:
      return {
        ...state,
        available: action.quotations,
      }
    default:
      return state
  }
}

export default combineReducers({
  availableQuotations,
  myAvailableQuotations,
  userQuotations,
  userQuotationsLoading,
  userQuotationsError,
  userQuotationOptions,
  userQuotationGraph,
  contractQuotations,
  parcelQuotations,
  parcelQuotationsErrors,
  lotQuotationsForDeals,
  lotQuotationGraph,
  lotQuotationGraphByQuot,
  currentlySelectedContractQuotations,
  parcelAndQuotations,
  parcelAndQuotationsByPeriod,
  dmr,
})

export const getContractQuotations = (state, contractId) =>
  state.quotations.contractQuotations[contractId] || []

export const getParcelQuotations = (state, parcelId) =>
  state.quotations.parcelQuotations[parcelId] || []

export const getParcelQuotationsError = (state, parcelId) =>
  state.quotations.parcelQuotationsErrors[parcelId] || null

export const getLotQuotationsForDeals = (state, parcelId) =>
  state.quotations.lotQuotationsForDeals[parcelId] || []

export const getAllQuotations = (state) => state.quotations.parcelAndQuotations.quotations || []
export const getAllQuotationsByPeriod = (state) =>
  state.quotations.parcelAndQuotationsByPeriod.quotations || []

export const getQuotationGraph = (state, quotationId) => {
  if (
    state.quotations.userQuotations &&
    state.quotations.userQuotations.length < 1 &&
    !state.quotations.quotationGraph
  )
    return []

  return state.quotations.quotationGraph[quotationId] || null
}

export const getMarketQuotationGraphStep = (state) => state.quotations.userQuotationGraph.step
export const getMarketQuotationGraph = (state) => state.quotations.userQuotationGraph

export const getAvailableQuotations = (state, parcelId) =>
  state.quotations.availableQuotations[parcelId] || []

export const getLotQuotationGraph = (state, lotId) => {
  if (lotId && state.quotations.lotQuotationGraph) {
    return state.quotations.lotQuotationGraph[lotId] || []
  }
  return []
}

export const getAllLotQuotationGraph = (state) => state.quotations.lotQuotationGraph

export const getQuotations = (state) => state.quotations.currentlySelectedContractQuotations
export const getUserQuotations = (state) => state.quotations?.userQuotations ?? []
export const getUserQuotationsLoading = (state) => state.quotations?.userQuotationsLoading ?? false
export const getUserQuotationsError = (state) => state.quotations?.userQuotationsError ?? null

export const getUserQuotationBollingerOption = (id) => (state) =>
  get(state, ['quotations', 'userQuotationOptions', id, 'BollingerBand'], false)

export const getUserQuotationTunnelOption = (id) => (state) =>
  get(state, ['quotations', 'userQuotationOptions', id, 'TunnelMensuel'], false)

export const getUserQuotationsWithOptions = (ids) => (state) => {
  return ids.map((Id) => ({
    Id,
    TunnelMensuel: getUserQuotationTunnelOption(Id)(state),
    BollingerBand: getUserQuotationBollingerOption(Id)(state),
  }))
}
