/** @jsxImportSource @emotion/react */
import React, { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaLongArrowAltRight } from 'react-icons/fa'
import last from 'lodash/last'

import Select from 'components/common/Select'
import { fetchFilters } from 'actions/contractSelection'
import { setStartYear, setEndYear, getStartYear, getEndYear } from 'store/reporting'

export const ReportingFilters = () => {
  const dispatch = useDispatch()
  const startYear = useSelector(getStartYear)
  const endYear = useSelector(getEndYear)

  React.useEffect(() => {
    dispatch(fetchFilters())
  }, [dispatch])

  const availableYears = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const years = Array.from({ length: 10 }, (_, i) => currentYear - 5 + i)
    return years.map((value) => ({ value, label: value }))
  }, [])

  useEffect(() => {
    if (endYear === null && availableYears.length > 0) {
      dispatch(setEndYear(last(availableYears).value))
    }
  }, [availableYears, dispatch, endYear])

  useEffect(() => {
    if (startYear === null && availableYears.length > 0) {
      dispatch(setStartYear(availableYears[0].value))
    }
  }, [availableYears, dispatch, startYear])

  const changeStartYear = useCallback(
    (e) => {
      dispatch(setStartYear(e ? e.value : null))
    },
    [dispatch],
  )

  const changeEndYear = useCallback(
    (e) => {
      dispatch(setEndYear(e ? e.value : null))
    },
    [dispatch],
  )

  const startOptions = availableYears.filter((option) => !endYear || option.value <= endYear)
  const endOptions = availableYears.filter((option) => !startYear || option.value >= startYear)

  return (
    <div
      className="shadow-sm"
      css={{
        background: '#fff',
        padding: '20px 30px',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          '>*:not(:last-child)': { marginRight: 10 },
        }}
      >
        <h6 css={{ margin: '0 2rem 4px 0' }}>Période du rapport</h6>
        <Select
          value={availableYears.find((option) => option.value === startYear)}
          options={startOptions}
          onChange={changeStartYear}
          width={100}
          fontSize={12}
          bordered
          placeholder="Début"
        />

        <FaLongArrowAltRight size={16} />

        <Select
          value={availableYears.find((option) => option.value === endYear)}
          options={endOptions}
          onChange={changeEndYear}
          width={100}
          fontSize={12}
          bordered
          placeholder="Fin"
        />
      </div>
    </div>
  )
}
