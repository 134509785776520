import React from 'react'
import { Row, Col, PopoverBody, Button, Popover, Input } from 'reactstrap'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAvailableQuotations, addQuotations } from 'actions/quotations'
import { getAvailableQuotations } from 'reducers/quotations'
import * as cotApi from 'api/quotations'


const QuotationsPopover = ({ isOpen, togglePopover, parcel }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const availableQuotations = useSelector((state) => getAvailableQuotations(state, parcel.id))
  const [availableQuotations, setAvailableQuotations] = React.useState([])
  const [searchFilter, setSearchFilter] = React.useState('')
  const [selectedQuotations, setSelectedQuotations] = React.useState([])
  const [filteredQuotations, setFilteredQuotations] = React.useState(availableQuotations)

  React.useEffect(() => {
    async function fetchData() {
      setAvailableQuotations(await cotApi.fetchAvailableQuotations(parcel.id))
      dispatch(fetchAvailableQuotations(parcel.id))
    }
    fetchData()
  }, [dispatch, parcel.id])

  React.useEffect(() => {
    setFilteredQuotations(availableQuotations)
  }, [availableQuotations])

  const toggleQuotation = (quotation, shouldToggleOn) => () =>
    setSelectedQuotations(
      shouldToggleOn
        ? [...selectedQuotations, quotation]
        : selectedQuotations.filter((p) => p.id !== quotation.id),
    )

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(addQuotations(parcel.id, selectedQuotations))
    togglePopover()
  }

  const onSearchChange = (e) => {
    const searchFilter = e.target.value.toLowerCase()
    setSearchFilter(searchFilter)

    let filteredQuotations = [...availableQuotations]
    searchFilter.split(' ').forEach((str) => {
      if (str !== '') {
        filteredQuotations = filteredQuotations.filter(
          (quotation) =>
            quotation.CotProduit.PdtNom.toLowerCase().includes(str) ||
            quotation.CotMarche.toLowerCase().includes(str) ||
            quotation.CotPays.toLowerCase().includes(str) ||
            quotation.CotProduit.PdtNrj.toLowerCase().includes(str),
        )
      }
    })
    setFilteredQuotations(filteredQuotations)
  }

  return (
    <Popover
      className="quotation-popover"
      placement="left"
      isOpen={isOpen}
      target="addProduct"
      toggle={togglePopover}
    >
      <PopoverBody>
        <form onSubmit={onSubmit}>
          <Row>
            <Col>
              <Button close onClick={togglePopover} />
              <label className="title">{t('strategy.quotationsPopover.search')}</label>
              <Input value={searchFilter} type="text" name="searchBox" onChange={onSearchChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                className="table table-striped table-bordered mt-4"
                sortable={['product', 'market', 'country', 'year']}
                itemsPerPage={15}
              >
                <Thead>
                  <Th column="select" className="quotation-selection">
                    {' '}
                  </Th>
                  <Th column="nrj">{t('strategy.alerts.commodity')}</Th>
                  <Th column="product">{t('strategy.alerts.product')}</Th>
                  <Th column="market">{t('strategy.alerts.market')}</Th>
                  <Th column="country">{t('strategy.alerts.country')}</Th>
                  <Th column="year">{t('strategy.alerts.year')}</Th>
                </Thead>
                {filteredQuotations.map((i, index) => {
                  const isSelected = selectedQuotations.findIndex((p) => p.id === i.id) !== -1
                  return (
                    <Tr key={index}>
                      <Td column="select" className="quotation-selection" value={isSelected}>
                        <Input
                          type="checkbox"
                          checked={isSelected}
                          onChange={toggleQuotation(i, !isSelected)}
                        />
                      </Td>
                      <Td column="nrj">{i.CotProduit.PdtNrj}</Td>
                      <Td column="product" value={i.CotProduit.PdtNom}>
                        <strong>{i.CotProduit.PdtNom}</strong>
                      </Td>
                      <Td column="market">{i.CotMarche}</Td>
                      <Td column="country">{i.CotPays}</Td>
                      <Td column="year">{i.CotAnnee}</Td>
                    </Tr>
                  )
                })}
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="float-right rounded" color="primary" type="submit" style={{ color: 'black' }}>
                {`${t('strategy.quotationsPopover.add')} (${selectedQuotations.length})`}
              </Button>
            </Col>
          </Row>
        </form>
      </PopoverBody>
    </Popover>
  )
}

export default QuotationsPopover
