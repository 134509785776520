/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import img1 from 'assets/pdf/page1.jpg'

const Page1 = ({ onLoad, clientName }) => {
  const date = moment().format('DD/MM/YYYY')

  const { t } = useTranslation()

  React.useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className="page"
      css={(theme) => ({
        margin: '15px auto',
        fontFamily: theme.fontFamily,
        width: '29.7cm',
        height: '21cm',
        padding: '1cm',
        background: '#fff',
        color: theme.gold,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        img: {
          width: '100%',
        },
      })}
    >
      <img src={img1} alt="Eleneo" />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: 24,
        }}
      >
        <span>{clientName}</span>
        <span>{t('reporting.title')}</span>
        <span>{date}</span>
      </div>
    </div>
  )
}

export default Page1
