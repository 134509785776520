export const FETCH_CONTRACTS_REQUEST = 'FETCH_CONTRACTS_REQUEST'
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS'
export const FETCH_CONTRACTS_FAILURE = 'FETCH_CONTRACTS_FAILURE'

export const FETCH_CONTRACTS_DETAIL_REQUEST = 'FETCH_CONTRACTS_DETAIL_REQUEST'
export const FETCH_CONTRACTS_DETAIL_SUCCESS = 'FETCH_CONTRACTS_DETAIL_SUCCESS'
export const FETCH_CONTRACTS_DETAIL_FAILURE = 'FETCH_CONTRACTS_DETAIL_FAILURE'

export const FETCH_CONTRACT_CONFIG_STATUS_SUCCESS = 'FETCH_CONTRACT_CONFIG_STATUS_SUCCESS'
export const FETCH_CONTRACT_CONFIG_STATUS_FAILURE = 'FETCH_CONTRACT_CONFIG_STATUS_FAILURE'
export const FETCH_CONTRACT_CONFIG_STATUS_REQUEST = 'FETCH_CONTRACT_CONFIG_STATUS_REQUEST'

export const FETCH_FILTERS_PARAM_REQUEST = 'FETCH_FILTERS_PARAM_REQUEST'
export const FETCH_FILTERS_PARAM_SUCCESS = 'FETCH_FILTERS_PARAM_SUCCESS'
export const FETCH_FILTERS_PARAM_FAILURE = 'FETCH_FILTERS_PARAM_FAILURE'

export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST'
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS'
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE'

export const FETCH_CONTRACT_PARCELS_REQUEST = 'FETCH_CONTRACT_PARCELS_REQUEST'
export const FETCH_CONTRACT_PARCELS_SUCCESS = 'FETCH_CONTRACT_PARCELS_SUCCESS'
export const FETCH_CONTRACT_PARCELS_FAILURE = 'FETCH_CONTRACT_PARCELS_FAILURE'

export const FETCH_CONTRACT_PARCEL_DETAIL_REQUEST = 'FETCH_CONTRACT_PARCEL_DETAIL_REQUEST'
export const FETCH_CONTRACT_PARCEL_DETAIL_SUCCESS = 'FETCH_CONTRACT_PARCEL_DETAIL_SUCCESS'
export const FETCH_CONTRACT_PARCEL_DETAIL_FAILURE = 'FETCH_CONTRACT_PARCEL_DETAIL_FAILURE'

export const FETCH_PARCEL_EDGES_REQUEST = 'FETCH_PARCEL_PROFILE_REQUEST'
export const FETCH_PARCEL_EDGES_SUCCESS = 'FETCH_PARCEL_PROFILE_SUCCESS'
export const FETCH_PARCEL_EDGES_FAILURE = 'FETCH_PARCEL_PROFILE_FAILURE'

export const ADD_PARCEL_EDGES_REQUEST = 'ADD_PARCEL_EDGES_REQUEST'
export const ADD_PARCEL_EDGES_SUCCESS = 'ADD_PARCEL_EDGES_SUCCESS'
export const ADD_PARCEL_EDGES_FAILURE = 'ADD_PARCEL_EDGES_FAILURE'

export const UPDATE_PARCEL_EDGES_REQUEST = 'UPDATE_PARCEL_EDGES_REQUEST'
export const UPDATE_PARCEL_EDGES_SUCCESS = 'UPDATE_PARCEL_EDGES_SUCCESS'
export const UPDATE_PARCEL_EDGES_FAILURE = 'UPDATE_PARCEL_EDGES_FAILURE'

export const DELETE_PARCEL_EDGES_REQUEST = 'DELETE_PARCEL_EDGES_REQUEST'
export const DELETE_PARCEL_EDGES_SUCCESS = 'DELETE_PARCEL_EDGES_SUCCESS'
export const DELETE_PARCEL_EDGES_FAILURE = 'DELETE_PARCEL_EDGES_FAILURE'

export const FETCH_HEDGING_PLAN_REQUEST = 'FETCH_HEDGING_PLAN_REQUEST'
export const FETCH_HEDGING_PLAN_SUCCESS = 'FETCH_HEDGING_PLAN_SUCCESS'
export const FETCH_HEDGING_PLAN_FAILURE = 'FETCH_HEDGING_PLAN_FAILURE'

export const ADD_HEDGING_PLAN_REQUEST = 'ADD_HEDGING_PLAN_REQUEST'
export const ADD_HEDGING_PLAN_SUCCESS = 'ADD_HEDGING_PLAN_SUCCESS'
export const ADD_HEDGING_PLAN_FAILURE = 'ADD_HEDGING_PLAN_FAILURE'

export const DELETE_HEDGING_PLAN_REQUEST = 'DELETE_HEDGING_PLAN_REQUEST'
export const DELETE_HEDGING_PLAN_SUCCESS = 'DELETE_HEDGING_PLAN_SUCCESS'
export const DELETE_HEDGING_PLAN_FAILURE = 'DELETE_HEDGING_PLAN_FAILURE'

export const SET_HEDGING_PLAN_LENGTH_REQUEST = 'SET_HEDGING_PLAN_LENGTH_REQUEST'
export const SET_HEDGING_PLAN_LENGTH_SUCCESS = 'SET_HEDGING_PLAN_LENGTH_SUCCESS'
export const SET_HEDGING_PLAN_LENGTH_FAILURE = 'SET_HEDGING_PLAN_LENGTH_FAILURE'

export const SET_HEDGING_PLAN_SEGMENT_REQUEST = 'SET_HEDGING_PLAN_SEGMENT_REQUEST'
export const SET_HEDGING_PLAN_SEGMENT_SUCCESS = 'SET_HEDGING_PLAN_SEGMENT_SUCCESS'
export const SET_HEDGING_PLAN_SEGMENT_FAILURE = 'SET_HEDGING_PLAN_SEGMENT_FAILURE'

export const UPDATE_HEDGING_PLAN_SEGMENT_REQUEST = 'UPDATE_HEDGING_PLAN_SEGMENT_REQUEST'
export const UPDATE_HEDGING_PLAN_SEGMENT_SUCCESS = 'UPDATE_HEDGING_PLAN_SEGMENT_SUCCESS'
export const UPDATE_HEDGING_PLAN_SEGMENT_FAILURE = 'UPDATE_HEDGING_PLAN_SEGMENT_FAILURE'

export const DELETE_HEDGING_PLAN_SEGMENT_REQUEST = 'DELETE_HEDGING_PLAN_SEGMENT_REQUEST'
export const DELETE_HEDGING_PLAN_SEGMENT_SUCCESS = 'DELETE_HEDGING_PLAN_SEGMENT_SUCCESS'
export const DELETE_HEDGING_PLAN_SEGMENT_FAILURE = 'DELETE_HEDGING_PLAN_SEGMENT_FAILURE'

export const FETCH_HEDGING_PLAN_RECAP_REQUEST = 'FETCH_HEDGING_PLAN_RECAP_REQUEST'
export const FETCH_HEDGING_PLAN_RECAP_SUCCESS = 'FETCH_HEDGING_PLAN_RECAP_SUCCESS'
export const FETCH_HEDGING_PLAN_RECAP_FAILURE = 'FETCH_HEDGING_PLAN_RECAP_FAILURE'

export const FETCH_CONTRACT_COVERAGE_REQUEST = 'FETCH_CONTRACT_COVERAGE_REQUEST'
export const FETCH_CONTRACT_COVERAGE_SUCCESS = 'FETCH_CONTRACT_COVERAGE_SUCCESS'
export const FETCH_CONTRACT_COVERAGE_FAILURE = 'FETCH_CONTRACT_COVERAGE_FAILURE'

export const FETCH_CONTRACT_COVERAGE_RECAP_REQUEST = 'FETCH_CONTRACT_COVERAGE_RECAP_REQUEST'
export const FETCH_CONTRACT_COVERAGE_RECAP_SUCCESS = 'FETCH_CONTRACT_COVERAGE_RECAP_SUCCESS'
export const FETCH_CONTRACT_COVERAGE_RECAP_FAILURE = 'FETCH_CONTRACT_COVERAGE_RECAP_FAILURE'

export const FETCH_MONTHLY_CONTRACT_COVERAGE_REQUEST = 'FETCH_MONTHLY_CONTRACT_COVERAGE_REQUEST'
export const FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS = 'FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS'
export const FETCH_MONTHLY_CONTRACT_COVERAGE_FAILURE = 'FETCH_MONTHLY_CONTRACT_COVERAGE_FAILURE'

export const FETCH_MONTHLY_PRICES_REQUEST = 'FETCH_MONTHLY_PRICES_REQUEST'
export const FETCH_MONTHLY_PRICES_SUCCESS = 'FETCH_MONTHLY_PRICES_SUCCESS'
export const FETCH_MONTHLY_PRICES_FAILURE = 'FETCH_MONTHLY_PRICES_FAILURE'

export const FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_REQUEST =
  'FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_REQUEST'
export const FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_SUCCESS =
  'FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_SUCCESS'
export const FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_FAILURE =
  'FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_FAILURE'

export const FETCH_AVAILABLE_QUOTATIONS_REQUEST = 'FETCH_AVAILABLE_QUOTATIONS_REQUEST'
export const FETCH_AVAILABLE_QUOTATIONS_SUCCESS = 'FETCH_AVAILABLE_QUOTATIONS_SUCCESS'
export const FETCH_AVAILABLE_QUOTATIONS_FAILURE = 'FETCH_AVAILABLE_QUOTATIONS_FAILURE'

export const FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST = 'FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST'
export const FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS = 'FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS'
export const FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE = 'FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE'

export const FETCH_CONTRACTS_QUOTATIONS_REQUEST = 'FETCH_CONTRACTS_QUOTATIONS_REQUEST'
export const FETCH_CONTRACTS_QUOTATIONS_SUCCESS = 'FETCH_CONTRACTS_QUOTATIONS_SUCCESS'
export const FETCH_CONTRACTS_QUOTATIONS_FAILURE = 'FETCH_CONTRACTS_QUOTATIONS_FAILURE'

export const FETCH_MY_QUOTATIONS_REQUEST = 'FETCH_MY_QUOTATIONS_REQUEST'
export const FETCH_MY_QUOTATIONS_SUCCESS = 'FETCH_MY_QUOTATIONS_SUCCESS'
export const FETCH_MY_QUOTATIONS_FAILURE = 'FETCH_MY_QUOTATIONS_FAILURE'

export const ADD_MY_QUOTATIONS_REQUEST = 'ADD_MY_QUOTATIONS_REQUEST'
export const ADD_MY_QUOTATIONS_SUCCESS = 'ADD_MY_QUOTATIONS_SUCCESS'
export const ADD_MY_QUOTATIONS_FAILURE = 'ADD_MY_QUOTATIONS_FAILURE'

export const DELETE_MY_QUOTATION_REQUEST = 'DELETE_MY_QUOTATION_REQUEST'
export const DELETE_MY_QUOTATION_SUCCESS = 'DELETE_MY_QUOTATION_SUCCESS'
export const DELETE_MY_QUOTATION_FAILURE = 'DELETE_MY_QUOTATION_FAILURE'

export const FETCH_PARCEL_QUOTATIONS_REQUEST = 'FETCH_PARCEL_QUOTATIONS_REQUEST'
export const FETCH_PARCEL_QUOTATIONS_SUCCESS = 'FETCH_PARCEL_QUOTATIONS_SUCCESS'
export const FETCH_PARCEL_QUOTATIONS_FAILURE = 'FETCH_PARCEL_QUOTATIONS_FAILURE'

export const FETCH_LOT_QUOTATIONS_FOR_DEAL_REQUEST = 'FETCH_PARCEL_QUOTATIONS_FOR_DEAL_REQUEST'
export const FETCH_LOT_QUOTATIONS_FOR_DEAL_SUCCESS = 'FETCH_PARCEL_QUOTATIONS_FOR_DEAL_SUCCESS'
export const FETCH_LOT_QUOTATIONS_FOR_DEAL_FAILURE = 'FETCH_PARCEL_QUOTATIONS_FOR_DEAL_FAILURE'

export const FETCH_PARCEL_ALERTS_REQUEST = 'FETCH_PARCEL_ALERTS_REQUEST'
export const FETCH_PARCEL_ALERTS_SUCCESS = 'FETCH_PARCEL_ALERTS_SUCCESS'
export const FETCH_PARCEL_ALERTS_FAILURE = 'FETCH_PARCEL_ALERTS_FAILURE'

export const FETCH_PARCEL_QUOTATION_ALERTS_REQUEST = 'FETCH_PARCEL_QUOTATION_ALERTS_REQUEST'
export const FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS = 'FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS'
export const FETCH_PARCEL_QUOTATION_ALERTS_FAILURE = 'FETCH_PARCEL_QUOTATION_ALERTS_FAILURE'

export const DELETE_QUOTATIONS_REQUEST = 'DELETE_QUOTATIONS_REQUEST'
export const DELETE_QUOTATIONS_SUCCESS = 'DELETE_QUOTATIONS_SUCCESS'
export const DELETE_QUOTATIONS_FAILURE = 'DELETE_QUOTATIONS_FAILURE'

export const FETCH_CONTRACT_ALERTS_REQUEST = 'FETCH_CONTRACT_ALERTS_REQUEST'
export const FETCH_CONTRACT_ALERTS_SUCCESS = 'FETCH_CONTRACT_ALERTS_SUCCESS'
export const FETCH_CONTRACT_ALERTS_FAILURE = 'FETCH_CONTRACT_ALERTS_FAILURE'

export const FETCH_MULTIPLE_CONTRACT_ALERTS_REQUEST = 'FETCH_MULTIPLE_CONTRACT_ALERTS_REQUEST'
export const FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS = 'FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS'
export const FETCH_MULTIPLE_CONTRACT_ALERTS_FAILURE = 'FETCH_MULTIPLE_CONTRACT_ALERTS_FAILURE'

export const FETCH_ALERT_GRAPH_REQUEST = 'FETCH_ALERT_GRAPH_REQUEST'
export const FETCH_ALERT_GRAPH_SUCCESS = 'FETCH_ALERT_GRAPH_SUCCESS'
export const FETCH_ALERT_GRAPH_FAILURE = 'FETCH_ALERT_GRAPH_FAILURE'

export const FETCH_ALERTS_GRAPH_REQUEST = 'FETCH_ALERTS_GRAPH_REQUEST'
export const FETCH_ALERTS_GRAPH_SUCCESS = 'FETCH_ALERTS_GRAPH_SUCCESS'
export const FETCH_ALERTS_GRAPH_FAILURE = 'FETCH_ALERTS_GRAPH_FAILURE'

export const FETCH_ALERT_TYPES_REQUEST = 'FETCH_ALERT_TYPES_REQUEST'
export const FETCH_ALERT_TYPES_SUCCESS = 'FETCH_ALERT_TYPES_SUCCESS'
export const FETCH_ALERT_TYPES_FAILURE = 'FETCH_ALERT_TYPES_FAILURE'

export const FETCH_ALERT_REQUEST = 'FETCH_ALERT_REQUEST'
export const FETCH_ALERT_SUCCESS = 'FETCH_ALERT_SUCCESS'
export const FETCH_ALERT_FAILURE = 'FETCH_ALERT_FAILURE'

export const ADD_ALERT_REQUEST = 'ADD_ALERT_REQUEST'
export const ADD_ALERT_SUCCESS = 'ADD_ALERT_SUCCESS'
export const ADD_ALERT_FAILURE = 'ADD_ALERT_FAILURE'

export const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST'
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS'
export const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE'

export const ADD_QUOTATIONS_REQUEST = 'ADD_QUOTATIONS_REQUEST'
export const ADD_QUOTATIONS_SUCCESS = 'ADD_QUOTATIONS_SUCCESS'
export const ADD_QUOTATIONS_FAILURE = 'ADD_QUOTATIONS_FAILURE'

export const FETCH_LOT_QUOTATION_GRAPH_REQUEST = 'FETCH_LOT_QUOTATION_GRAPH_REQUEST'
export const FETCH_LOT_QUOTATION_GRAPH_FAILURE = 'FETCH_LOT_QUOTATION_GRAPH_FAILURE'
export const FETCH_LOT_QUOTATION_GRAPH_SUCCESS = 'FETCH_LOT_QUOTATION_GRAPH_SUCCESS'

export const SET_QUOTATION_GRAPH_STEP = 'SET_QUOTATION_GRAPH_STEP'
export const FETCH_QUOTATION_GRAPH_REQUEST = 'FETCH_QUOTATION_GRAPH_REQUEST'
export const FETCH_QUOTATION_GRAPH_FAILURE = 'FETCH_QUOTATION_GRAPH_FAILURE'
export const FETCH_QUOTATION_GRAPH_SUCCESS = 'FETCH_QUOTATION_GRAPH_SUCCESS'

export const UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST'
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS'
export const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE'

export const UPDATE_ALERT_TOGGLE_DAILY_REQUEST = 'UPDATE_ALERT_TOGGLE_DAILY_REQUEST'
export const UPDATE_ALERT_TOGGLE_DAILY_SUCCESS = 'UPDATE_ALERT_TOGGLE_DAILY_SUCCESS'
export const UPDATE_ALERT_TOGGLE_DAILY_FAILURE = 'UPDATE_ALERT_TOGGLE_DAILY_FAILURE'

export const UPDATE_ALERT_TOGGLE_MAIL_REQUEST = 'UPDATE_ALERT_TOGGLE_MAIL_REQUEST'
export const UPDATE_ALERT_TOGGLE_MAIL_SUCCESS = 'UPDATE_ALERT_TOGGLE_MAIL_SUCCESS'
export const UPDATE_ALERT_TOGGLE_MAIL_FAILURE = 'UPDATE_ALERT_TOGGLE_MAIL_FAILURE'

export const FETCH_CONTRACT_TRANSACTIONS_REQUEST = 'FETCH_CONTRACT_TRANSACTIONS_REQUEST'
export const FETCH_CONTRACT_TRANSACTIONS_SUCCESS = 'FETCH_CONTRACT_TRANSACTIONS_SUCCESS'
export const FETCH_CONTRACT_TRANSACTIONS_FAILURE = 'FETCH_CONTRACT_TRANSACTIONS_FAILURE'

export const FETCH_LOT_TRANSACTIONS_REQUEST = 'FETCH_LOT_TRANSACTIONS_REQUEST'
export const FETCH_LOT_TRANSACTIONS_SUCCESS = 'FETCH_LOT_TRANSACTIONS_SUCCESS'
export const FETCH_LOT_TRANSACTIONS_FAILURE = 'FETCH_LOT_TRANSACTIONS_FAILURE'

export const FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST = 'FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST'
export const FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS = 'FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS'
export const FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE = 'FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE'

export const FETCH_LOT_QUOTATION_TRANSACTIONS_REQUEST = 'FETCH_LOT_QUOTATION_TRANSACTIONS_REQUEST'
export const FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS = 'FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS'
export const FETCH_LOT_QUOTATION_TRANSACTIONS_FAILURE = 'FETCH_LOT_QUOTATION_TRANSACTIONS_FAILURE'

export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST'
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS'
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE'

export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST'
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS'
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE'

export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST'
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS'
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE'

export const ADD_CONTRACTS_TO_SELECTION = 'ADD_CONTRACTS_TO_SELECTION'
export const REMOVE_CONTRACTS_TO_SELECTION = 'REMOVE_CONTRACTS_TO_SELECTION'

export const EMPTY_SELECTION = 'EMPTY_SELECTION'

export const SET_CONTRACT_VISIBLE = 'SET_CONTRACT_VISIBLE'
export const SET_CONTRACT_INVISIBLE = 'SET_CONTRACT_INVISIBLE'
export const SET_UNIQUE_CONTRACT_VISIBLE = 'SET_UNIQUE_CONTRACT_VISIBLE'

export const SET_CONTRACTS_VISIBLE = 'SET_CONTRACTS_VISIBLE'
export const SET_CONTRACTS_INVISIBLE = 'SET_CONTRACTS_INVISIBLE'

export const RESET_CONTRACTS_PARCELS = 'RESET_CONTRACTS_PARCELS'

export const RESET_PARCEL_QUOTATIONS = 'RESET_PARCEL_QUOTATIONS'

export const RESET_LOT_QUOTATION_GRAPH = 'RESET_LOT_QUOTATION_GRAPH'

export const FETCH_QUOTATION_AND_PARCELS_REQUEST = 'FETCH_QUOTATION_AND_PARCELS_REQUEST'
export const FETCH_QUOTATION_AND_PARCELS_SUCCESS = 'FETCH_QUOTATION_AND_PARCELS_SUCCESS'
export const FETCH_QUOTATION_AND_PARCELS_FAILURE = 'FETCH_QUOTATION_AND_PARCELS_FAILURE'

export const FETCH_MONTHLY_COVERAGE_FOLLOWUP_REQUEST = 'FETCH_MONTHLY_COVERAGE_FOLLOWUP_REQUEST'
export const FETCH_MONTHLY_COVERAGE_FOLLOWUP_SUCCESS = 'FETCH_MONTHLY_COVERAGE_FOLLOWUP_SUCCESS'
export const FETCH_MONTHLY_COVERAGE_FOLLOWUP_FAILURE = 'FETCH_MONTHLY_COVERAGE_FOLLOWUP_FAILURE'

export const FETCH_LOT_QUOTATION_PERFORMANCE_REQUEST = 'FETCH_LOT_QUOTATION_PERFORMANCE_REQUEST'
export const FETCH_LOT_QUOTATION_PERFORMANCE_FAILURE = 'FETCH_LOT_QUOTATION_PERFORMANCE_FAILURE'
export const FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS = 'FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS'

export const FETCH_STRATEGY_FOLLOWUP_REQUEST = 'FETCH_STRATEGY_FOLLOWUP_REQUEST'
export const FETCH_STRATEGY_FOLLOWUP_SUCCESS = 'FETCH_STRATEGY_FOLLOWUP_SUCCESS'
export const FETCH_STRATEGY_FOLLOWUP_FAILURE = 'FETCH_STRATEGY_FOLLOWUP_FAILURE'

export const FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_REQUEST =
  'FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_REQUEST'
export const FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_SUCCESS =
  'FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_SUCCESS'
export const FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_FAILURE =
  'FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_FAILURE'

export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR'

export const FETCH_ALERTS_EVENTS_REQUEST = 'FETCH_ALERTS_EVENTS_REQUEST'
export const FETCH_ALERTS_EVENTS_SUCCESS = 'FETCH_ALERTS_EVENTS_SUCCESS'
export const FETCH_ALERTS_EVENTS_FAILURE = 'FETCH_ALERTS_EVENTS_FAILURE'

export const FETCH_PARCELS_NAMES_REQUEST = 'FETCH_PARCELS_NAMES_REQUEST'
export const FETCH_PARCELS_NAMES_FAILURE = 'FETCH_PARCELS_NAMES_FAILURE'
export const FETCH_PARCELS_NAMES_SUCCESS = 'FETCH_PARCELS_NAMES_SUCCESS'

// COTATIONS DMR
export const FETCH_AVAILABLE_DMR_QUOTATIONS_REQUEST = 'FETCH_AVAILABLE_DMR_QUOTATIONS_REQUEST'
export const FETCH_AVAILABLE_DMR_QUOTATIONS_SUCCESS = 'FETCH_AVAILABLE_DMR_QUOTATIONS_SUCCESS'
export const FETCH_AVAILABLE_DMR_QUOTATIONS_FAILURE = 'FETCH_AVAILABLE_DMR_QUOTATIONS_FAILURE'

export const FETCH_DMR_QUOTATIONS_REQUEST = 'FETCH_DMR_QUOTATIONS_REQUEST'
export const FETCH_DMR_QUOTATIONS_SUCCESS = 'FETCH_DMR_QUOTATIONS_SUCCESS'
export const FETCH_DMR_QUOTATIONS_FAILURE = 'FETCH_DMR_QUOTATIONS_FAILURE'

export const ADD_DMR_QUOTATIONS_REQUEST = 'ADD_DMR_QUOTATIONS_REQUEST'
export const ADD_DMR_QUOTATIONS_SUCCESS = 'ADD_DMR_QUOTATIONS_SUCCESS'
export const ADD_DMR_QUOTATIONS_FAILURE = 'ADD_DMR_QUOTATIONS_FAILURE'

export const TOGGLE_DMR_QUOTATION_REQUEST = 'TOGGLE_DMR_QUOTATION_REQUEST'
export const TOGGLE_DMR_QUOTATION_SUCCESS = 'TOGGLE_DMR_QUOTATION_SUCCESS'
export const TOGGLE_DMR_QUOTATION_FAILURE = 'TOGGLE_DMR_QUOTATION_FAILURE'

export const DELETE_DMR_QUOTATION_REQUEST = 'DELETE_DMR_QUOTATION_REQUEST'
export const DELETE_DMR_QUOTATION_SUCCESS = 'DELETE_DMR_QUOTATION_SUCCESS'
export const DELETE_DMR_QUOTATION_FAILURE = 'DELETE_DMR_QUOTATION_FAILURE'

export const TOGGLE_QUOTATION_BOLLINGER = 'TOGGLE_QUOTATION_BOLLINGER'
export const TOGGLE_QUOTATION_TUNNEL = 'TOGGLE_QUOTATION_TUNNEL'
