import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const i18n = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 2,
})

const StrategyContractBoard = ({ contracts, setUniqueContractVisible, t, configurationStatus }) => (
  <div className="strategy-contract-board">
    <section>
      <Table
        className="table table-striped table-bordered"
        sortable={['type', 'country', 'contract', 'startDate', 'endDate']}
      >
        <Thead>
          <Th column="type"> </Th>
          <Th column="country">{t('strategy.board.country')}</Th>
          <Th column="contract">{t('strategy.board.contract')}</Th>
          <Th column="startDate">{t('strategy.board.startDate')}</Th>
          <Th column="endDate">{t('strategy.board.endDate')}</Th>
          <Th column="hedgingProfile" className="text-center">
            {t('strategy.board.hedgingProfile')}
          </Th>
          <Th column="hedgingPlan" className="text-center">
            {t('strategy.board.hedgingPlan')}
          </Th>
          <Th column="alerts" className="text-center">
            {t('strategy.board.alerts')}
          </Th>
          <Th column="edit" className="text-center">
            <i className="icon-ico-menu-parameters" />
          </Th>
          <Th column="view" className="text-center">
            <i className="icon-collapse-plus" />
          </Th>
        </Thead>
        {contracts.map((i, index) => (
          <Tr key={index}>
            <Td column="type" value={i.CtrNrjId}>
              <i className={`${i.CtrNrjId === 2 ? 'icon-elec' : 'icon-gaz'} left-pad`}></i>
            </Td>
            <Td column="country">{i.CtrLibellePays}</Td>
            <Td column="contract">{i.CtrNomCourt}</Td>
            <Td column="startDate">{i.CtrDateDebut}</Td>
            <Td column="endDate">{i.CtrDateFin}</Td>
            <Td column="hedgingProfile" className="text-center">
              {configurationStatus &&
                configurationStatus[i.id] &&
                i18n.format(configurationStatus[i.id].EtatProfil) + '%'}
            </Td>
            <Td column="hedgingPlan" className="text-center">
              {configurationStatus &&
                configurationStatus[i.id] &&
                i18n.format(configurationStatus[i.id].EtatPlan) + '%'}
            </Td>
            <Td column="alerts" className="text-center">
              {configurationStatus &&
                configurationStatus[i.id] &&
                configurationStatus[i.id].NbAlertes}
            </Td>
            <Td column="edit" className="text-center">
              <Link to={'/admin/' + i.id}>
                <Button
                  className="btn-table active"
                  onClick={() => {
                    setUniqueContractVisible(i)
                  }}
                >
                  <i className="icon-ico-menu-parameters" />
                </Button>
              </Link>
            </Td>
            <Td column="view" className="text-center">
              <Link to={'/transactions/' + i.id}>
                <Button
                  className="btn-table active"
                  onClick={() => {
                    setUniqueContractVisible(i, true)
                  }}
                >
                  <i className="icon-collapse-plus" />
                </Button>
              </Link>
            </Td>
          </Tr>
        ))}
      </Table>
    </section>
  </div>
)

StrategyContractBoard.propTypes = {
  contracts: PropTypes.array.isRequired,
  configurationStatus: PropTypes.object.isRequired,
  setUniqueContractVisible: PropTypes.func.isRequired,
}

export default withTranslation()(StrategyContractBoard)
