import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

import {
  FETCH_CONTRACTS_DETAIL_REQUEST,
  FETCH_CONTRACTS_DETAIL_SUCCESS,
  FETCH_CONTRACTS_DETAIL_FAILURE,
  FETCH_CONTRACT_COVERAGE_REQUEST,
  FETCH_CONTRACT_COVERAGE_SUCCESS,
  FETCH_CONTRACT_COVERAGE_FAILURE,
  FETCH_MONTHLY_CONTRACT_COVERAGE_REQUEST,
  FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS,
  FETCH_MONTHLY_CONTRACT_COVERAGE_FAILURE,
  FETCH_MONTHLY_PRICES_REQUEST,
  FETCH_MONTHLY_PRICES_SUCCESS,
  FETCH_MONTHLY_PRICES_FAILURE,
  FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_REQUEST,
  FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_SUCCESS,
  FETCH_CONTRACT_COVERAGE_RECAP_REQUEST,
  FETCH_CONTRACT_COVERAGE_RECAP_SUCCESS,
  FETCH_CONTRACT_COVERAGE_RECAP_FAILURE,
  FETCH_CONTRACT_CONFIG_STATUS_SUCCESS,
  FETCH_CONTRACT_CONFIG_STATUS_FAILURE,
  FETCH_CONTRACT_CONFIG_STATUS_REQUEST,
  FETCH_MONTHLY_COVERAGE_FOLLOWUP_REQUEST,
  FETCH_MONTHLY_COVERAGE_FOLLOWUP_SUCCESS,
  FETCH_MONTHLY_COVERAGE_FOLLOWUP_FAILURE,
  FETCH_STRATEGY_FOLLOWUP_REQUEST,
  FETCH_STRATEGY_FOLLOWUP_SUCCESS,
  FETCH_STRATEGY_FOLLOWUP_FAILURE,
} from './'
import {
  fetchContractsDetail as apiFetchContractsDetail,
  fetchContractCoverage as apiFetchContractCoverage,
  fetchMonthlyContractCoverage as apiFetchMonthlyContractCoverage,
  fetchMonthlyPrices as apiFetchMonthlyPrices,
  fetchContractCoverageRecap as apiFetchContractCoverageRecap,
  fetchStrategyFollowup as apiFetchStrategyFollowup,
  fetchContractConfigStatus as apiFetchContractConfigStatus,
  fetchMonthlyCoverageFollowup as apiFetchMonthlyCoverageFollowup,
} from '../api/contracts'

export const fetchContractConfigStatus = (contractIds) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_CONFIG_STATUS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_CONTRACT_CONFIG_STATUS_SUCCESS,
      contractIds,
      status: await apiFetchContractConfigStatus(contractIds),
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_CONFIG_STATUS_FAILURE,
      contractIds: contractIds,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchContractsDetail = (contractIds) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACTS_DETAIL_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_CONTRACTS_DETAIL_SUCCESS,
      contractIds,
      details: await apiFetchContractsDetail(contractIds),
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACTS_DETAIL_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchContractCoverage = (contractIds, startDate, endDate) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_COVERAGE_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_CONTRACT_COVERAGE_SUCCESS,
      contractIds,
      contractCoverage: await apiFetchContractCoverage(contractIds, startDate, endDate),
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_COVERAGE_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchMonthlyContractCoverage = (contractIds, startDate, endDate) => async (
  dispatch,
) => {
  dispatch({
    type: FETCH_MONTHLY_CONTRACT_COVERAGE_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_MONTHLY_CONTRACT_COVERAGE_SUCCESS,
      contractIds,
      monthlyContractCoverage: await apiFetchMonthlyContractCoverage(
        contractIds,
        startDate,
        endDate,
      ),
    })
  } catch (e) {
    dispatch({
      type: FETCH_MONTHLY_CONTRACT_COVERAGE_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchMonthlyPrices = (contractIds, startDate, endDate) => async (dispatch) => {
  dispatch({
    type: FETCH_MONTHLY_PRICES_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_MONTHLY_PRICES_SUCCESS,
      contractIds,
      monthlyPrices: await apiFetchMonthlyPrices(contractIds, startDate, endDate),
    })
  } catch (e) {
    dispatch({
      type: FETCH_MONTHLY_PRICES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchMonthlyContractCoveragePerCountry = (
  contractObjects,
  startDate,
  endDate,
) => async (dispatch) => {
  dispatch({
    type: FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_REQUEST,
  })

  const contractIdsByCountry = mapValues(groupBy(contractObjects, 'CtrIdPays'), (ids) =>
    groupBy(ids, 'CtrNrjId'),
  )

  const coveragePerCountry = {}
  for (const country in contractIdsByCountry) {
    for (const nrjId in contractIdsByCountry[country]) {
      const contractIds = contractIdsByCountry[country][nrjId].map((c) => c.id)

      let error = null
      let data = null

      try {
        data = await apiFetchMonthlyContractCoverage(contractIds, startDate, endDate)
      } catch (e) {
        error = e.message
      }

      coveragePerCountry[country] = {
        ...coveragePerCountry[country],
        [nrjId]: { data, error },
      }
    }
  }

  dispatch({
    type: FETCH_MONTHLY_CONTRACT_COVERAGE_PER_COUNTRY_SUCCESS,
    coveragePerCountry,
  })
}

export const fetchContractCoverageRecap = (contractIds, startDate, endDate) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_COVERAGE_RECAP_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_CONTRACT_COVERAGE_RECAP_SUCCESS,
      contractIds,
      contractCoverageRecap: await apiFetchContractCoverageRecap(contractIds, startDate, endDate),
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_COVERAGE_RECAP_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchStrategyFollowup = (contractIds, startDate, endDate, lotId) => async (
  dispatch,
) => {
  dispatch({
    type: FETCH_STRATEGY_FOLLOWUP_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_STRATEGY_FOLLOWUP_SUCCESS,
      contractIds,
      strategyFollowup: await apiFetchStrategyFollowup(contractIds, startDate, endDate, lotId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_STRATEGY_FOLLOWUP_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchMonthlyCoverageFollowup = (contractIds, startDate, endDate) => async (
  dispatch,
) => {
  dispatch({
    type: FETCH_MONTHLY_COVERAGE_FOLLOWUP_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_MONTHLY_COVERAGE_FOLLOWUP_SUCCESS,
      contractIds,
      monthlyConverageFollowup: await apiFetchMonthlyCoverageFollowup(
        contractIds,
        startDate,
        endDate,
      ),
    })
  } catch (e) {
    dispatch({
      type: FETCH_MONTHLY_COVERAGE_FOLLOWUP_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}
