import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button, ButtonGroup } from 'reactstrap'

// Actions
import { emptySelection, selectAll } from '../../actions/contractSelection'

const SelectButtons = ({ t, emptySelection, selectAll, contracts, searchFilters }) => (
  <ButtonGroup className="select-buttons">
    <Button
      color="link"
      onClick={(e) => {
        e.preventDefault()
        selectAll(contracts, searchFilters)
      }}
    >
      {t('sidebar.panel.selectAll')}
    </Button>
    <Button
      color="link"
      onClick={(e) => {
        e.preventDefault()
        emptySelection(contracts, searchFilters)
      }}
    >
      {t('sidebar.panel.unselectAll')}
    </Button>
  </ButtonGroup>
)

const mapStateToProps = (state) => ({
  searchFilters: state.searchFilters,
  contracts: state.contracts.contractList,
})

const mapDispatchToProps = (dispatch) => ({
  emptySelection(contracts, searchFilters) {
    dispatch(emptySelection(contracts, searchFilters))
  },
  selectAll(contracts, searchFilters) {
    dispatch(selectAll(contracts, searchFilters))
  },
})

SelectButtons.propTypes = {
  contracts: PropTypes.array.isRequired,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SelectButtons))
