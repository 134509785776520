import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { withTranslation } from 'react-i18next'

import Select from 'components/common/Select'
import SelectButtons from './SelectButtons'

import { fetchFilters, addContracts, removeContracts } from 'actions/contractSelection'
import { setFilters } from 'store/searchFilters'

class SearchFilters extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchFilters()
  }

  toggleValueOfType = (type, value, shouldAdd) => () => {
    let filtered
    if (shouldAdd) {
      filtered = [...this.props.searchFilters[type]]
      filtered.push(value)
    } else {
      filtered = this.props.searchFilters[type].filter((f) => f !== value)
    }
    const newFilter = { ...this.props.searchFilters }
    newFilter[type] = filtered
    this.props.setFilters(newFilter)
  }

  changeProviders = (e) => {
    this.props.setFilters({
      ...this.props.searchFilters,
      providers: e === null ? [] : e.map((i) => i.value),
    })
  }

  changeCountries = (e) => {
    this.props.setFilters({
      ...this.props.searchFilters,
      countries: e === null ? [] : e.map((i) => i.value),
    })
  }

  changeStartYear = (e) => {
    const value = e ? e.value : null
    this.props.setFilters({ ...this.props.searchFilters, startYear: value })
  }

  changeEndYear = (e) => {
    const value = e ? e.value : null
    this.props.setFilters({ ...this.props.searchFilters, endYear: value })
  }

  toggleContracts = (counterIds, shouldAdd) => () => {
    if (shouldAdd) {
      this.props.addContracts(counterIds)
    } else {
      this.props.removeContracts(counterIds)
    }
  }

  render() {
    const currentYear = new Date().getFullYear()
    const availableYears = []
    for (let i = currentYear - 5; i < currentYear + 5; i++) {
      availableYears.push({ value: i, label: i })
    }
    return (
      <Form>
        <Row className="filter-section">
          <Col>
            <Row>
              <Col>
                <Label className="filter-title">
                  {this.props.t('sidebar.searchFilters.energyFilter')}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <Label
                  className={`form-check-label${
                    this.props.searchFilters.energyType.includes(1) ? ' checked' : ''
                  }`}
                >
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    onChange={this.toggleValueOfType(
                      'energyType',
                      1,
                      !this.props.searchFilters.energyType.includes(1),
                    )}
                  />
                  {this.props.t('sidebar.searchFilters.gaz')}
                </Label>
              </Col>
              <Col xs="3">
                <Label
                  className={`form-check-label${
                    this.props.searchFilters.energyType.includes(2) ? ' checked' : ''
                  }`}
                >
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    onChange={this.toggleValueOfType(
                      'energyType',
                      2,
                      !this.props.searchFilters.energyType.includes(2),
                    )}
                  />
                  {this.props.t('sidebar.searchFilters.electricity')}
                </Label>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="filter-section">
          <Col xs="5">
            <Row>
              <Col>
                <Label className="filter-title" for="searchCountry">
                  {this.props.t('sidebar.searchFilters.country')}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  isMulti
                  value={this.props.countriesForSelect.filter((option) =>
                    this.props.searchFilters.countries.includes(option.value),
                  )}
                  placeholder={this.props.t('sidebar.searchFilters.countryFilter')}
                  options={this.props.countriesForSelect}
                  id="valuePays"
                  onChange={this.changeCountries}
                  isClearable={false}
                  width="100%"
                  bordered
                />
              </Col>
            </Row>
          </Col>
          <Col xs="7">
            <Row>
              <Col>
                <Label className="filter-title" for="searchFournisseur">
                  {this.props.t('sidebar.searchFilters.providers')}
                </Label>
              </Col>
            </Row>
            <Row className="filter-section">
              <Col>
                <Select
                  isMulti
                  value={this.props.providersForSelect.filter((option) =>
                    this.props.searchFilters.providers.includes(option.value),
                  )}
                  placeholder={this.props.t('sidebar.searchFilters.providerFilter')}
                  options={this.props.providersForSelect}
                  id="valueFournisseur"
                  onChange={this.changeProviders}
                  isClearable={false}
                  width="100%"
                  bordered
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="filter-section">
          <Col>
            <Row>
              <Col>
                <Label className="filter-title">
                  {this.props.t('sidebar.searchFilters.timeframe')}
                </Label>
              </Col>
            </Row>
            <Row className="date-range">
              <Col className="date-range-wrapper">
                <div className="date-range-global">
                  <FormGroup>
                    <Select
                      value={availableYears.find(
                        (option) => option.value === this.props.searchFilters.startYear,
                      )}
                      options={availableYears}
                      onChange={this.changeStartYear}
                      width={100}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Select
                      value={availableYears.find(
                        (option) => option.value === this.props.searchFilters.endYear,
                      )}
                      options={availableYears}
                      onChange={this.changeEndYear}
                      width={100}
                    />
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <SelectButtons />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="contract-section">
          <Col>
            <ul className="form-check-list">
              {this.props.countries.map((c) =>
                this.props.contractsByCountryCode[c.code] ? (
                  <li key={c.code}>
                    <Label
                      className={`form-check-label
                    ${this.props.countryStatus[c.code] === 1 ? ' indeterminate' : ''}
                    ${this.props.countryStatus[c.code] === 2 ? ' checked' : ''}`}
                    >
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        onChange={this.toggleContracts(
                          this.props.contractsByCountryCode[c.code].map((c) => c.id),
                          this.props.countryStatus[c.code] !== 2,
                        )}
                        checked={this.props.countryStatus[c.code] === 2}
                      />
                      {c.libelle}
                    </Label>
                    <ul>
                      {this.props.contractsByCountryCode[c.code].map((ctr) => {
                        const checked = this.props.selectedContracts.includes(ctr.id)
                        return (
                          <li key={ctr.id}>
                            <Label className={`form-check-label${checked ? ' checked' : ''}`}>
                              <Input
                                type="checkbox"
                                onChange={this.toggleContracts([ctr.id], !checked)}
                                className="form-check-input"
                              />
                              <i
                                className={`${
                                  ctr.CtrNrjId === 2 ? 'icon-elec' : 'icon-gaz'
                                } left-pad`}
                              ></i>
                              {ctr.CtrNomCourt}
                            </Label>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                ) : null,
              )}
            </ul>
          </Col>
        </Row>
      </Form>
    )
  }
}

SearchFilters.propTypes = {
  contracts: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  countriesForSelect: PropTypes.array.isRequired,
  providersForSelect: PropTypes.array.isRequired,
  fetchFilters: PropTypes.func.isRequired,
  addContracts: PropTypes.func.isRequired,
  removeContracts: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  countries: state.countries,
  providers: state.providers,
  contracts: state.contracts.contractList,
  selectedContracts: state.contracts.contractSelection,
  searchFilters: state.searchFilters,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFilters: () => {
    dispatch(fetchFilters())
  },
  addContracts: (contracts) => {
    dispatch(addContracts(contracts))
  },
  removeContracts: (contracts) => {
    dispatch(removeContracts(contracts))
  },
  setFilters: (filter) => {
    dispatch(setFilters(filter))
  },
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ countries, providers, contracts, selectedContracts, searchFilters }) => ({
    countriesForSelect: countries.map((c) => ({
      value: c.id,
      label: c.libelle,
    })),
    providersForSelect: providers.map((c) => ({
      value: c.id,
      label: c.libelle,
    })),
    // Return a map of by country code
    contractsByCountryCode: contracts.reduce((rv, x) => {
      if (
        searchFilters.energyType.includes(x.CtrNrjId) &&
        (searchFilters.providers.length === 0 ||
          searchFilters.providers.includes(x.CtrIdFournisseur)) &&
        (searchFilters.countries.length === 0 || searchFilters.countries.includes(x.CtrIdPays)) &&
        (!searchFilters.startYear || x.CtrDateFin.split('/')[2] >= searchFilters.startYear) &&
        (!searchFilters.endYear || x.CtrDateDebut.split('/')[2] <= searchFilters.endYear)
      ) {
        ;(rv[x['CtrCodePays']] = rv[x['CtrCodePays']] || []).push(x)
      }
      return rv
    }, {}),
    // Status of a country. 0 for no contract selected, 1 for some, 2 for all
    countryStatus: contracts.reduce((rv, x) => {
      let newValue = 1
      switch (rv[x['CtrCodePays']]) {
        case undefined:
          newValue = selectedContracts.includes(x.id) ? 2 : 0
          break
        case 0:
          newValue = selectedContracts.includes(x.id) ? 1 : 0
          break
        case 2:
          newValue = selectedContracts.includes(x.id) ? 2 : 1
          break
        default:
          break
      }
      rv[x['CtrCodePays']] = newValue
      return rv
    }, {}),
  })),
)(SearchFilters)
