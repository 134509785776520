/** @jsxImportSource @emotion/react */
import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import DateTime from 'react-datetime'
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'

import * as user from 'commons'
import { dateFormat } from 'helpers/formater'

const RenderInput = (props, openCalendar) => (
  <InputGroup
    size="sm"
    css={{
      width: '120px !important',
      margin: '0 8px',
    }}
  >
    <Input {...props} />
    <InputGroupAddon addonType="append">
      <Button
        css={(theme) => ({
          lineHeight: 0,
          background: theme.gray400,
          borderColor: theme.gray400,
          color: theme.gray700,
          boxShadow: 'none',
        })}
        onClick={openCalendar}
      >
        <i className="icon-event" />
      </Button>
    </InputGroupAddon>
  </InputGroup>
)

const InputDatepicker = ({ locale, value, onDateChange }) => (
  <DateTime
    className="input-datepicker"
    renderInput={RenderInput}
    locale={locale}
    closeOnSelect
    dateFormat={dateFormat()}
    timeFormat={false}
    value={value}
    onChange={onDateChange}
  />
)

const mapStateToProps = (state) => ({
  lng: user.lngIso(state),
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onDateChange: (props) => (date) => {
      if (typeof date !== 'string') {
        props.onChange(date)
      }
    },
  }),
)(InputDatepicker)
