/** @jsxImportSource @emotion/react */
import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'

export const HedgingProfileTarget = ({ disabled, onChange, checked, label }) => {
  return (
    <FormGroup
      check
      className={`${checked ? 'checked' : ''} ${disabled ? ' disabled ' : ''}`}
      css={{
        opacity: disabled ? 0.5 : 1,
        lineHeight: 1.1,
        height: '100%',
        position: 'relative',
        '.form-check-label': {
          height: '100%',
          fontSize: '13.5px !important',
          padding: '0 !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Label check className={checked ? 'checked' : ''}>
        <Input type="checkbox" name="radio1" disabled={disabled} onChange={onChange} />
        <span css={{ verticalAlign: 'middle' }}>{label}</span>
      </Label>
    </FormGroup>
  )
}
