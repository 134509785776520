/** @jsxImportSource @emotion/react */
import React from 'react'
import { Link } from 'react-router-dom'
import logoVinci from 'images/eflex-rvb.svg'

import { ReactComponent as Logo } from 'images/logo-eleneo.svg'

export const DefaultLogo = () => {
  return (
    <div
      css={(theme) => ({
        fontFamily: 'Merlo, Muli, sans-serif',
        color: '#e9ecef', //theme.gray200,

        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '>div': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        },

        a: {
          color: '#e9ecef', //theme.gray200,
          '&:hover': {
            textDecoration: 'none',
            color: '#fff',
          },
        },

        '.logo-eflex': {
          fontSize: '3.8rem',
          lineHeight: '1.2',
        },

        '.logo-eleneo': {
          fontSize: 10,
          svg: {
            width: 'calc(1.2rem * 3.6)',
            height: '1.2rem',
            fill: '#FFFFFF', // theme.gray600
            marginBottom: 3,
            verticalAlign: 'bottom',
            marginLeft: '.5rem',
            marginRight: '.5rem',
          },
        },
      })}
    >
      <div>
        <Link to="/" className="logo-eflex">
          <svg fill='white' width="120pt" height="50pt" viewBox="12 0 450 150">
            <g id="surface1">
              <path d="M 104.667969 110.96875 C 95.050781 123.195312 81.785156 130.5625 67.359375 130.730469 C 39.335938 130.730469 23.085938 109.460938 23.085938 88.027344 C 23.085938 66.589844 39.335938 45.324219 67.359375 45.324219 C 84.105469 45.324219 108.976562 59.222656 108.976562 90.875 L 108.976562 94.054688 L 42.320312 94.054688 C 43.316406 106.449219 54.757812 115.324219 66.859375 115.324219 C 76.148438 115.324219 84.105469 110.800781 90.074219 102.761719 Z M 88.75 79.652344 C 85.101562 67.261719 75.980469 60.730469 65.039062 60.730469 C 54.09375 60.730469 45.136719 67.59375 42.320312 79.652344 Z M 88.75 79.652344 " />
              <path d="M 130.207031 83.839844 L 117.769531 83.839844 L 117.769531 65.417969 L 130.207031 65.417969 L 130.207031 23.386719 L 210.128906 23.386719 C 210.128906 33.558594 201.964844 41.804688 191.890625 41.804688 L 149.441406 41.804688 L 149.441406 65.417969 L 191.722656 65.417969 C 191.722656 75.59375 183.558594 83.839844 173.484375 83.839844 L 149.441406 83.839844 L 149.441406 111.402344 C 149.441406 122.132812 140.828125 130.828125 130.207031 130.828125 Z M 130.207031 83.839844 " />
              <path d="M 236 23.386719 L 236 95.898438 C 236 105.277344 237.992188 108.625 243.132812 111.640625 L 252.417969 116.996094 L 244.789062 130.730469 C 227.046875 122.1875 218.757812 115.992188 218.757812 99.078125 L 218.757812 23.386719 Z M 236 23.386719 " />
              <path d="M 338.476562 110.96875 C 328.859375 123.195312 315.59375 130.5625 301.167969 130.730469 C 273.148438 130.730469 256.894531 109.460938 256.894531 88.027344 C 256.894531 66.589844 273.148438 45.324219 301.167969 45.324219 C 317.917969 45.324219 342.789062 59.222656 342.789062 90.875 L 342.789062 94.054688 L 276.132812 94.054688 C 277.125 106.449219 288.566406 115.324219 300.671875 115.324219 C 309.957031 115.324219 317.917969 110.800781 323.886719 102.761719 Z M 322.558594 79.652344 C 318.910156 67.261719 309.789062 60.730469 298.847656 60.730469 C 287.902344 60.730469 278.949219 67.59375 276.132812 79.652344 Z M 322.558594 79.652344 " />
              <path d="M 379.769531 88.863281 L 349.265625 45.335938 L 370.824219 45.335938 L 389.714844 75.300781 L 408.320312 45.335938 L 428.550781 45.335938 L 398.171875 87.859375 L 428.550781 130.828125 L 406.664062 130.828125 L 388.722656 101.421875 L 369.664062 130.828125 L 349.265625 130.828125 Z M 379.769531 88.863281 " />
            </g>
          </svg>
        </Link>
        <div className="logo-eleneo">
          by
          <a href="https://www.eleneo.fr">
            <Logo />
          </a>
        </div>
      </div>
    </div>
  )
}
