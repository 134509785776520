import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const svgSizeX = 1316
const svgSizeY = 460

const graphSizeMinX = 100
const graphSizeMaxX = 1300
const graphSizeMinY = 20
const graphSizeMaxY = 420
const graphWidth = 1200
const graphHeight = 400
const yUnit = graphHeight / 100

const noop = () => { }

export class HedgingPlanGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSegmentNode: null,
      selectedSegmentNodeIsMax: false,
      selectedSegmentNodeDraggedValue: 0,
      dragStartingPosition: 0,
      dragAllowedMinMin: 0,
      dragAllowedMinMax: 0,
      dragAllowedMaxMin: 100,
      dragAllowedMaxMax: 100,
      segmentToBeAdded: null,
    }
  }

  componentWillUnmount() {
    if (this.props.readOnly) return
    document.removeEventListener('mousemove', this.onSegmentDrag)
    document.removeEventListener('mouseup', this.onSegmentDragEnd)
  }

  findClosestSegments = (refMonth, segmentList) => {
    const res = {
      closestPreviousSegment: null,
      closestNextSegment: null,
    }
    segmentList.forEach((segment) => {
      if (segment.moisRelatif < refMonth) {
        res.closestPreviousSegment =
          !res.closestPreviousSegment ||
            res.closestPreviousSegment.moisRelatif < segment.moisRelatif
            ? segment
            : res.closestPreviousSegment
      } else if (segment.moisRelatif > refMonth) {
        res.closestNextSegment =
          !res.closestNextSegment || res.closestNextSegment.moisRelatif > segment.moisRelatif
            ? segment
            : res.closestNextSegment
      }
    })

    return res
  }

  drawVerticalScale = () => {
    const divs = []
    const divisionNum = 10
    for (let i = 1; i <= divisionNum; i++) {
      const y = graphSizeMaxY - i * yUnit * 10
      divs.push(
        <text
          key={`t-${i}`}
          x="70"
          y={y - divisionNum / 2}
          dy=".35em"
          dx={`${i * divisionNum < 100 ? 0 : -0.35}em`}
          fill={'#A3A8B5'}
        >
          {i * divisionNum}
        </text>,
      )
      divs.push(
        <line
          key={`l-${i}`}
          x1={graphSizeMinX}
          y1={y}
          x2={svgSizeX}
          y2={y}
          style={{ stroke: '#D8DADF' }}
        />,
      )
    }
    divs.push(
      <line
        key={`l-${0}`}
        x1="1302"
        y1={graphSizeMaxY}
        x2={svgSizeX}
        y2={graphSizeMaxY}
        style={{ stroke: '#D8DADF' }}
      />,
    )
    divs.push(
      <line
        key={`end-line`}
        x1={graphSizeMaxX + 2}
        y1={graphSizeMinY}
        x2={graphSizeMaxX + 2}
        y2={graphSizeMaxY}
        style={{ stroke: 'crimson' }}
      />,
    )
    divs.push(
      <text
        key={`end-text`}
        x="0"
        y="0"
        textAnchor="middle"
        dy=".35em"
        fill={'crimson'}
        transform={`rotate(90) translate(${graphSizeMaxY / 2},-${graphSizeMaxX + 8})`}
      >
        {this.props.t('strategy.graph.executionStartingPeriod')}
      </text>,
    )
    return <g>{divs}</g>
  }

  drawHorizontalScale = () => {
    const scaleDraw = []
    for (let i = 0; i < this.props.length; i++) {
      const xStart = graphSizeMinX + (graphWidth / this.props.length) * i
      const xMid = graphSizeMinX + (graphWidth / this.props.length) * (i + 0.5)
      const xEnd = graphSizeMinX + (graphWidth / this.props.length) * (i + 1)

      // Monthly text
      scaleDraw.push(
        <text
          key={`key-${i}`}
          x={xMid}
          y="440"
          dx={`${this.props.length - i < 10 ? -1.05 : -1.35}em`}
          dy=".35em"
          fill={'#A3A8B5'}
        >
          {`M -${this.props.length - i}`}
        </text>,
      )

      // Monthly line
      scaleDraw.push(
        <line
          key={`mo-${i}`}
          x1={xStart + 2}
          y1={graphSizeMaxY}
          x2={xEnd - 2}
          y2={graphSizeMaxY}
          className="hedging-plan-line"
        />,
      )
      // Monthly line limits (small bump on each side of the segment)
      scaleDraw.push(
        <line
          key={`mo-left-${i}`}
          x1={xStart + 2}
          y1={graphSizeMaxY}
          x2={xStart + 2}
          y2={417}
          className="hedging-plan-line"
        />,
      )
      scaleDraw.push(
        <line
          key={`mo-right-${i}`}
          x1={xEnd - 2}
          y1={graphSizeMaxY}
          x2={xEnd - 2}
          y2={417}
          className="hedging-plan-line"
        />,
      )
    }

    return <g className="horizontal-scale">{scaleDraw}</g>
  }

  drawCircleIndicators = () => {
    const circleIndicators = []
    const len = this.props.length + 1
    for (let i = 0; i < len; i++) {
      const xEnd = graphSizeMinX + (graphWidth / this.props.length) * i
      const circles = []
      const moisRelatif = -(this.props.length - i)
      const correspondingSegmentIndex = this.props.segments.findIndex(
        (s) => s.moisRelatif === moisRelatif,
      )
      if (correspondingSegmentIndex === -1) {
        const { closestPreviousSegment, closestNextSegment } = this.findClosestSegments(
          moisRelatif,
          this.props.segments,
        )
        const areCirclesOfSegmentToBeAdded =
          this.state.segmentToBeAdded && this.state.segmentToBeAdded.moisRelatif === moisRelatif
        let min = closestPreviousSegment ? closestPreviousSegment.min : 0
        let max = closestNextSegment ? closestNextSegment.max : 100

        min =
          areCirclesOfSegmentToBeAdded &&
            closestPreviousSegment &&
            closestPreviousSegment.max > this.state.segmentToBeAdded.value
            ? closestPreviousSegment.max
            : min
        max =
          areCirclesOfSegmentToBeAdded &&
            closestNextSegment &&
            closestNextSegment.min < this.state.segmentToBeAdded.value
            ? closestNextSegment.min
            : max

        const hasAtLeastTwoPoints = max - Math.ceil(min / 10) * 10 > 10

        if (areCirclesOfSegmentToBeAdded) {
          // show the point that has already been selected
          circles.push(
            <circle
              cx={xEnd}
              cy={graphSizeMaxY - this.state.segmentToBeAdded.value * yUnit}
              key={'circle-selected'}
              r="8"
              className={'hedging-plan-actual-inflexion selected'}
            />,
          )
        }

        for (let j = Math.ceil(min / 10) * 10; j <= max; j += 10) {
          if (
            !(
              closestNextSegment &&
              closestPreviousSegment &&
              closestNextSegment.min < j &&
              closestPreviousSegment.max > j
            )
          ) {
            const segment =
              areCirclesOfSegmentToBeAdded || !hasAtLeastTwoPoints
                ? {
                  min: Math.min(
                    j,
                    this.state.segmentToBeAdded ? this.state.segmentToBeAdded.value : 100,
                  ),
                  max: Math.max(
                    j,
                    this.state.segmentToBeAdded ? this.state.segmentToBeAdded.value : 0,
                  ),
                  moisRelatif,
                }
                : {
                  value: j,
                  moisRelatif,
                }

            circles.push(
              <circle
                cx={xEnd}
                cy={graphSizeMaxY - j * yUnit}
                key={`circle-${i}-${j}`}
                r="8"
                className={`hedging-plan-actual-inflexion${areCirclesOfSegmentToBeAdded && this.state.segmentToBeAdded.value === j
                  ? ' selected'
                  : ''
                  }`}
                onClick={
                  this.props.readOnly
                    ? noop
                    : areCirclesOfSegmentToBeAdded || !hasAtLeastTwoPoints
                      ? this.addSegment(segment)
                      : this.initAddSegmentProcess(segment)
                }
              />,
            )
          }
        }
        circleIndicators.push(
          <g
            className={`column-indicators ${areCirclesOfSegmentToBeAdded ? ' add-segment' : ''}`}
            key={`column-indicator-${i}`}
          >
            <rect
              key={`invisible-selection-line-${i}`}
              width={48}
              height={graphSizeMaxY - graphSizeMinY}
              x={xEnd - 24}
              y={graphSizeMinY}
              className="invisible-selection-line"
            />
            {circles}
          </g>,
        )
      }
    }

    return <g className="circle-indicators">{circleIndicators}</g>
  }

  initAddSegmentProcess = (segmentToBeAdded) => () => {
    this.setState({ segmentToBeAdded })
  }

  addSegment = (segment) => () => {
    this.setState({ segmentToBeAdded: null })
    this.props.setSegment(segment)
  }

  onSegmentDragStart = (segment, selectedSegmentNodeIsMax) => (e) => {
    const { closestPreviousSegment, closestNextSegment } = this.findClosestSegments(
      segment.moisRelatif,
      this.props.segments,
    )
    this.setState({
      selectedSegmentNode: segment,
      selectedSegmentNodeIsMax,
      selectedSegmentNodeDraggedValue: selectedSegmentNodeIsMax ? segment.max : segment.min,
      dragStartingPosition: e.pageY,
      dragAllowedMinMin: closestPreviousSegment ? closestPreviousSegment.min : 0,
      dragAllowedMinMax: closestNextSegment ? closestNextSegment.min : 100,
      dragAllowedMaxMin: closestPreviousSegment ? closestPreviousSegment.max : 0,
      dragAllowedMaxMax: closestNextSegment ? closestNextSegment.max : 100,
    })
    document.addEventListener('mousemove', this.onSegmentDrag)
    document.addEventListener('mouseup', this.onSegmentDragEnd)
  }

  onSegmentDrag = (e) => {
    if (this.state.selectedSegmentNode) {
      const delta = this.state.dragStartingPosition - e.pageY // positive <=> raising value of node
      let newValue
      if (this.state.selectedSegmentNodeIsMax) {
        newValue = Math.round(
          Math.min(
            Math.max(
              this.state.selectedSegmentNode.max + delta / yUnit,
              this.state.selectedSegmentNode.min,
              this.state.dragAllowedMaxMin,
            ),
            this.state.dragAllowedMaxMax,
          ),
        )
      } else {
        newValue = Math.round(
          Math.max(
            Math.min(
              this.state.selectedSegmentNode.min + delta / yUnit,
              this.state.selectedSegmentNode.max,
              this.state.dragAllowedMinMax,
            ),
            this.state.dragAllowedMinMin,
          ),
        )
      }
      this.setState({
        selectedSegmentNodeDraggedValue: newValue,
      })
    }
  }

  onSegmentDragEnd = () => {
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.onSegmentDragEnd)

    const newSegment = { ...this.state.selectedSegmentNode }
    if (this.state.selectedSegmentNodeIsMax) {
      newSegment.max = this.state.selectedSegmentNodeDraggedValue
    } else {
      newSegment.min = this.state.selectedSegmentNodeDraggedValue
    }
    this.props.setSegment(newSegment)

    this.setState({
      selectedSegmentNode: null,
      selectedSegmentNodeIsMax: false,
      selectedSegmentNodeDraggedValue: 0,
      dragStartingPosition: 0,
    })
  }

  drawSegments() {
    // const upperPolygon = [`${graphSizeMinX},${graphSizeMaxY}`];
    // const lowerPolygon = [];
    const polygons = []
    const circles = []
    const sortedSegments = this.props.segments.sort((a, b) => a.moisRelatif - b.moisRelatif)
    sortedSegments.forEach((segment, i) => {
      const cx =
        graphSizeMinX +
        (graphWidth / this.props.length) * (this.props.length + segment.moisRelatif) +
        2

      let uCy = graphSizeMaxY - segment.max * yUnit
      let lCy = graphSizeMaxY - segment.min * yUnit
      if (this.state.selectedSegmentNode && this.state.selectedSegmentNode.id === segment.id) {
        if (this.state.selectedSegmentNodeIsMax) {
          uCy = graphSizeMaxY - this.state.selectedSegmentNodeDraggedValue * yUnit
        } else {
          lCy = graphSizeMaxY - this.state.selectedSegmentNodeDraggedValue * yUnit
        }
      }

      // Polygon
      if (i !== sortedSegments.length - 1) {
        const width =
          (graphWidth / this.props.length) *
          (sortedSegments[i + 1].moisRelatif - segment.moisRelatif)
        polygons.push(
          <rect
            key={`rect-${cx}-${uCy}`}
            width={width}
            height={lCy - uCy}
            x={cx}
            y={uCy}
            className="hedging-plan-actual"
          />,
        )
      } else {
        const width = svgSizeX - cx
        polygons.push(
          <rect
            key={`rect-${cx}-${uCy}`}
            width={width}
            height={lCy - uCy}
            x={cx}
            y={uCy}
            className="hedging-plan-actual"
          />,
        )
      }

      // Lower point
      circles.push(
        <g key={`c-l-${cx}-${lCy}`}>
          <title>{segment.min}%</title>
          <circle
            id={`hedgingPlanPoint-${segment.id}-min`}
            onClick={this.props.onClick(segment, false)}
            cx={cx}
            cy={lCy}
            r="8"
            onMouseDown={this.props.readOnly ? noop : this.onSegmentDragStart(segment, false)}
            className="hedging-plan-actual-inflexion"
          />
        </g>,
      )
      // lowerPolygon.unshift(`${cx},${lCy}`);

      // Upper point
      circles.push(
        <g key={`c-u-${cx}-${uCy}`}>
          <title>{segment.max}%</title>
          <circle
            id={`hedgingPlanPoint-${segment.id}-max`}
            onClick={this.props.onClick(segment, true)}
            cx={cx}
            cy={uCy}
            r="8"
            onMouseDown={this.props.readOnly ? noop : this.onSegmentDragStart(segment, true)}
            className="hedging-plan-actual-inflexion"
          />
        </g>,
      )
      // upperPolygon.push(`${cx},${uCy}`);
    })
    return (
      <g>
        {/* <polygon points={`${upperPolygon.join(' ')} ${lowerPolygon.join(' ')}`} className="hedging-plan-actual"/> */}
        {polygons}
        {circles}
      </g>
    )
  }

  drawText = () => {
    if (!this.state.selectedSegmentNode) {
      return null
    }
    const x =
      graphSizeMinX +
      (graphWidth / this.props.length) *
      (this.props.length + this.state.selectedSegmentNode.moisRelatif) -
      30
    const y = graphSizeMaxY - this.state.selectedSegmentNodeDraggedValue * yUnit
    return (
      <g>
        <text x={x} y={y} dy=".35em" fill={'#A3A8B5'}>
          {this.state.selectedSegmentNodeDraggedValue}
        </text>
      </g>
    )
  }

  render() {
    return (
      <svg className="hedging-plan-graph" viewBox={`0 0 ${svgSizeX} ${svgSizeY}`}>
        {this.drawVerticalScale()}
        {this.drawHorizontalScale()}
        {this.drawSegments()}
        {this.drawCircleIndicators()}
        {this.drawText()}
      </svg>
    )
  }
}

HedgingPlanGraph.propTypes = {
  onClick: PropTypes.func.isRequired,
  segments: PropTypes.array,
  length: PropTypes.number,
  setSegment: PropTypes.func,
}

export default withTranslation()(HedgingPlanGraph)
