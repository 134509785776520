/** @jsxImportSource @emotion/react */
import React from 'react'
import { useTheme } from '@emotion/react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'

import { wait } from 'helpers/promises'

import {
  fetchStrategyFollowup,
  fetchMonthlyContractCoverage,
  fetchMonthlyCoverageFollowup,
} from 'actions/contract'
import { fetchLotQuotationGraph } from 'actions/quotations'
import { fetchLotQuotationTransactions } from 'actions/transactions'
import { fetchLotQuotationPerformance } from 'actions/performance'
import { fetchParcelQuotationAlerts } from 'actions/alerts'
import { getStrategyFollowup } from 'reducers/contracts'

import StrategyFollowupGraph from 'components/execution/StrategyFollowupGraph'
import MonthlyCoverageGraph from 'components/execution/MonthlyCoverageGraph'
import CoverageFollowupGraph from 'components/execution/CoverageFollowupGraph'
import ReportingQuotation from './ReportingQuotation'
import { PageFooter } from './PageFooter'

import { getStartYear, getEndYear } from 'store/reporting'

const splitArray = (a, n) =>
  Array.from({ length: Math.ceil(a.length / n) }, (x, i) => a.slice(i * n, (i + 1) * n))

const ReportingPage = ({ parcel, contract, index, onLoad, pageIndex, total }) => {
  const { synthese = false, year } = parcel
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const startYear = useSelector(getStartYear)
  const endYear = useSelector(getEndYear)

  const hedgingPlan = useSelector((state) => getStrategyFollowup(state).planCouverture)

  const monthlyContractCoverage = useSelector(
    (state) => state.contracts.currentSelectionMonthlyContractCoverage,
  )

  const prevIndex = React.useRef(null)

  React.useEffect(() => {
    if (!contract || !parcel || prevIndex.current === index) return

    const fetchData = async () => {
      try {
        prevIndex.current = index

        const contracts = [contract.id]
        const startDate = synthese ? moment(year, 'YYYY').startOf('year') : moment(parcel.dateDebut)
        const endDate = synthese ? moment(year, 'YYYY').endOf('year') : moment(parcel.dateFin)

        const actions = [
          // Couverture
          dispatch(fetchStrategyFollowup(contracts, startDate, endDate, !synthese && parcel.id)),
          dispatch(fetchMonthlyContractCoverage(contracts, startDate, endDate)),
          // Prix
          dispatch(fetchMonthlyCoverageFollowup(contracts, startDate, endDate)),
          ...parcel.quotations.map((q) => [
            // Graphique des cotations
            dispatch(fetchLotQuotationGraph(parcel.id, q.IdCotation, true, true)),
            // Limites de prix
            dispatch(fetchParcelQuotationAlerts(parcel.id, q.IdCotation)),
            // Transactions
            dispatch(fetchLotQuotationTransactions(parcel.id, q.IdCotation)),
            // Performance
            dispatch(fetchLotQuotationPerformance(parcel.id, q.IdCotation)),
          ]),
        ]

        await Promise.all(actions)

        await wait(5000)
        onLoad()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [index, contract, parcel, onLoad, dispatch, startYear, endYear, synthese, year])

  const allGraphs = useSelector((state) =>
    get(state, ['quotations', 'lotQuotationGraphByQuot'], {}),
  )
  const allAlerts = useSelector((state) => get(state, ['alerts', 'parcelAlertsByQuot'], {}))
  const allTransactions = useSelector((state) =>
    get(state, ['transactions', 'lotTransactionsByQuot'], {}),
  )
  const allPerformances = useSelector((state) =>
    get(state, ['performance', 'lotPerformanceByQuot'], {}),
  )

  const data = parcel.quotations.map(({ IdLot, IdCotation }) => ({
    graph: get(allGraphs, [IdLot, IdCotation], null),
    alerts: get(allAlerts, [IdLot, IdCotation], []),
    transactions: get(allTransactions, [IdLot, IdCotation], []),
    performance: get(allPerformances, [IdLot, IdCotation], 0, null),
  }))

  const filteredQuotations = parcel.quotations.filter(
    (q, i) =>
      data[i].graph ||
      data[i].performance ||
      data[i].transactions.length > 0 ||
      data[i].alerts.length > 0,
  )

  const [firstQuotation, ...rest] = filteredQuotations
  const quotationChunks = splitArray(rest, 3)

  if (!contract || !parcel) return null

  return (
    <div
      css={(theme) => ({
        fontFamily: theme.fontFamily,
        header: {
          color: '#fff',
          background: theme.blue,
          padding: '4px 8px',
        },
        h5: {
          fontSize: 16,
          marginBottom: 4,
          color: '#fff',
        },
        h6: {
          width: '100%',
          fontSize: 12,
          fontWeight: 'bold',
          color: theme.gold,
          borderBottom: `1px solid ${theme.gold}`,
          marginBottom: 10,
        },
        section: {
          width: '100%',
          overflow: 'hidden',
        },
      })}
    >
      <div
        className="page"
        css={{
          position: 'relative',
          margin: '15px auto',
          width: '21cm',
          height: '29.7cm',
          padding: '1cm',
          background: '#fff',
          display: 'grid',
          gridGap: 15,
          gridTemplateRows: 'auto auto auto 2fr 3fr 3fr',
          gridTemplateAreas: `
            'header1'
            'header2'
            'info'
            'couv'
            'price'
            'quotation'
          `,
        }}
      >
        <header css={{ gridArea: 'header1' }}>
          <h5>{t('reporting.title')}</h5>
          <div>{moment().format('DD/MM/YYYY')}</div>
        </header>

        <header
          css={{
            gridArea: 'header2',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {t('reporting.contract')} : {contract.CtrNom} &mdash; {contract.CtrLibellePays} &ndash;{' '}
            {year}
          </div>

          <div>
            {synthese ? (
              <div>
                {t('reporting.synthesis')} {year}
              </div>
            ) : (
              <div>
                Lot : {parcel.nom} &ndash; {parcel.year}
              </div>
            )}
          </div>
        </header>

        <section css={{ gridArea: 'info' }}>
          <h6>{t('reporting.infos')}</h6>
          <div
            css={{
              padding: '2px 4px',
              background: 'rgba(250, 160, 60, .3)',
              color: theme.gold,
            }}
          >
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {t('reporting.provider')} : {contract.CtrFournisseur}
              </div>
              <div>
                {t('reporting.expiration')}: {contract.CtrDateFin}
              </div>
            </div>
            {!synthese && (
              <div>
                <div>
                  {t('reporting.priceFormula')} : {parcel.typeFormulePrix}
                </div>
              </div>
            )}
          </div>
        </section>

        <section css={{ gridArea: 'couv' }}>
          <h6>{t('reporting.coverage')}</h6>
          <div css={{ display: 'flex' }}>
            <div css={{ width: '55%' }}>
              {hedgingPlan && (
                <StrategyFollowupGraph hedgingPlan={hedgingPlan} print height={200} />
              )}
            </div>
            <div css={{ width: '45%' }}>
              {monthlyContractCoverage && (
                <MonthlyCoverageGraph
                  currentSelectionMonthlyContractCoverage={monthlyContractCoverage}
                  height={200}
                  print
                />
              )}
            </div>
          </div>
        </section>

        <section css={{ gridArea: 'price' }}>
          <h6>{t('reporting.price')} (€/MWh)</h6>
          <div css={{ display: 'flex' }}>
            <div css={{ width: '88%' }}>
              <CoverageFollowupGraph year={year} />
            </div>
            <div css={{ width: '12%' }}>
              <CoverageFollowupGraph year={year} total />
            </div>
          </div>
        </section>

        {firstQuotation && (
          <ReportingQuotation
            idLot={firstQuotation.IdLot}
            idQuotation={firstQuotation.IdCotation}
            unit={firstQuotation.Cotation.CotUnite}
            name={firstQuotation.Cotation.CotProduit.PdtNom}
            year={parcel.year}
          />
        )}

        <PageFooter total={total} index={pageIndex + 1} />
      </div>

      {quotationChunks.map((chunk, index) => (
        <div
          key={index}
          className="page"
          css={{
            position: 'relative',
            margin: '15px auto',
            width: '21cm',
            height: '29.7cm',
            padding: '1cm',
            background: '#fff',
            '>:not(:last-child)': {
              marginBottom: '1cm',
            },
          }}
        >
          {chunk.map((quot, index) => (
            <ReportingQuotation
              key={`${quot.IdCotation}${index}`}
              idLot={quot.IdLot}
              idQuotation={quot.IdCotation}
              unit={quot.Cotation.CotUnite}
              name={quot.Cotation.CotProduit.PdtNom}
              year={parcel.year}
            />
          ))}

          <footer
            css={{
              position: 'absolute',
              bottom: '0.4cm',
              right: '0.8cm',
              fontSize: 10,
            }}
          >
            {total ? `Page ${pageIndex + index + 2}/${total}` : `Page ${pageIndex + index + 2}`}
          </footer>
        </div>
      ))}
    </div>
  )
}

export default ReportingPage
