import { fetchLotQuotationPerformance as apiFetchLotQuotationPerformance } from '../api/performance'
import {
  FETCH_LOT_QUOTATION_PERFORMANCE_REQUEST,
  FETCH_LOT_QUOTATION_PERFORMANCE_FAILURE,
  FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS,
} from './'

export const fetchLotQuotationPerformance = (lotId, cotId) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_QUOTATION_PERFORMANCE_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_QUOTATION_PERFORMANCE_SUCCESS,
      lotId,
      cotId,
      performance: await apiFetchLotQuotationPerformance(lotId, cotId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_QUOTATION_PERFORMANCE_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}
