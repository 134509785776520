/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { usePreferences, usePreferencesReferentiel, useUpdatePreferences } from './preferences'
import { addNotification } from 'notification'

export const Options = () => {
  const { t } = useTranslation()
  const {
    // nrjUnits,
    devises,
  } = usePreferencesReferentiel()
  const { data: pref } = usePreferences()
  const { mutateAsync, status } = useUpdatePreferences()
  const queryClient = useQueryClient()

  const [devise, setDevise] = useState(null)
  // const [nrjUnit, setNrjUnit] = useState(null)

  useEffect(() => {
    if (pref) setDevise(pref.CurrencyUI)
  }, [pref])

  // useEffect(() => {
  //   pref && setNrjUnit(pref.EnergyUnitUI)
  // }, [pref])

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        fontSize: '1rem',
        marginRight: '1rem',
        '@media (max-width: 1500px)': {
          fontSize: '.9rem',
        },
      }}
    >
      {devises.length > 0 && (
        <Select
          label={t('global.currency')}
          value={devise}
          options={devises}
          disabled={status === 'loading' || devise === null}
          onChange={async (value) => {
            setDevise(value)
            try {
              await mutateAsync([{ op: 'replace', path: 'CurrencyUI', value }])
              queryClient.refetchQueries(['preferences'])
            } catch (error) {
              setDevise(pref?.CurrencyUI ?? null)
              addNotification({
                type: 'danger',
                message: error.toString(),
              })
            }
          }}
        />
      )}

      {/* <Select
        label={t('global.nrjUnit')}
        value={nrjUnit}
        options={nrjUnits}
        disabled={status === 'loading' || nrjUnit === null}
        onChange={async (value) => {
          setNrjUnit(value)
          try {
            await mutate([{ op: 'replace', path: 'EnergyUnitUI', value }], {
              refetchQueries: ['preferences'],
            })
          } catch (error) {
            setNrjUnit(pref?.EnergyUnitUI ?? null)
            addNotification({
              type: 'danger',
              message: error.toString(),
            })
          }
        }}
      /> */}
    </div>
  )
}

const Select = ({ label, value, options, disabled = false, onChange }) => {
  return (
    <label
      css={{
        margin: 0,
        '>span': { marginRight: '.5rem' },
        select: { outline: 'none', border: 'none' },
      }}
    >
      <span>{label}</span>
      <select value={value ?? ''} onChange={(e) => onChange(e.target.value)} disabled={disabled}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  )
}
