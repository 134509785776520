import { combineReducers } from 'redux'

import {
  FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
  FETCH_LOT_TRANSACTIONS_SUCCESS,
  FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST,
  FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE,
  FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS,
  FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS,
  ADD_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_REQUEST,
} from '../actions'

const contractTransactions = (state = {}, action) => {
  let newState
  let newUpdatesOfContract
  let index
  switch (action.type) {
    case FETCH_CONTRACT_TRANSACTIONS_SUCCESS:
      newState = { ...state }
      newState[action.contractId] = action.transactions
      return newState
    case ADD_TRANSACTION_SUCCESS:
      newState = { ...state }
      if (newState[action.contractId]) {
        newState[action.contractId] = [...newState[action.contractId], action.transaction]
      } else {
        newState[action.contractId] = [action.transaction]
      }
      return newState
    case UPDATE_TRANSACTION_SUCCESS:
      newState = { ...state }
      if (newState[action.contractId]) {
        newUpdatesOfContract = [...newState[action.contractId]]
        index = newUpdatesOfContract.findIndex(
          (transaction) => transaction.id === action.transaction.id,
        )
        newUpdatesOfContract[index] = action.transaction
        newState[action.contractId] = newUpdatesOfContract
      }
      return newState
    case DELETE_TRANSACTION_REQUEST: {
      const { contractId, transactionId } = action
      const transactions = state[contractId]
      if (!transactions) return state
      return {
        ...state,
        [contractId]: transactions.filter((b) => b.id !== transactionId),
      }
    }
    default:
      return state
  }
}

const lotTransactions = (state = {}, action) => {
  let newState
  let newUpdatesOfLot
  let index
  switch (action.type) {
    case FETCH_LOT_TRANSACTIONS_SUCCESS:
    case FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS:
      newState = { ...state }
      newState[action.lotId] = action.transactions
      return newState
    case ADD_TRANSACTION_SUCCESS:
      newState = { ...state }
      if (newState[action.lotId]) {
        newState[action.lotId] = [...newState[action.lotId], action.transaction]
      } else {
        newState[action.lotId] = [action.transaction]
      }
      return newState
    case UPDATE_TRANSACTION_SUCCESS:
      newState = { ...state }
      if (newState[action.lotId]) {
        newUpdatesOfLot = [...newState[action.lotId]]
        index = newUpdatesOfLot.findIndex((transaction) => transaction.id === action.transaction.id)
        newUpdatesOfLot[index] = action.transaction
        newState[action.lotId] = newUpdatesOfLot
      }
      return newState
    case DELETE_TRANSACTION_REQUEST:
      newState = { ...state }
      if (newState[action.lotId]) {
        newState[action.lotId] = [
          ...newState[action.lotId].filter((b) => {
            return b.id !== action.transactionId
          }),
        ]
      }
      return newState
    default:
      return state
  }
}

const lotTransactionsByType = (
  state = {
    loading: false,
    error: null,
    data: {},
  },
  action,
) => {
  switch (action.type) {
    case FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    case FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          [action.lotId]: action.transactions,
        },
      }
    default:
      return state
  }
}

const lotTransactionsByQuot = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        [action.lotId]: {
          ...state[action.lotId],
          [action.cotId]: action.transactions,
        },
      }
    default:
      return state
  }
}

export const getTransactions = (state) => state.transactions.contractTransactions
export const getContractTransactions = (state, contractId) =>
  state.transactions.contractTransactions[contractId] || []

export const getLotTransactions = (state, lotId) =>
  lotId ? state.transactions.lotTransactions[lotId] : []

export const getLotTransactionsByType = (state) => state.transactions.lotTransactionsByType
export const getLotTransaction = (state, lotId) =>
  lotId ? state.transactions.lotTransactionsByType.data[lotId] : []

export default combineReducers({
  contractTransactions,
  lotTransactions,
  lotTransactionsByQuot,
  lotTransactionsByType,
})
