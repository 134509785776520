/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { Row, Col, PopoverBody, Popover } from 'reactstrap'
import { FormGroup, Label, Button, Input, InputGroupAddon } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import { HedgingProfileTarget } from './HedgingProfileTarget'

const availableUnit = {
  percent: '%',
  watt: 'MW',
}

const HedgingProfilePopover = ({ nrjId, ...props }) => {
  const { t } = useTranslation()

  const [state, setState] = useState({
    hedgeTypes: props.initialHedgeData
      ? [
          {
            hedgeTypeCode: props.initialHedgeData.perTypeCode,
            hedgeTypeNum: props.initialHedgeData.perTypeNum,
            hedgeTypeYear: props.initialHedgeData.perAnnee,
            hedgeTypeLength: props.initialHedgeData.perTypeLongueur,
            isArenh: props.initialHedgeData.isArenh,
          },
        ]
      : [],
    hedgeLength: props.initialHedgeData ? props.initialHedgeData.perTypeLongueur : 'Y',
    hedgeTypeLength: props.initialHedgeData ? props.initialHedgeData.perTypeLongueur : 'Y',
    hedgeStep: props.initialHedgeData ? props.initialHedgeData.pas : 25,
    hedgeDealValue: props.initialHedgeData ? props.initialHedgeData.valeur : 75,
  })

  const setHedgeLength = (length) => () => {
    setState((state) => ({
      ...state,
      // Reset those values to have consistent data
      hedgeTypes: [],
      hedgeTypeLength: length,
    }))
  }

  // Sélectionne un bloc
  const setHedgeType = (code, length, num, year, isArenh) => () => {
    const hedgeTypes = state.hedgeTypes.slice(0)

    // block selected
    const blockType = {
      hedgeTypeCode: code,
      hedgeTypeNum: num,
      hedgeTypeYear: year,
      hedgeTypeLength: length,
      isArenh,
    }

    if (code === 'CAL') {
      const index = hedgeTypes.findIndex((t) => t.hedgeTypeCode === 'CAL' && t.isArenh !== isArenh)
      hedgeTypes.splice(index, 1)
    }

    let index = -1
    for (let i = 0; i < hedgeTypes.length; i++) {
      if (isEqual(hedgeTypes[i], blockType)) {
        index = i
        break
      }
    }

    // Si le bloc est déjà sélectionné on le supprime
    if (index > -1) {
      hedgeTypes.splice(index, 1)
    } else {
      // Sinon on l'ajoute à la sélection
      hedgeTypes.push(blockType)
    }

    setState({ ...state, hedgeTypes })
  }

  const setHedgeStep = (step) => () => {
    setState((state) => ({
      ...state,
      hedgeStep: step,
    }))
  }

  const setHedgeStepFromInput = (e) => {
    setHedgeStep(parseFloat(e.target.value, 10))()
  }

  const setHedgeDealValue = (hedgeDealValue) => {
    setState((state) => ({
      ...state,
      hedgeDealValue,
    }))
  }

  const addHedge = () => {
    const initial = props.initialHedgeData || {}
    const hedge = {
      ...initial,
      blocks: state.hedgeTypes,
      pas: state.hedgeStep,
      valeur: state.hedgeDealValue,
    }
    props.togglePopover()
    props.addHedge(hedge)
  }

  const profileContainsBloc = (bloc) => {
    if (props.products && props.products.length > 0) {
      return (
        props.products.filter(
          (b) =>
            b.perAnnee === bloc.hedgeTypeYear &&
            b.perTypeLongueur === bloc.hedgeTypeLength &&
            b.perTypeNum === bloc.hedgeTypeNum &&
            b.perTypeCode === bloc.hedgeTypeCode &&
            b.isArenh === bloc.isArenh,
        ).length > 0
      )
    }
    return false
  }

  const deleteHedge = () => {
    props.deleteHedge(props.initialHedgeData)
    props.togglePopover()
  }

  const buildTargets = () => {
    const { hedgeTypes } = state
    const res = []
    const start = new Date(props.startDate)
    const end = new Date(props.endDate)

    for (let y = start.getFullYear(); y <= end.getFullYear(); y++) {
      switch (state.hedgeTypeLength) {
        case 'M': {
          const moStart = y === start.getFullYear() ? start.getMonth() + 1 : 1
          const moEnd = y === end.getFullYear() ? end.getMonth() + 1 : 12
          for (let m = moStart; m <= moEnd; m++) {
            const cBloc = {
              hedgeTypeCode: `M${m}`,
              hedgeTypeNum: m,
              hedgeTypeYear: y,
              hedgeTypeLength: 'M',
              isArenh: false,
            }
            res.push(
              <HedgingProfileTarget
                key={`M${m}${y}`}
                disabled={props.isBlockUpdate || profileContainsBloc(cBloc)}
                onChange={setHedgeType(`M${m}`, 'M', m, y, false)}
                label={`M${m}${end.getFullYear() - start.getFullYear() > 0 ? ` ${y}` : ''}`}
                checked={hedgeTypes.filter((b) => isEqual(b, cBloc)).length > 0}
              />,
            )
          }
          break
        }
        case 'Q': {
          const qStart = y === start.getFullYear() ? Math.floor((start.getMonth() + 1) / 3) + 1 : 1
          const qEnd = y === end.getFullYear() ? Math.floor((end.getMonth() + 1) / 3) : 4
          for (let q = qStart; q <= qEnd; q++) {
            const cBloc = {
              hedgeTypeCode: `Q${q}`,
              hedgeTypeNum: q,
              hedgeTypeYear: y,
              hedgeTypeLength: 'Q',
              isArenh: false,
            }
            res.push(
              <HedgingProfileTarget
                key={`Q${q}${y}`}
                disabled={props.isBlockUpdate || profileContainsBloc(cBloc)}
                onChange={setHedgeType(`Q${q}`, 'Q', q, y, false)}
                label={`Q${q}${end.getFullYear() - start.getFullYear() > 0 ? ` ${y}` : ''}`}
                checked={hedgeTypes.filter((b) => isEqual(b, cBloc)).length > 0}
              />,
            )
          }
          break
        }
        case 'Y': {
          if (
            !(y === start.getFullYear() && start.getMonth() > 0) &&
            !(y === end.getFullYear() && end.getMonth() < 11)
          ) {
            const cBloc = {
              hedgeTypeCode: 'CAL',
              hedgeTypeLength: 'Y',
              hedgeTypeNum: 1,
              hedgeTypeYear: y,
              isArenh: false,
            }
            const cBlocArenh = {
              hedgeTypeCode: 'CAL',
              hedgeTypeLength: 'Y',
              hedgeTypeNum: 1,
              hedgeTypeYear: y,
              isArenh: true,
            }
            res.push(
              <HedgingProfileTarget
                key={`CAL${y}`}
                disabled={props.isBlockUpdate || profileContainsBloc(cBloc)}
                onChange={setHedgeType('CAL', 'Y', 1, y, false)}
                label={`CAL ${y}`}
                checked={hedgeTypes.filter((b) => isEqual(b, cBloc)).length}
              />,
            )
            if (nrjId === 2) {
              res.push(
                <HedgingProfileTarget
                  key={`ARENH${y}`}
                  disabled={props.isBlockUpdate || profileContainsBloc(cBlocArenh)}
                  onChange={setHedgeType('CAL', 'Y', 1, y, true)}
                  label={`ARENH ${y}`}
                  checked={hedgeTypes.filter((b) => isEqual(b, cBlocArenh)).length}
                />,
              )
            }
          }
          break
        }
        default:
          break
      }
    }
    return res
  }

  return (
    <Popover
      placement="bottom"
      className="hedging-profile-popover"
      isOpen={props.isOpen}
      target="hedgingProfilePopover"
      toggle={props.togglePopover}
    >
      <PopoverBody>
        <Row>
          <Col>
            <div css={{ display: 'flex', alignItems: 'flex-start' }}>
              <label className="title">{t('strategy.hedgingProfilePopover.length')}</label>
              <Button close onClick={props.togglePopover} />
            </div>

            <div className="choice-group full-width">
              <FormGroup
                check
                className={
                  state.hedgeTypeLength === 'M'
                    ? 'checked '
                    : '' + (props.initialHedgeData ? ' disabled ' : '')
                }
              >
                <Label check className={state.hedgeTypeLength === 'M' ? 'checked' : ''}>
                  <Input
                    type="radio"
                    name="radio1"
                    disabled={props.isBlockUpdate && state.hedgeTypeLength !== 'M'}
                    checked={state.hedgeTypeLength === 'M'}
                    onChange={setHedgeLength('M')}
                  />
                  {t('strategy.hedgingProfilePopover.month')}
                </Label>
              </FormGroup>
              <FormGroup
                check
                className={
                  state.hedgeTypeLength === 'Q'
                    ? 'checked'
                    : '' + (props.initialHedgeData ? ' disabled ' : '')
                }
              >
                <Label check className={state.hedgeTypeLength === 'Q' ? 'checked' : ''}>
                  <Input
                    type="radio"
                    name="radio1"
                    disabled={props.isBlockUpdate && state.hedgeTypeLength !== 'Q'}
                    checked={state.hedgeTypeLength === 'Q'}
                    onChange={setHedgeLength('Q')}
                  />
                  {t('strategy.hedgingProfilePopover.quarter')}
                </Label>
              </FormGroup>
              <FormGroup
                check
                className={
                  state.hedgeTypeLength === 'Y'
                    ? 'checked'
                    : '' + (props.initialHedgeData ? ' disabled ' : '')
                }
              >
                <Label check className={state.hedgeTypeLength === 'Y' ? 'checked' : ''}>
                  <Input
                    type="radio"
                    name="radio1"
                    disabled={props.isBlockUpdate && state.hedgeTypeLength !== 'Y'}
                    checked={state.hedgeTypeLength === 'Y'}
                    onChange={setHedgeLength('Y')}
                  />
                  {t('strategy.hedgingProfilePopover.calendar')}
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="title">{t('strategy.hedgingProfilePopover.target')}</label>
            <div className="hedging-popover-target choice-group full-width">{buildTargets()}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="title">{t('strategy.hedgingProfilePopover.step')}</label>
            <span className="choice-group full-width last-apart">
              <Input
                value={state.hedgeStep}
                type="number"
                onChange={setHedgeStepFromInput}
                /* onFocus={setHedgeStepFromInput} */
                min="0"
              />
              <InputGroupAddon addonType="append">{props.unit}</InputGroupAddon>
            </span>
          </Col>
          <Col>
            <label className="title">{t('strategy.hedgingProfilePopover.volumeToCover')}</label>
            <span className="choice-group full-width last-apart">
              <Input
                value={state.hedgeDealValue}
                type="number"
                onChange={(e) => {
                  const value = e.target.value
                  const parsedValue = isNaN(value) || value === '' ? value : parseFloat(value, 10)
                  setHedgeDealValue(parsedValue)
                }}
              />
              <InputGroupAddon addonType="append">{props.unit}</InputGroupAddon>
            </span>
          </Col>
        </Row>
        {!((state.hedgeDealValue / state.hedgeStep) % 1 === 0) && (
          <Row>
            <Col className="error">{t('strategy.hedgingProfilePopover.error')}</Col>
          </Row>
        )}
        <Row>
          <Col>
            {props.initialHedgeData ? (
              <Button className="float-left rounded" color="danger" onClick={deleteHedge}>
                {t('strategy.hedgingProfilePopover.delete')}
              </Button>
            ) : null}
            <Button
              disabled={
                state.hedgeDealValue === 0 ||
                !state.hedgeTypes ||
                state.hedgeTypes.length === 0 ||
                !state.hedgeStep > 0 ||
                (props.unit === availableUnit.percent && !(state.hedgeStep <= 100)) ||
                (state.hedgeDealValue / state.hedgeStep) % 1 !== 0
              }
              className="float-right rounded"
              color="primary"
              onClick={addHedge}
            >
              {props.initialHedgeData
                ? t('strategy.hedgingProfilePopover.edit')
                : t('strategy.hedgingProfilePopover.add')}
            </Button>
          </Col>
        </Row>
      </PopoverBody>
    </Popover>
  )
}

export default HedgingProfilePopover
