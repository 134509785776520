import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { withTranslation } from 'react-i18next'

import Header from './Header'
import HedgingProfile from './hedgingProfile/HedgingProfile'
import HedgingPlans from './hedgingPlan/HedgingPlans'
import Alerts from './alerts/Alerts'
import StrategyContractBoard from './StrategyContractBoard'
import ContractParcel from '../contractInfo/ContractParcel'

// Actions
import { fetchContractConfigStatus } from '../../actions/contract'
import { fetchContractParcels } from '../../actions/parcels'
import { setUniqueContractVisible } from '../../actions/contractSelection'

// Selectors
import { getContractParcels } from '../../reducers/parcels'
import { getVisibleContractsObjets } from '../../reducers/contracts'
import { isAdmin, isSuperUser } from '../../selectors/user'
import DmrCotationsBoard from 'components/admin/DmrCotationsBoard'

class Strategy extends Component {
  componentDidMount() {
    if (this.props.selectedContract) {
      this.props.fetchContractParcels(this.props.selectedContract.id)
    }
    if (this.props.contractParcels.length > 0) {
      this.props.setSelectedParcel(this.props.contractParcels[0])
    }
    if (
      !this.props.selectedContract &&
      this.props.visibleContractIds &&
      this.props.visibleContractIds.length > 0
    ) {
      this.props.fetchContractConfigStatus(this.props.visibleContractIds)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldProps = this.props
    if (
      !newProps.selectedContract &&
      newProps.visibleContractIds &&
      newProps.visibleContractIds.length > 0 &&
      newProps.visibleContractIds !== oldProps.visibleContractIds
    ) {
      this.props.fetchContractConfigStatus(newProps.visibleContractIds)
    }

    if (
      newProps.selectedContract &&
      !newProps.visibleContractIds.includes(newProps.selectedContract.id)
    ) {
      this.props.setSelectedParcel(null)
      return
    }
    if (
      (oldProps.selectedContract &&
        newProps.selectedContract &&
        oldProps.selectedContract.id !== newProps.selectedContract.id) ||
      (!oldProps.selectedContract && newProps.selectedContract)
    ) {
      this.props.setSelectedParcel(null)
      this.props.fetchContractParcels(newProps.selectedContract.id)
    }
    if (newProps.selectedParcel === null && newProps.contractParcels.length > 0) {
      this.props.setSelectedParcel(newProps.contractParcels[0])
    }
  }

  render() {
    const {
      contracts,
      configurationStatus,
      selectedContract,
      setUniqueContractVisible,
      selectedParcel,
      setSelectedParcel,
      contractParcels,
      selectedYear,
      setSelectedYear,
      selectedCodeType,
      setSelectedCodeType,
      isAdmin,
      t,
    } = this.props

    return !isAdmin ? (
      <div
        style={{
          fontSize: '1rem',
          fontWeight: 'bold',
          margin: '1rem',
        }}
      >
        {t('global.denied')}
      </div>
    ) : (
      <div>
        {selectedContract ? (
          <div>
            <Row className="main-header">
              <Header
                contract={selectedContract}
                selectedParcel={selectedParcel}
                setSelectedParcel={setSelectedParcel}
                contractParcels={contractParcels}
                setUniqueContractVisible={setUniqueContractVisible}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedCodeType={selectedCodeType}
                setSelectedCodeType={setSelectedCodeType}
              />
            </Row>
            <Row>
              {selectedParcel && (
                <Col>
                  <Row>
                    <Col className="col-4">
                      <section className="section-ninety">
                        <Row>
                          <Col className="col-4">
                            <h6>{t('strategy.contractDetail.title')}</h6>
                          </Col>
                        </Row>
                        <ContractParcel
                          key={`parcel-${selectedParcel.id}`}
                          selectedContract={selectedContract}
                          selectedParcel={selectedParcel}
                          isAdmin={isAdmin}
                        />
                      </section>
                    </Col>
                    <Col className="col-8">
                      <section className="section-ninety">
                        <Row>
                          <Col className="col-8">
                            <h6>{t('header.strategy.hedgingProfile')}</h6>
                          </Col>
                        </Row>
                        <HedgingProfile contract={selectedContract} parcel={selectedParcel} />
                      </section>
                    </Col>
                  </Row>
                  <HedgingPlans contract={selectedContract} parcel={selectedParcel} />
                  <Alerts
                    contract={selectedContract}
                    parcel={selectedParcel}
                    displayQuotationGraph={true}
                  />
                </Col>
              )}
            </Row>
          </div>
        ) : (
          <>
            <Row>
              <Col>
                <StrategyContractBoard
                  contracts={contracts}
                  setUniqueContractVisible={setUniqueContractVisible}
                  configurationStatus={configurationStatus}
                />
              </Col>
            </Row>
            {isSuperUser && (
              <Row>
                <Col>
                  <DmrCotationsBoard />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    )
  }
}

Strategy.propTypes = {}

const mapStateToProps = (state) => ({
  visibleContractIds: state.contracts.visibleContracts,
  contracts: getVisibleContractsObjets(state),
  configurationStatus: state.contracts.configurationStatus,
  selectedContract: state.contracts.zoomOnContract,
  contractParcels: state.contracts.zoomOnContract
    ? getContractParcels(state.contracts.zoomOnContract.id)(state)
    : [],
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchContractConfigStatus: (contractId) => {
    dispatch(fetchContractConfigStatus(contractId))
  },
  fetchContractParcels: (contractId) => {
    dispatch(fetchContractParcels(contractId))
  },
  setUniqueContractVisible: (contract, readonly) => {
    dispatch(setUniqueContractVisible(contract, readonly))
  },
})

export default compose(
  withTranslation(),
  withState('selectedParcel', 'setSelectedParcel', null),
  withState('selectedYear', 'setSelectedYear', null),
  withState('selectedCodeType', 'setSelectedCodeType', 'Y'),
  connect(mapStateToProps, mapDispatchToProps),
)(Strategy)
