import { useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { superFetch } from 'commons'

const ONE_DAY = 86400000

const mapKeys = (options) =>
  options.map((option) => ({ label: option.m_Item2, value: option.m_Item1 }))

export const usePreferencesReferentiel = () => {
  // const { data: nrjUnits } = useQuery(
  //   ['nrjUnitsRef'],
  //   () =>
  //     superFetch({
  //       url: `referentiel/nrjunites`,
  //     }),
  //   { staleTime: ONE_DAY },
  // )

  const { data: devises } = useQuery(
    ['devisesRef'],
    () =>
      superFetch({
        url: `referentiel/devises`,
      }),
    { staleTime: ONE_DAY },
  )

  return {
    // nrjUnits: nrjUnits ? mapKeys(nrjUnits) : [],
    devises: devises ? mapKeys(devises) : [],
  }
}

export const usePreferences = () => {
  return useQuery('preferences', () =>
    superFetch({
      url: `utilisateurs/preferences`,
    }),
  )
}

export const useUpdatePreferences = () => {
  return useMutation((payload) =>
    superFetch({
      url: `/utilisateurs/preferences`,
      method: 'PATCH',
      body: payload,
    }),
  )
}

export const useDevise = () => {
  const pref = usePreferences()
  const deviseId = pref.data?.CurrencyUI
  const { devises } = usePreferencesReferentiel()
  return useMemo(() => devises.find(({ value }) => value === deviseId)?.label, [deviseId, devises])
}
