import React from 'react'
import { connect } from 'react-redux'
import HighchartsReact from 'highcharts-react-official'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import compose from 'lodash/fp/compose'

import { Highcharts } from 'helpers/highcharts'
import colors from 'colors'
import { getMonthlyConverageFollowup } from 'reducers/contracts'
import { decimalFormat } from 'helpers/formater'

const createConfig = (data, t, total) => {
  if (!data || data.length === 0) return { title: { text: null }, exporting: { enabled: false } }

  const totalPrices = data.map((m) => [
    Number(moment(m.date).format('x')),
    m.info.volumeFermeFixe + m.info.volumeOuvert + m.info.volumeFermeFlottant === 0
      ? 0
      : (m.info.depenseFermeFixe + m.info.depenseOuverte + m.info.depenseFermeFlottant) /
        (m.info.volumeFermeFixe + m.info.volumeOuvert + m.info.volumeFermeFlottant),
  ])

  const fixedPrices = data.map((m) => [Number(moment(m.date).format('x')), m.info.prixFermeFixe])

  const floatingPrices = data.map((m) => [
    Number(moment(m.date).format('x')),
    m.info.volumeOuvert + m.info.volumeFermeFlottant === 0
      ? 0
      : (m.info.depenseOuverte + m.info.depenseFermeFlottant) /
        (m.info.volumeOuvert + m.info.volumeFermeFlottant),
  ])

  const { color8, color2, color4 } = colors

  const totalSum = totalPrices.reduce((a, b) => a + b[1], 0)
  const fixedSum = fixedPrices.reduce((a, b) => a + b[1], 0)
  const floatSum = floatingPrices.reduce((a, b) => a + b[1], 0)

  // const minValues = [totalPrices, fixedPrices, floatingPrices]
  //   .map((arr) => Math.min(...arr.map((x) => x[1])))
  //   .filter((x) => x !== 0)

  const maxValues = [totalPrices, fixedPrices, floatingPrices].map((arr) =>
    Math.max(...arr.map((x) => x[1])),
  )

  // const minValue = minValues.length === 0 ? 0 : Math.min(...minValues)
  const maxValue = Math.max(...maxValues)

  // const delta = maxValue - minValue
  // const pad = delta > 5 ? 0 : 5 - delta
  const min = 0 // Math.floor(minValue - pad / 2)
  const max = Math.floor(maxValue) + 2 // Math.ceil(maxValue + pad / 2)

  return {
    chart: {
      height: total ? 255 : 300,
      style: {
        fontFamily: 'Muli, sans-serif',
        fontSize: 8,
      },
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 30 * 60 * 60 * 24 * 1000,
      labels: {
        enabled: true,
        step: 1,
        // rotation: -45,
        formatter: function () {
          const m = moment(this.value)
          return total ? `${m.format('YYYY')}` : `${m.format('MM')}<br />${m.format('YY')}`
        },
        style: {
          fontSize: 8,
        },
      },
    },
    yAxis: {
      min,
      max,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          fontSize: 8,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: !total,
      symbolWidth: 12,
      symbolHeight: 6,
      itemStyle: {
        fontSize: 10,
        fontWeight: 'normal',
        color: '#666666',
      },
    },
    plotOptions: {
      series: {
        animation: false,
        enableMouseTracking: false,
      },
      column: {
        dataLabels: {
          enabled: true,
          verticalAlign: 'middle',
          inside: true,
          style: {
            fontSize: 8,
            fontWeight: 400,
          },
          formatter: function () {
            return decimalFormat(this.y, { digitCount: 1 })
          },
        },
      },
    },
    series: total
      ? [
          {
            name: t('execution.coverageFollowup.total'),
            data:
              totalPrices.length === 0 ? [] : [[totalPrices[0][0], totalSum / totalPrices.length]],
            type: 'column',
            zIndex: 0,
            color: color8,
          },
          ...(fixedSum === 0
            ? []
            : [
                {
                  name: t('execution.coverageFollowup.fixed'),
                  data:
                    fixedPrices.length === 0
                      ? []
                      : [[fixedPrices[0][0], fixedSum / fixedPrices.length]],
                  color: color2,
                  lineWidth: 0,
                  marker: {
                    symbol: 'square',
                    radius: 3,
                  },
                  label: {
                    enabled: false,
                  },
                  dataLabels: {
                    enabled: true,
                    verticalAlign: 'middle',
                    x: -26,
                    style: {
                      fontSize: 8,
                      fontWeight: 400,
                      color: color2,
                    },
                    formatter: function () {
                      return decimalFormat(this.y, { digitCount: 1, force: true })
                    },
                  },
                },
              ]),
          ...(floatSum === 0
            ? []
            : [
                {
                  name: t('execution.coverageFollowup.floatting'),
                  data:
                    floatingPrices.length === 0
                      ? []
                      : [[floatingPrices[0][0], floatSum / floatingPrices.length]],
                  color: color4,
                  lineWidth: 0,
                  marker: {
                    symbol: 'square',
                    radius: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    verticalAlign: 'middle',
                    x: 28,
                    style: {
                      fontSize: 8,
                      fontWeight: 400,
                      color: color4,
                    },
                    formatter: function () {
                      return decimalFormat(this.y, { digitCount: 1, force: true })
                    },
                  },
                  label: {
                    enabled: false,
                  },
                },
              ]),
        ]
      : [
          {
            name: t('execution.coverageFollowup.total'),
            data: totalPrices,
            type: 'column',
            zIndex: 0,
            color: color8,
          },
          ...(fixedSum === 0
            ? []
            : [
                {
                  name: t('execution.coverageFollowup.fixed'),
                  data: fixedPrices,
                  color: color2,
                  lineWidth: 0,
                  marker: {
                    symbol: 'square',
                    radius: 3,
                  },
                  label: {
                    enabled: false,
                  },
                  dataLabels: {
                    enabled: true,
                    verticalAlign: 'middle',
                    x: 13,
                    style: {
                      fontSize: 8,
                      fontWeight: 400,
                      color: color2,
                    },
                    formatter: function () {
                      return decimalFormat(this.y, { digitCount: 1, force: true })
                    },
                  },
                },
              ]),
          ...(floatSum === 0
            ? []
            : [
                {
                  name: t('execution.coverageFollowup.floatting'),
                  data: floatingPrices,
                  color: color4,
                  lineWidth: 0,
                  marker: {
                    symbol: 'square',
                    radius: 3,
                  },
                  dataLabels: {
                    enabled: true,
                    verticalAlign: 'middle',
                    x: -18,
                    style: {
                      fontSize: 8,
                      fontWeight: 400,
                      color: color4,
                    },
                    formatter: function () {
                      return decimalFormat(this.y, { digitCount: 1, force: true })
                    },
                  },
                  label: {
                    enabled: false,
                  },
                },
              ]),
        ],
  }
}

const CoverageFollowupGraph = ({ total = false, data, t }) => {
  const options = createConfig(data, t, total)
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

const mapState = (state) => ({
  data: getMonthlyConverageFollowup(state),
})

export default compose(withTranslation(), connect(mapState))(CoverageFollowupGraph)
