export const rgbs = [
  [0 , 155, 164],
  [243, 146, 0],
  [255, 204, 0],
  [149, 193, 31],
  [0, 159, 227],
  [115, 140, 153],
  [90, 87, 160],
  [55, 55, 110],
  
]

const colors = rgbs.reduce(
  (acc, rgb, index) => ({
    ...acc,
    [`color${index + 1}`]: `rgb(${rgb.join(',')})`,
    [`color${index + 1}alpha`]: `rgba(${rgb.join(',')},0.8)`,
  }),
  {},
)

const allColors = {
  ...colors,
  primary: colors.color1,
  secondary: colors.color2,
  success: colors.color2,
  info: colors.color6,
  warning: colors.color5,
  danger: colors.color3,
  light: '#f8f9fa',
  dark: colors.color1,
}

export default allColors
