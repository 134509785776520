import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import HedgingProfilePopover from './HedgingProfilePopover'
import HedgingProfileGraph from './HedgingProfileGraph'
import {
  addParcelHedging,
  fetchParcelHedging,
  updateParcelHedging,
  deleteParcelHedging,
} from '../../../actions/hedgingProfile'

class HedgingProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popoverOpen: false,
      popoverBlockData: null,
      isBlockUpdate: false,
    }
  }

  componentDidMount() {
    this.props.fetchParcelHedging(this.props.contract.id, this.props.parcel.id)
  }

  componentDidUpdate(oldProps) {
    if (this.props.parcel.id !== oldProps.parcel.id) {
      this.props.fetchParcelHedging(this.props.contract.id, this.props.parcel.id)
    }
  }

  togglePopover = () => {
    this.setState({
      popoverBlockData: null,
      popoverOpen: !this.state.popoverOpen,
      isBlockUpdate: false,
    })
  }

  togglePopoverWithBlockData = (popoverBlockData) => {
    this.setState({
      popoverBlockData: popoverBlockData,
      popoverOpen: !this.state.popoverOpen,
      isBlockUpdate: true,
    })
  }

  addParcelHedging = (hedging) => {
    this.props.addParcelHedging(this.props.parcel.id, this.props.profileId, {
      ...hedging,
    })
  }

  deleteParcelHedging = (hedging) => {
    this.props.deleteParcelHedging(this.props.parcel.id, this.props.profileId, {
      ...hedging,
    })
  }

  render() {
    const { readOnly } = this.props

    return (
      <div id="hedgingProfile">
        <Row>
          <Col>
            {!readOnly && (
              <Button
                className="float-right rounded standard-width"
                style={{ color: 'black', background: '#98D2DD', border: '#98D2DD' }}
                id="hedgingProfilePopover"
                onClick={this.togglePopover}
              >
                {this.props.t('strategy.hedgingProfile.addProduct')}
              </Button>
            )}
            {this.state.popoverOpen && (
              <HedgingProfilePopover
                isOpen={this.state.popoverOpen}
                togglePopover={this.togglePopover}
                startDate={this.props.parcel.dateDebut}
                endDate={this.props.parcel.dateFin}
                maxValue={this.props.parcel.max || 100}
                profileType={this.props.parcel.typeCouverture || 0}
                initialHedgeData={this.state.popoverBlockData}
                addHedge={this.addParcelHedging}
                deleteHedge={this.deleteParcelHedging}
                unit="%"
                isBlockUpdate={this.state.isBlockUpdate}
                products={this.props.hedges}
                contractId={this.props.contract.id}
                isArenh={this.props.parcel.IsArenh}
                parcelId={this.props.parcel.id}
                nrjId={this.props.contract.CtrNrjId}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.hedges !== undefined && (
              <HedgingProfileGraph
                onClick={readOnly ? () => { } : this.togglePopoverWithBlockData}
                products={this.props.hedges}
                startDate={this.props.parcel.dateDebut}
                endDate={this.props.parcel.dateFin}
                unit="%"
              />
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

HedgingProfile.propTypes = {
  contract: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { parcel }) => ({
  contractParcels: state.parcels.contractParcels,
  hedges: state.hedgingProfile.parcelHedges[parcel.id] || [],
  profileId: state.hedgingProfile.profile[parcel.id]
    ? state.hedgingProfile.profile[parcel.id].id
    : null,
})
const mapDispatchToProps = (dispatch) => ({
  fetchParcelHedging: (contractId, parcelId) => {
    dispatch(fetchParcelHedging(contractId, parcelId))
  },
  addParcelHedging: (parcelId, profileId, hedging) => {
    if (hedging.id) {
      dispatch(updateParcelHedging(parcelId, { ...hedging, ProfilId: profileId }))
    } else {
      dispatch(addParcelHedging(parcelId, { ...hedging, ProfilId: profileId }))
    }
  },
  deleteParcelHedging: (parcelId, profileId, hedging) => {
    dispatch(deleteParcelHedging(parcelId, { ...hedging, ProfilId: profileId }))
  },
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(HedgingProfile)
