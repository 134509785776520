import { superFetch } from 'commons'

export const fetchContractTransactions = (contractId, quotationId) => {
  let url = `contrats/${contractId}/transaction`
  if (quotationId) url = `${url}?idCotation=${quotationId}`

  return superFetch({ url: url })
}

export const fetchLotTransactions = (lotId) => {
  return superFetch({ url: `lot/${lotId}/transaction` })
}

export const fetchLotPeriodeTransactions = (lotId, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
  }
  return superFetch({
    url: `lot/${lotId}/transaction/periode`,
    method: 'POST',
    body: query,
  })
}

export const fetchLotQuotationTransactions = (lotId, cotId) => {
  return superFetch({ url: `lot/${lotId}/cotation/${cotId}/transaction` })
}

export const addTransaction = (lotId, transaction) => {
  return superFetch({
    url: `lot/${lotId}/transaction`,
    method: 'POST',
    body: transaction,
  })
}

export const updateTransaction = (lotId, transaction) => {
  return superFetch({
    url: `lot/${lotId}/transaction/${transaction.id}`,
    method: 'PUT',
    body: transaction,
  })
}

export const deleteTransaction = (lotId, transactionId) => {
  const url = `lot/${lotId}/transaction/${transactionId}`
  return superFetch({ url: url, method: 'DELETE' })
}
