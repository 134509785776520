/** @jsxImportSource @emotion/react */
import { Button, ModalFooter, Progress } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export const Footer = ({ openOptions, toggleModal, progress, createPDF }) => {
  const { t } = useTranslation()
  return (
    <ModalFooter
      css={{
        padding: 10,
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        '>div >*:not(:last-child)': {
          marginRight: 10,
        },
      }}
    >
      <div>
        <Button color="secondary" size="sm" onClick={openOptions}>
          <i
            className="icon-smallarrow-left"
            css={{ marginRight: '1em', fontSize: '0.8em', position: 'relative', top: -1 }}
          />
          {t('reporting.options')}
        </Button>
      </div>

      <div css={{ display: 'flex' }}>
        <Button color="light" size="sm" onClick={toggleModal} css={{ marginRight: 10 }}>
          <i className="icon-cross" css={{ marginRight: '1em', fontSize: '0.8em' }} />
          {t('reporting.cancel')}
        </Button>

        {progress < 100 ? (
          <Progress
            striped
            animated
            color="secondary"
            value={progress}
            style={{ width: 118, height: 28, fontWeight: 'bold' }}
            className="border"
          >
            {progress < 34 ? '' : `${Math.round(progress)}%`}
          </Progress>
        ) : (
          <Button
            color="secondary"
            size="sm"
            onClick={createPDF}
            style={{ width: 135, height: 28 }}
          >
            {t('reporting.downloadPdf')}
          </Button>
        )}
      </div>
    </ModalFooter>
  )
}
