import { superFetch } from 'commons'

export const fetchParcelHedging = (contractId, parcelId) => {
  return superFetch({ url: `couvertures/profils/${parcelId}` })
}

export const addParcelHedging = (hedge) => {
  return superFetch({
    url: 'couvertures/blocs',
    method: 'POST',
    body: hedge,
  })
}

export const updateParcelHedging = (hedge) => {
  return superFetch({
    url: `couvertures/blocs/${hedge.id}`,
    method: 'PUT',
    body: hedge,
  })
}

export const deleteParcelHedging = (hedge) => {
  return superFetch({
    url: `couvertures/blocs/${hedge.id}`,
    method: 'DELETE',
  })
}
