import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Table, Badge } from 'reactstrap'
import moment from 'moment'

import { dateFormat } from 'helpers/formater'
import { fetchParcelDetail } from 'actions/parcels'
import { getParcelDetail } from 'reducers/parcels'
import { ContractArenh } from './ContractArenh'

const i18n = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 0,
})

const formatVolume = (volume, year) =>
  volume ? (volume < -1e100 ? 'N/A' : `${i18n.format(volume)} MWh/${year}`) : null

const ContractParcel = ({ selectedParcel, selectedContract, showTitle, isAdmin = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const parcelDetails = useSelector((state) => getParcelDetail(state, selectedParcel.id))

  useEffect(() => {
    dispatch(fetchParcelDetail(selectedContract.id, selectedParcel.id))
  }, [dispatch, selectedContract.id, selectedParcel.id])

  return (
    <div className="mb-3 contract-parcel">
      {showTitle && (
        <Row>
          <Col>
            <Badge>{selectedParcel.nom}</Badge>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Table bordered className="ctr-lot-infos">
            <tbody>
              <tr>
                <td>{t('strategy.contractDetail.start')}</td>
                <td>{moment(selectedParcel.dateDebut).format(dateFormat())}</td>
              </tr>
              <tr>
                <td>{t('strategy.contractDetail.end')}</td>
                <td>{moment(selectedParcel.dateFin).format(dateFormat())}</td>
              </tr>
              <tr>
                <td>{t('strategy.contractDetail.vol')}</td>
                <td>{formatVolume(parcelDetails.volumeAnnuel, t('time.year'))}</td>
              </tr>
              <tr>
                <td>{t('strategy.contractDetail.parcelType')}</td>
                <td>{selectedParcel.type}</td>
              </tr>
            </tbody>
          </Table>
          <h6>{t('strategy.contractDetail.priceform')}</h6>
          <Table bordered>
            <tbody>
              {parcelDetails.formulesPrix &&
                parcelDetails.formulesPrix.map((i, index) => (
                  <tr key={index}>
                    <td>{i}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {parcelDetails.IsArenh && (
            <>
              <h6>
                {t('strategy.contractDetail.arenh')}{' '}
                {moment(selectedParcel.dateDebut).format('YYYY')}
              </h6>
              <ContractArenh
                contractId={selectedContract.id}
                parcelId={parcelDetails.id}
                isAdmin={isAdmin}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ContractParcel
