import * as cot from 'api/quotations'

import {
  FETCH_AVAILABLE_QUOTATIONS_REQUEST,
  FETCH_AVAILABLE_QUOTATIONS_FAILURE,
  FETCH_AVAILABLE_QUOTATIONS_SUCCESS,
  FETCH_PARCEL_QUOTATIONS_REQUEST,
  FETCH_PARCEL_QUOTATIONS_SUCCESS,
  FETCH_PARCEL_QUOTATIONS_FAILURE,
  FETCH_LOT_QUOTATIONS_FOR_DEAL_REQUEST,
  FETCH_LOT_QUOTATIONS_FOR_DEAL_SUCCESS,
  FETCH_LOT_QUOTATIONS_FOR_DEAL_FAILURE,
  FETCH_CONTRACTS_QUOTATIONS_REQUEST,
  FETCH_CONTRACTS_QUOTATIONS_FAILURE,
  FETCH_CONTRACTS_QUOTATIONS_SUCCESS,
  FETCH_LOT_QUOTATION_GRAPH_REQUEST,
  FETCH_LOT_QUOTATION_GRAPH_SUCCESS,
  FETCH_LOT_QUOTATION_GRAPH_FAILURE,
  DELETE_QUOTATIONS_REQUEST,
  DELETE_QUOTATIONS_FAILURE,
  DELETE_QUOTATIONS_SUCCESS,
  ADD_QUOTATIONS_REQUEST,
  ADD_QUOTATIONS_FAILURE,
  ADD_QUOTATIONS_SUCCESS,
  SET_QUOTATION_GRAPH_STEP,
  FETCH_QUOTATION_GRAPH_REQUEST,
  FETCH_QUOTATION_GRAPH_FAILURE,
  FETCH_QUOTATION_GRAPH_SUCCESS,
  RESET_LOT_QUOTATION_GRAPH,
  FETCH_MY_QUOTATIONS_REQUEST,
  FETCH_MY_QUOTATIONS_SUCCESS,
  FETCH_MY_QUOTATIONS_FAILURE,
  ADD_MY_QUOTATIONS_REQUEST,
  ADD_MY_QUOTATIONS_SUCCESS,
  ADD_MY_QUOTATIONS_FAILURE,
  DELETE_MY_QUOTATION_REQUEST,
  DELETE_MY_QUOTATION_SUCCESS,
  DELETE_MY_QUOTATION_FAILURE,
  FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST,
  FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS,
  FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE,
  FETCH_QUOTATION_AND_PARCELS_REQUEST,
  FETCH_QUOTATION_AND_PARCELS_SUCCESS,
  FETCH_QUOTATION_AND_PARCELS_FAILURE,
  FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_REQUEST,
  FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_SUCCESS,
  FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_FAILURE,
  FETCH_AVAILABLE_DMR_QUOTATIONS_REQUEST,
  FETCH_AVAILABLE_DMR_QUOTATIONS_SUCCESS,
  FETCH_AVAILABLE_DMR_QUOTATIONS_FAILURE,
  FETCH_DMR_QUOTATIONS_REQUEST,
  FETCH_DMR_QUOTATIONS_SUCCESS,
  FETCH_DMR_QUOTATIONS_FAILURE,
  ADD_DMR_QUOTATIONS_REQUEST,
  ADD_DMR_QUOTATIONS_SUCCESS,
  ADD_DMR_QUOTATIONS_FAILURE,
  TOGGLE_DMR_QUOTATION_REQUEST,
  TOGGLE_DMR_QUOTATION_SUCCESS,
  TOGGLE_DMR_QUOTATION_FAILURE,
  DELETE_DMR_QUOTATION_REQUEST,
  DELETE_DMR_QUOTATION_SUCCESS,
  DELETE_DMR_QUOTATION_FAILURE,
  TOGGLE_QUOTATION_BOLLINGER,
  TOGGLE_QUOTATION_TUNNEL,
} from './'

export const fetchAvailableQuotations = (parcelId) => async (dispatch) => {
  dispatch({
    type: FETCH_AVAILABLE_QUOTATIONS_REQUEST,
    parcelId,
  })
  try {
    dispatch({
      type: FETCH_AVAILABLE_QUOTATIONS_SUCCESS,
      quotations: [], // await cot.fetchAvailableQuotations(parcelId),
      parcelId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_AVAILABLE_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addQuotations = (parcelId, quotations) => async (dispatch) => {
  dispatch({
    type: ADD_QUOTATIONS_REQUEST,
  })

  try {
    const rqQuotations = await cot.addQuotations(
      parcelId,
      quotations.map((q) => ({
        IdLot: parcelId,
        IdCotation: q.id,
        IsReference: false,
      })),
    )

    const contractQuotations = rqQuotations.map((cq) => ({
      ...cq,
      Cotation: quotations.find((q) => q.id === cq.IdCotation),
    }))

    dispatch({
      type: ADD_QUOTATIONS_SUCCESS,
      parcelId,
      quotations: contractQuotations,
    })
  } catch (e) {
    dispatch({
      type: ADD_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteQuotation = (parcelId, quotationId) => async (dispatch) => {
  dispatch({
    type: DELETE_QUOTATIONS_REQUEST,
  })
  try {
    await cot.deleteQuotation(parcelId, quotationId)
    dispatch({
      type: DELETE_QUOTATIONS_SUCCESS,
      parcelId,
      quotationId,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchParcelQuotations = (parcelId, debut, fin) => async (dispatch) => {
  dispatch({
    type: FETCH_PARCEL_QUOTATIONS_REQUEST,
    parcelId,
  })

  try {
    dispatch({
      type: FETCH_PARCEL_QUOTATIONS_SUCCESS,
      quotations: await cot.fetchParcelQuotations(parcelId, debut, fin),
      parcelId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_PARCEL_QUOTATIONS_FAILURE,
      parcelId,
      message: e.message,
    })
  }
}

export const fetchLotQuotationsForDeals = (parcelId, debut, fin) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_QUOTATIONS_FOR_DEAL_REQUEST,
    parcelId,
  })

  try {
    dispatch({
      type: FETCH_LOT_QUOTATIONS_FOR_DEAL_SUCCESS,
      quotations: await cot.fetchLotQuotationsForDeals(parcelId, debut, fin),
      parcelId,
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_QUOTATIONS_FOR_DEAL_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchContractsQuotations = (contractIds) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACTS_QUOTATIONS_REQUEST,
    contractIds,
  })

  try {
    dispatch({
      type: FETCH_CONTRACTS_QUOTATIONS_SUCCESS,
      quotations: await cot.fetchContractsQuotations(contractIds),
      contractIds,
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACTS_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const resetLotQuotationGraph = (lotId) => (dispatch) => {
  dispatch({
    type: RESET_LOT_QUOTATION_GRAPH,
    lotId,
  })
}

export const fetchLotQuotationGraph =
  (lotId, quotationId, showAlertes, showTransactions, showEvt) => async (dispatch) => {
    dispatch({
      type: FETCH_LOT_QUOTATION_GRAPH_REQUEST,
    })

    try {
      dispatch({
        type: FETCH_LOT_QUOTATION_GRAPH_SUCCESS,
        lotQuotationGraph: await cot.fetchLotQuotationGraph(
          lotId,
          quotationId,
          showAlertes,
          showTransactions,
          showEvt,
        ),
        lotId,
        quotationId,
        showAlertes,
        showTransactions,
      })
    } catch (e) {
      dispatch({
        type: FETCH_LOT_QUOTATION_GRAPH_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }

///////////////////////////////////////////////////////////////////////////////
// Onglet Market - Cotations Suivies par un utilisateur
///////////////////////////////////////////////////////////////////////////////

/**
 * Recupère les cotations disponibles pour le user connecte
 */
export const fetchMyAvailableQuotations = () => async (dispatch) => {
  dispatch({
    type: FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS,
      quotations: [], // await cot.fetchMyAvailableQuotations(),
    })
  } catch (e) {
    dispatch({
      type: FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Récupère les cotations suivies par l'utilisateur connecté
 */
export const fetchMyQuotations = () => async (dispatch) => {
  dispatch({
    type: FETCH_MY_QUOTATIONS_REQUEST,
  })

  try {
    dispatch({
      type: FETCH_MY_QUOTATIONS_SUCCESS,
      quotations: await cot.fetchMyQuotations(),
    })
  } catch (e) {
    dispatch({
      type: FETCH_MY_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Ajoute des cotations suivies pour l'utilisateur connecté
 */
export const addQuotationsForUser = (quotations) => async (dispatch) => {
  dispatch({
    type: ADD_MY_QUOTATIONS_REQUEST,
  })

  try {
    dispatch({
      type: ADD_MY_QUOTATIONS_SUCCESS,
      quotations: await cot.addQuotationForUser(quotations),
    })
  } catch (e) {
    dispatch({
      type: ADD_MY_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Supprime une cotation suivie par l'utilisateur connecté
 */
export const deleteMyQuotation = (quotationId) => async (dispatch) => {
  dispatch({
    type: DELETE_MY_QUOTATION_REQUEST,
  })
  try {
    await cot.deleteMyQuotation(quotationId)
    dispatch({
      type: DELETE_MY_QUOTATION_SUCCESS,
      quotationId,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_MY_QUOTATION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Options du graphe de cotation
 */

export const setGraphCotationStep = (step) => ({ type: SET_QUOTATION_GRAPH_STEP, payload: step })

/**
 * Charge le graph de cotation pour la cotation
 */
let cancelQuotationGraph = null
export const fetchQuotationGraph = (quotations, options) => async (dispatch) => {
  if (typeof cancelQuotationGraph === 'function') {
    cancelQuotationGraph()
  }

  try {
    dispatch({
      type: FETCH_QUOTATION_GRAPH_REQUEST,
      quotations: quotations.map((q) => q.Id),
    })

    const promise = cot.fetchQuotationGraph(quotations, options)
    cancelQuotationGraph = promise.cancel

    dispatch({
      type: FETCH_QUOTATION_GRAPH_SUCCESS,
      graph: await promise,
    })

    cancelQuotationGraph = null
  } catch (error) {
    if (error.__CANCEL__) return
    dispatch({
      type: FETCH_QUOTATION_GRAPH_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchParcelAndQuotations = (contractIds) => async (dispatch) => {
  dispatch({
    type: FETCH_QUOTATION_AND_PARCELS_REQUEST,
    contractIds,
  })
  try {
    dispatch({
      type: FETCH_QUOTATION_AND_PARCELS_SUCCESS,
      quotations: await cot.fetchParcelAndQuotations(contractIds),
      contractIds,
    })
  } catch (e) {
    dispatch({
      type: FETCH_QUOTATION_AND_PARCELS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchParcelAndQuotationsByPeriod =
  (contractIds, startDate, endDate) => async (dispatch) => {
    dispatch({
      type: FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_REQUEST,
      contractIds,
    })
    try {
      dispatch({
        type: FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_SUCCESS,
        quotations: await cot.fetchParcelAndQuotationsByPeriod(contractIds, startDate, endDate),
        contractIds,
        startDate,
        endDate,
      })
    } catch (e) {
      dispatch({
        type: FETCH_QUOTATION_AND_PARCELS_BY_PERIOD_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }

/**
 * Récupère les favoris disponibles de la DMR pour le client connecté
 */
export const fetchAvailableDmrQuotations = () => async (dispatch) => {
  dispatch({
    type: FETCH_AVAILABLE_DMR_QUOTATIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_AVAILABLE_DMR_QUOTATIONS_SUCCESS,
      quotations: [], // await cot.fetchAvailableDmrQuotations(),
    })
  } catch (e) {
    dispatch({
      type: FETCH_AVAILABLE_DMR_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Récupère les favoris de la DMR pour le client connecté
 */
export const fetchDmrQuotations = () => async (dispatch) => {
  dispatch({
    type: FETCH_DMR_QUOTATIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_DMR_QUOTATIONS_SUCCESS,
      quotations: await cot.fetchDmrQuotations(),
    })
  } catch (e) {
    dispatch({
      type: FETCH_DMR_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Ajoute des cotations annexes ppour la Daily Market Report de l'utilisateur connecté
 */
export const addDmrQuotations = (quotations) => async (dispatch) => {
  dispatch({
    type: ADD_DMR_QUOTATIONS_REQUEST,
  })

  try {
    dispatch({
      type: ADD_DMR_QUOTATIONS_SUCCESS,
      quotations: await cot.addDmrQuotations(quotations),
    })
  } catch (e) {
    dispatch({
      type: ADD_DMR_QUOTATIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Active ou désactive une cotation de la DMR pour l'utilisateur connecté
 */
export const toggleDmrQuotation = (quotationId) => async (dispatch) => {
  dispatch({
    type: TOGGLE_DMR_QUOTATION_REQUEST,
  })
  try {
    dispatch({
      type: TOGGLE_DMR_QUOTATION_SUCCESS,
      quotations: await cot.toggleDmrQuotation(quotationId),
    })
  } catch (e) {
    dispatch({
      type: TOGGLE_DMR_QUOTATION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

/**
 * Supprime une cotation annexe de la DMR pour l'utilisateur connecté
 */
export const deleteDmrQuotation = (quotationId) => async (dispatch) => {
  dispatch({
    type: DELETE_DMR_QUOTATION_REQUEST,
  })
  try {
    dispatch({
      type: DELETE_DMR_QUOTATION_SUCCESS,
      quotations: await cot.deleteDmrQuotation(quotationId),
    })
  } catch (e) {
    dispatch({
      type: DELETE_DMR_QUOTATION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const toggleQuotationBollinger = (id) => ({ type: TOGGLE_QUOTATION_BOLLINGER, id })
export const toggleQuotationTunnel = (id) => ({ type: TOGGLE_QUOTATION_TUNNEL, id })
