import React, { Component } from 'react'
import { Popover, PopoverBody, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Datetime from 'react-datetime'
import moment from 'moment'

import Select from 'components/common/Select'
import { fetchAlertTypes, fetchAlert, addAlert, updateAlert } from '../../../actions/alerts'
import { getAlertTypes, getParcelAlert } from '../../../reducers/alerts'
import { dateFormat } from '../../../helpers/formater'

// Type 1 = Volume à couvrir
// Type 2 = Limite haute
// Type 3 = Limite basse
// Type 4 = Sens
const parameterTypesToSkip = [1]

class AlertPopover extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alertType: null,
      alertParameters: [],
      isDailyAlert: 0,
      mailSupport: 0,
      mailAddresses: '',
      startDate: this.props.initialAlert ? null : moment(),
      endDate: this.props.initialAlert ? null : moment(),
    }
  }

  componentDidMount() {
    this.props.fetchAlertTypes()

    if (this.props.initialAlert) {
      // Need to fetch the detail of the alert if we don't already have it
      if (!this.props.initialAlert.Parametres) {
        this.props.fetchAlert(this.props.parcel.id, this.props.initialAlert.Id)
      } else if (this.props.alertTypes.length > 0) {
        this.initStateWithParameters(this.props.initialAlert, this.props.alertTypes)
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps, oldProps) {
    if (
      (!oldProps.fullAlert || !oldProps.alertTypes) &&
      newProps.fullAlert &&
      newProps.fullAlert.Parametres &&
      newProps.alertTypes
    ) {
      this.initStateWithParameters(newProps.fullAlert, newProps.alertTypes)
    }
  }

  initStateWithParameters = (alert, alertTypes) => {
    this.setState({
      alertType: alertTypes.find((at) => at.Id === alert.Modele.Id),
      mailSupport: alert.AlerteMail,
      mailAddresses: alert.ListeDiffusion,
      isDailyAlert: alert.AlerteQuotidienne,
      alertParameters: alert.Parametres,
      startDate: moment(alert.DateDebut),
      endDate: moment(alert.DateFin),
    })
  }

  setAlertType = (option) => {
    const model = this.props.alertTypes.find((item) => option.value === item.Id)
    const crossing = model.Parametres.find((p) => p.Type === 4)
    const alertParameters = crossing ? [{ AlerteModeleParamId: crossing.Id, Valeur: 0 }] : []
    this.setState({
      alertType: model,
      alertParameters: alertParameters,
    })
  }

  setParam = (parameter) => (e) => {
    const param =
      this.state.alertParameters.find((p) => p.AlerteModeleParamId === parameter.Id) || {}
    const alertParameters = this.state.alertParameters.filter(
      (p) => p.AlerteModeleParamId !== parameter.Id,
    )
    const valeur = e.target && e.target.value ? e.target.value : e.value ? e.value : undefined
    if (valeur) {
      alertParameters.push({
        ...param,
        AlerteModeleParamId: parameter.Id,
        Valeur: parseFloat(valeur),
      })
    }
    this.setState({ alertParameters })
  }

  onDailyAlertChange = (v) => () => {
    this.setState({ isDailyAlert: v })
  }

  toggleMailSupport = () => {
    this.setState({ mailSupport: !this.state.mailSupport })
  }

  setEmailAddress = (e) => {
    this.setState({ mailAddresses: e.target.value })
  }

  onChangeStartDate = (startDate) => {
    this.setState({ startDate })
  }

  onChangeEndDate = (endDate) => {
    this.setState({ endDate })
  }

  save = () => {
    let initAlert = this.props.initialAlert
      ? {
          ...this.props.initialAlert,
        }
      : {
          CotationSuivie: {
            Id: this.props.quotationId,
          },
        }

    const newAlert = {
      ...initAlert,
      Modele: {
        Id: this.state.alertType.Id,
      },
      ListeDiffusion: this.state.mailAddresses,
      AlerteMail: this.state.mailSupport,
      AlerteQuotidienne: this.state.isDailyAlert,
      DateDebut: this.state.startDate.format(),
      DateFin: this.state.endDate.format(),
      Parametres: this.state.alertParameters.map((param) => ({
        ...param,
      })),
    }

    if (this.props.initialAlert) {
      this.props.updateAlert(this.props.parcel.id, newAlert, this.props.realQuotationId)
    } else {
      this.props.addAlert(this.props.parcel.id, newAlert, this.props.realQuotationId)
    }

    this.props.togglePopover()
    this.props.reloadGraph(this.props.parcel.id)
  }

  render() {
    const options = this.props.alertTypes.map((item) => ({
      value: item.Id,
      label: item.Titre,
    }))

    const optionsPeriode = [
      {
        value: '1',
        label: this.props.t('strategy.alertPopover.periode.hebdo'),
      },
      {
        value: '2',
        label: this.props.t('strategy.alertPopover.periode.mensuel'),
      },
      {
        value: '3',
        label: this.props.t('strategy.alertPopover.periode.trimestriel'),
      },
    ]

    return (
      <Popover
        className="alert-popover"
        placement="left"
        isOpen={this.props.isOpen}
        target={
          this.props.initialAlert
            ? `editAlert-${this.props.initialAlert.Id}`
            : `addAlert-${this.props.quotationId}`
        }
        toggle={this.props.togglePopover}
      >
        <PopoverBody>
          <Row>
            <Col>
              <Button close onClick={this.props.togglePopover} />
              <h5>{this.props.t('strategy.alertPopover.addAlert')}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                width="100%"
                bordered
                placeholder={this.props.t('strategy.alertPopover.selectAlertModel')}
                value={
                  this.state.alertType === null
                    ? null
                    : options.find((option) => option.value === this.state.alertType.Id)
                }
                isClearable={false}
                onChange={this.setAlertType}
                options={options}
              />
            </Col>
          </Row>
          {this.state.alertType && (
            <div>
              {this.state.alertType.Parametres.filter(
                (p) => !parameterTypesToSkip.includes(p.Type),
              ).map((p) => {
                const existingParameter = this.state.alertParameters.find(
                  (ap) => ap.AlerteModeleParamId === p.Id,
                )
                return (
                  <Row key={p.Id}>
                    <Col>
                      <label className="title">{p.Titre}</label>
                      {p.Type === 4 ? (
                        <div className="radio-input form-inline">
                          <FormGroup>
                            <Input
                              id="sens1"
                              type="radio"
                              name="sens"
                              onChange={this.setParam(p)}
                              value="1"
                              checked={!!existingParameter && existingParameter.Valeur === 1}
                            />
                            <Label htmlFor="sens1">
                              {this.props.t('strategy.alertPopover.rise')}
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              id="sens2"
                              type="radio"
                              name="sens"
                              onChange={this.setParam(p)}
                              value="-1"
                              checked={!!existingParameter && existingParameter.Valeur === -1}
                            />
                            <Label htmlFor="sens2">
                              {this.props.t('strategy.alertPopover.fall')}
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              id="sens3"
                              type="radio"
                              name="sens"
                              onChange={this.setParam(p)}
                              value="0"
                              checked={!!existingParameter && existingParameter.Valeur === 0}
                            />
                            <Label htmlFor="sens3">
                              {this.props.t('strategy.alertPopover.both')}
                            </Label>
                          </FormGroup>
                        </div>
                      ) : p.Type === 12 ? (
                        <Select
                          width="100%"
                          bordered
                          placeholder={this.props.t('strategy.alertPopover.selectPeriod')}
                          value={
                            existingParameter
                              ? optionsPeriode.find(
                                  (option) => option.value === existingParameter.Valeur.toString(),
                                )
                              : optionsPeriode.find((option) => option.value === '2')
                          }
                          isClearable={false}
                          onChange={this.setParam(p)}
                          options={optionsPeriode}
                        />
                      ) : (
                        <Input
                          value={existingParameter ? existingParameter.Valeur : ''}
                          type="number"
                          onChange={this.setParam(p)}
                        />
                      )}
                    </Col>
                  </Row>
                )
              })}
            </div>
          )}
          <Row>
            <Col>
              <FormGroup>
                <label className="title" htmlFor="period">
                  {this.props.t('strategy.alertPopover.startDate')}
                </label>

                <Datetime
                  locale={this.props.lng}
                  closeOnSelect
                  timeFormat={false}
                  dateFormat={dateFormat()}
                  viewMode="days"
                  value={this.state.startDate ?? ''}
                  onChange={this.onChangeStartDate}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="title" htmlFor="period">
                  {this.props.t('strategy.alertPopover.endDate')}
                </label>
                <Datetime
                  locale={this.props.lng}
                  closeOnSelect
                  timeFormat={false}
                  dateFormat={dateFormat()}
                  viewMode="days"
                  value={this.state.endDate ?? ''}
                  onChange={this.onChangeEndDate}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{this.props.t('strategy.alertPopover.alertSupport')}</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-inline">
                <FormGroup>
                  <Label className={`form-check-label${this.state.mailSupport ? ' checked' : ''}`}>
                    <Input
                      className="form-check-input"
                      onChange={this.toggleMailSupport}
                      type="checkbox"
                    />
                    {this.props.t('strategy.alertPopover.email')}
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="title">{this.props.t('strategy.alertPopover.contactEmail')}</label>
              <Input
                type="email"
                value={this.state.mailAddresses ?? ''}
                onChange={this.setEmailAddress}
              />
            </Col>
          </Row>

          <Row>
            <Col className="text-right">
              <Button
                className="rounded"
                color="primary"
                onClick={this.save}
                disabled={false}
              >
                {this.props.t('strategy.alertPopover.save')}
              </Button>
            </Col>
          </Row>
        </PopoverBody>
      </Popover>
    )
  }
}

const mapStateToProps = (state, { parcel, initialAlert }) => ({
  alertTypes: getAlertTypes(state),
  fullAlert: initialAlert ? getParcelAlert(state, parcel.id, initialAlert.Id) : null,
})

const mapDispatchToProps = {
  fetchAlertTypes,
  fetchAlert,
  addAlert,
  updateAlert,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(AlertPopover)
