import {
  fetchContractTransactions as apiFetchContractTransactions,
  fetchLotTransactions as apiFetchLotTransactions,
  fetchLotPeriodeTransactions as apiFetchLotPeriodeTransactions,
  fetchLotQuotationTransactions as apiFetchLotQuotationTransactions,
  addTransaction as apiAddTransaction,
  updateTransaction as apiUpdateTransaction,
  deleteTransaction as apiDeleteTransaction,
} from '../api/transactions'

import {
  FETCH_CONTRACT_TRANSACTIONS_REQUEST,
  FETCH_CONTRACT_TRANSACTIONS_FAILURE,
  FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
  FETCH_LOT_TRANSACTIONS_REQUEST,
  FETCH_LOT_TRANSACTIONS_FAILURE,
  FETCH_LOT_TRANSACTIONS_SUCCESS,
  FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST,
  FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE,
  FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS,
  FETCH_LOT_QUOTATION_TRANSACTIONS_REQUEST,
  FETCH_LOT_QUOTATION_TRANSACTIONS_FAILURE,
  FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
} from './'

export const fetchContractTransactions = (contractId, quotationId) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
      contractId,
      transactions: await apiFetchContractTransactions(contractId, quotationId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchLotTransactions = (lotId) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_TRANSACTIONS_SUCCESS,
      lotId,
      transactions: await apiFetchLotTransactions(lotId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchLotPeriodeTransactions = (lotId, debut, fin) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS,
      lotId,
      transactions: await apiFetchLotPeriodeTransactions(lotId, debut, fin),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchLotQuotationTransactions = (lotId, cotId) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_QUOTATION_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS,
      lotId,
      cotId,
      transactions: await apiFetchLotQuotationTransactions(lotId, cotId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_QUOTATION_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addTransaction = (lotId, contractId, transaction) => async (dispatch) => {
  dispatch({
    type: ADD_TRANSACTION_REQUEST,
  })
  try {
    dispatch({
      type: ADD_TRANSACTION_SUCCESS,
      lotId,
      contractId,
      transaction: await apiAddTransaction(lotId, transaction),
    })
  } catch (e) {
    dispatch({
      type: ADD_TRANSACTION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateTransaction = (lotId, contractId, transaction) => async (dispatch) => {
  dispatch({
    type: UPDATE_TRANSACTION_REQUEST,
  })
  try {
    dispatch({
      type: UPDATE_TRANSACTION_SUCCESS,
      lotId,
      contractId,
      transaction: await apiUpdateTransaction(lotId, transaction),
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: UPDATE_TRANSACTION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteTransaction =
  (lotId, contractId, transactionId, message) => async (dispatch) => {
    if (!window.confirm(message)) return

    dispatch({
      type: DELETE_TRANSACTION_REQUEST,
      lotId,
      contractId,
      transactionId,
    })
    try {
      const rq = await apiDeleteTransaction(lotId, transactionId)
      if (rq.status === 200) {
        dispatch({
          type: DELETE_TRANSACTION_SUCCESS,
          lotId,
          contractId,
          transactionId,
        })
      }
    } catch (e) {
      console.error(e)
      dispatch({
        type: DELETE_TRANSACTION_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }
