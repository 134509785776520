import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import moment from 'moment'
import flatten from 'lodash/flatten'

import { uppercaseFirst, dateFormat, decimalFormat } from '../helpers/formater'

import {
  FETCH_CONTRACT_ALERTS_SUCCESS,
  FETCH_ALERT_SUCCESS,
  FETCH_MULTIPLE_CONTRACT_ALERTS_REQUEST,
  FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS,
  FETCH_MULTIPLE_CONTRACT_ALERTS_FAILURE,
  FETCH_PARCEL_ALERTS_SUCCESS,
  FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS,
  FETCH_ALERT_TYPES_SUCCESS,
  ADD_ALERT_SUCCESS,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_TOGGLE_DAILY_REQUEST,
  UPDATE_ALERT_TOGGLE_MAIL_REQUEST,
  DELETE_ALERT_SUCCESS,
  DELETE_QUOTATIONS_SUCCESS,
  FETCH_ALERTS_GRAPH_SUCCESS,
  FETCH_ALERTS_EVENTS_REQUEST,
  FETCH_ALERTS_EVENTS_SUCCESS,
  FETCH_ALERTS_EVENTS_FAILURE,
} from '../actions'

const alerts = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_CONTRACT_ALERTS_SUCCESS:
      newState = { ...state }
      newState[action.contractId] = action.alerts
      return newState
    default:
      return state
  }
}

const alertTypes = (state = [], action) => {
  switch (action.type) {
    case FETCH_ALERT_TYPES_SUCCESS:
      return action.alerts
    default:
      return state
  }
}

// For the currently visible contracts
const currentSelectionAlerts = (state = [], action) => {
  let newState
  switch (action.type) {
    case FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS:
      newState = action.alerts
      return newState
    case DELETE_ALERT_SUCCESS:
      return state.filter((alert) => alert.Id !== action.alertId)
    default:
      return state
  }
}

const alertGraph = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_ALERTS_GRAPH_SUCCESS:
      newState = { ...state }
      newState[action.followedQuotationId] = action.alertGraph
      return newState
    default:
      return state
  }
}

const currentSelectionAlertsLoading = (state = false, action) => {
  switch (action.type) {
    case FETCH_MULTIPLE_CONTRACT_ALERTS_REQUEST:
      return true
    case FETCH_MULTIPLE_CONTRACT_ALERTS_FAILURE:
    case FETCH_MULTIPLE_CONTRACT_ALERTS_SUCCESS:
      return false
    default:
      return state
  }
}

const parcelAlerts = (state = {}, action) => {
  let newState, newAlertsOfContract, index
  switch (action.type) {
    case FETCH_PARCEL_ALERTS_SUCCESS:
      newState = { ...state }
      newState[action.parcelId] = action.alerts
      return newState
    case FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS:
      return {
        ...state,
        [action.parcelId]: action.alerts,
      }
    case ADD_ALERT_SUCCESS: {
      const alerts = state[action.parcelId] || []
      return {
        ...state,
        [action.parcelId]: [...alerts, action.alert],
      }
    }
    case UPDATE_ALERT_SUCCESS:
    case FETCH_ALERT_SUCCESS:
    case UPDATE_ALERT_TOGGLE_DAILY_REQUEST:
    case UPDATE_ALERT_TOGGLE_MAIL_REQUEST:
      newState = { ...state }
      newAlertsOfContract = [...newState[action.parcelId]]
      index = newAlertsOfContract.findIndex((alert) => alert.Id === action.alert.Id)
      newAlertsOfContract[index] = action.alert
      newState[action.parcelId] = newAlertsOfContract
      return newState
    case DELETE_ALERT_SUCCESS:
      newState = { ...state }
      if (newState[action.parcelId]) {
        newState[action.parcelId] = newState[action.parcelId].filter(
          (alert) => alert.Id !== action.alertId,
        )
      }
      return newState
    case DELETE_QUOTATIONS_SUCCESS:
      newState = { ...state }
      newAlertsOfContract = newState[action.parcelId].filter(
        (alert) => alert.CotationSuivie.Cotation.id !== action.quotationId,
      )
      newState[action.parcelId] = newAlertsOfContract
      return newState
    default:
      return state
  }
}

const parcelAlertsByQuot = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PARCEL_QUOTATION_ALERTS_SUCCESS:
      return {
        ...state,
        [action.parcelId]: {
          ...state[action.parcelId],
          [action.quotationId]: action.alerts,
        },
      }
    default:
      return state
  }
}

const events = (
  state = {
    isLoading: false,
    error: false,
    list: [],
  },
  action,
) => {
  switch (action.type) {
    case FETCH_ALERTS_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    case FETCH_ALERTS_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        list: action.events,
      }
    case FETCH_ALERTS_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: true,
        error: true,
        list: [],
      }
    default:
      return state
  }
}

export const getContractAlerts = (state, contractId) => state.alerts.alerts[contractId]

export const getParcelAlerts = (state, parcelId) => state.alerts.parcelAlerts[parcelId]

export const getAlertTypes = (state) => state.alerts.alertTypes

export const getAlert = (state, contractId, alertId) =>
  state.alerts.alerts[contractId].find((a) => a.Id === alertId)

export const getAlertGraph = (state, alertId) => (alertId ? state.alerts.alertGraph[alertId] : null)

export const getParcelAlert = (state, parcelId, alertId) =>
  state.alerts.parcelAlerts[parcelId].find((a) => a.Id === alertId)

export const getAlertsLoading = (state) => state.alerts.currentSelectionAlertsLoading
export const getAlerts = (state) => state.alerts.currentSelectionAlerts

export const getAlertValues = (state) => {
  const alerts = flatten(Object.values(state.alerts.parcelAlerts))
  if (!alerts) return {}
  return alerts.reduce((acc, a) => {
    return {
      ...acc,
      [a.Id]: a.DernieresValeurs
        ? a.DernieresValeurs.map((p) => `${decimalFormat(p)}`).join('-')
        : ' - ',
    }
  }, {})
}

export const getAlertsTableData = createSelector(getAlerts, (alerts) => {
  const dataByProductId = alerts.reduce((acc, alert) => {
    const {
      Id: alertId,
      DateDebut: startDate,
      DateFin: endDate,
      NbEvenements: eventCount,
      Libelle: alertName,
      CotationSuivie: {
        IdLot: parcelId,
        LotNom: parcelName,
        Contrat: { NomCourt: contractName },
        Cotation: {
          id: quotationId,
          CotProduit: { id: productId, PdtNom: productName, PdtNrjId: nrjId },
        },
      },
      DernieresValeurs,
    } = alert

    const period = `${moment(startDate).format(dateFormat())} - ${moment(endDate).format(
      dateFormat(),
    )}`

    const alertIds = [...(acc[alertId] ? acc[alertId].alertIds : []), alertId]

    const alertItem = {
      productId,
      nrjId,
      contractName,
      parcelName,
      parcelId,
      quotationId,
      productName,
      alertIds,
      id: alertId,
      alertName,
      period,
      eventCount,
      value: DernieresValeurs
        ? DernieresValeurs.map((p) => `${decimalFormat(p)}`).join('-')
        : ' - ',
    }

    return {
      ...acc,
      [alertId]: alertItem,
    }
  }, {})

  return Object.values(dataByProductId)
})

export const getEventsLoading = (state) => state.alerts.events.isLoading
export const getEventList = (state) => state.alerts.events.list

export const getEventsTableData = createSelector(getEventList, getAlerts, (list, alerts) => {
  return list.map((e) => {
    const alert = alerts.find((a) => a.Id === e.AlerteId)

    if (!alert) return {}

    const {
      Libelle: alertName,
      CotationSuivie: {
        LotNom: parcelName,
        Contrat: { NomCourt: contractName },
        Cotation: {
          CotProduit: { PdtNom: productName, PdtNrjId: nrjId },
        },
      },
    } = alert

    return {
      nrjId,
      contractName,
      parcelName,
      productName,
      alertName,
      eventDateFormatted: moment(e.DateEvenement).format(dateFormat()),
      eventDate: e.DateEvenement,
      level: e.AlerteNiveauLibelle || '-',
      direction: uppercaseFirst(e.SensLibelle),
      price: decimalFormat(e.ValeurProduit),
      refPrice: decimalFormat(e.ValeurComparaison),
    }
  })
})

export default combineReducers({
  alerts,
  alertTypes,
  alertGraph,
  parcelAlerts,
  parcelAlertsByQuot,
  currentSelectionAlerts,
  currentSelectionAlertsLoading,
  events,
})
