/** @jsxImportSource @emotion/react */
import React from 'react'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { lighten } from 'polished'

import { emptyAllSelection } from 'actions/contractSelection'

const EmptySelection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div
      css={(theme) => ({
        display: 'flex',
        color: lighten(0.2, theme.gold),
        button: {
          color: 'inherit',
          '&:focus, &:hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      })}
    >
      <Button style={{ background: "none", color: 'black', border: 'none' }} onClick={() => dispatch(emptyAllSelection())}>
        <span>{t('sidebar.btn.empty')}</span>
      </Button>
    </div>
  )
}

export default EmptySelection
