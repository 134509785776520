/** @jsxImportSource @emotion/react */

export const PageFooter = ({ index, total }) => {
  return (
    <footer
      css={{
        position: 'absolute',
        bottom: '0.4cm',
        right: '0.8cm',
        fontSize: 10,
      }}
    >
      {total ? `Page ${index}/${total}` : `Page ${index}`}
    </footer>
  )
}
