import { combineReducers } from 'redux'
import { reducer as oidc } from 'redux-oidc'
import { userReducer } from 'commons'
import get from 'lodash/get'

import contracts from './contracts'
import countries from './countries'
import providers from './providers'
import parcels from './parcels'
import hedgingProfile from './hedgingProfile'
import hedgingPlan from './hedgingPlan'
import alerts from './alerts'
import quotations from './quotations'
import transactions from './transactions'
import performance from './performance'
import execution from './execution'
import deals from '../store/deals'
import searchFilters from '../store/searchFilters'
import reporting from '../store/reporting'
import perf from '../store/perf'
import market from '../store/market'

const app = combineReducers({
  oidc,
  user: userReducer,
  searchFilters,
  countries,
  providers,
  contracts,
  parcels,
  hedgingProfile,
  hedgingPlan,
  quotations,
  alerts,
  transactions,
  performance,
  execution,
  deals,
  reporting,
  perf,
  market,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT_SUCCESS') {
    return app(undefined, action)
  }

  if (action.type === 'LOAD_PRESISTED_STATE') {
    state = {
      ...action.persistedState,
      oidc: state.oidc,
    }
  }

  return app(state, action)
}

export default rootReducer
export const getUor = (state) => get(state, ['oidc', 'user', 'profile', 'uor'], false)
