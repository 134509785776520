import {
  fetchParcelHedging as apifetchParcelHedging,
  addParcelHedging as apiAddParcelHedging,
  updateParcelHedging as apiUpdateParcelHedging,
  deleteParcelHedging as apiDeleteParcelHedging,
} from '../api/hedgingProfile'
import {
  FETCH_PARCEL_EDGES_REQUEST,
  FETCH_PARCEL_EDGES_FAILURE,
  FETCH_PARCEL_EDGES_SUCCESS,
  ADD_PARCEL_EDGES_REQUEST,
  ADD_PARCEL_EDGES_FAILURE,
  ADD_PARCEL_EDGES_SUCCESS,
  UPDATE_PARCEL_EDGES_REQUEST,
  UPDATE_PARCEL_EDGES_FAILURE,
  UPDATE_PARCEL_EDGES_SUCCESS,
  DELETE_PARCEL_EDGES_REQUEST,
  DELETE_PARCEL_EDGES_SUCCESS,
  DELETE_PARCEL_EDGES_FAILURE,
} from './'

export const fetchParcelHedging = (contractId, parcelId) => async (dispatch) => {
  dispatch({
    type: FETCH_PARCEL_EDGES_REQUEST,
  })

  try {
    const profile = await apifetchParcelHedging(contractId, parcelId)

    dispatch({
      type: FETCH_PARCEL_EDGES_SUCCESS,
      parcelId,
      blocks: profile.listeBloc,
      profile: {
        libelle: profile.libelle,
        lotId: profile.lotId,
        stcId: profile.stcId,
        id: profile.id,
      },
    })
  } catch (e) {
    dispatch({
      type: FETCH_PARCEL_EDGES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addParcelHedging = (parcelId, hedging) => async (dispatch) => {
  dispatch({
    type: ADD_PARCEL_EDGES_REQUEST,
    block: hedging,
    parcelId,
  })

  try {
    const hedges = []
    hedging.blocks.forEach((block) => {
      hedges.push({
        ProfilId: hedging.ProfilId,
        perTypeCode: block.hedgeTypeCode,
        pas: hedging.pas,
        perAnnee: block.hedgeTypeYear,
        perTypeLongueur: block.hedgeTypeLength,
        perTypeNum: block.hedgeTypeNum,
        valeur: hedging.valeur,
        isArenh: block.isArenh,
      })
    })

    dispatch({
      type: ADD_PARCEL_EDGES_SUCCESS,
      block: await apiAddParcelHedging(hedges),
      parcelId,
    })
  } catch (e) {
    dispatch({
      type: ADD_PARCEL_EDGES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateParcelHedging = (parcelId, hedging) => async (dispatch) => {
  dispatch({
    type: UPDATE_PARCEL_EDGES_REQUEST,
    block: hedging,
    parcelId,
  })

  try {
    dispatch({
      type: UPDATE_PARCEL_EDGES_SUCCESS,
      block: await apiUpdateParcelHedging(hedging),
      parcelId,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: UPDATE_PARCEL_EDGES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteParcelHedging = (parcelId, hedging) => async (dispatch) => {
  dispatch({
    type: DELETE_PARCEL_EDGES_REQUEST,
    block: hedging,
    parcelId,
  })

  try {
    const rq = await apiDeleteParcelHedging(hedging)

    if (rq.status === 200) {
      dispatch({
        type: DELETE_PARCEL_EDGES_SUCCESS,
        parcelId,
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_PARCEL_EDGES_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}
