/** @jsxImportSource @emotion/react */
import flatten from 'lodash/flatten'
import range from 'lodash/range'
import sortBy from 'lodash/sortBy'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import orderBy from 'lodash/orderBy'
// import sum from 'lodash/sum'

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import html2canvas from 'html2canvas'
import moment from 'moment'

import { fetchAllContractParcels } from 'actions/parcels'
import { fetchParcelAndQuotationsByPeriod } from 'actions/quotations'
import { getAllQuotationsByPeriod } from 'reducers/quotations'
import { getContractList } from 'reducers/contracts'
import { getParcelsByContract } from 'reducers/parcels'
import { getByParcel, getFilteredSelection, getStartYear, getEndYear } from 'store/reporting'

import Page1 from './Page1'
import Page2 from './Page2'
import ReportingPageContract from './ReportingPageContract'
import ReportingPage from './ReportingPage'

const useFetchData = (selection, startDate, endDate) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(fetchAllContractParcels(selection))
    dispatch(fetchParcelAndQuotationsByPeriod(selection, startDate, endDate))
  }, [dispatch, endDate, selection, startDate])
}

export const Reporting = ({ addImages, clientName }) => {
  const pageIndex = React.useRef(0)
  const [index, setIndex] = React.useState(0)
  const [done, setDone] = React.useState(false)

  const startYear = useSelector(getStartYear)
  const endYear = useSelector(getEndYear)

  const startDate = moment(startYear, 'YYYY')
  const endDate = moment(endYear, 'YYYY').endOf('year')

  const byParcel = useSelector(getByParcel)

  const printRef = React.useRef(null)
  const pageRef = React.useRef(null)

  const selection = useSelector(getFilteredSelection)
  useFetchData(selection, startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY'))

  const contractList = useSelector(getContractList)
  const unsortedQuotations = useSelector(getAllQuotationsByPeriod)
  const quotations = orderBy(
    unsortedQuotations,
    ['Cotation.Periode.PerTypeLongueur', 'Cotation.CotProduit.PdtId', 'Cotation.Periode.PerType'],
    ['desc', 'asc', 'asc'],
  )

  const parcelsByContract = mapValues(useSelector(getParcelsByContract(selection)), (parcels) =>
    parcels.filter(
      (parcel) =>
        !(moment(parcel.dateFin).isBefore(startDate) || moment(parcel.dateDebut).isAfter(endDate)),
    ),
  )

  const { contracts, pageList } = React.useMemo(() => {
    const list = contractList.filter((c) => selection.includes(c.id))
    const loaded = list.length === Object.keys(parcelsByContract).length
    if (!loaded) return { contracts: {}, pageList: [] }

    const contracts = keyBy(list, 'id')

    const pageList = Object.entries(parcelsByContract).reduce((acc, [contractId, parcels]) => {
      const c = contracts[contractId]
      const s = c ? Math.max(moment(c.CtrDateDebut, 'DD/MM/YYYY').year(), startYear) : startYear
      const e = c ? Math.min(moment(c.CtrDateFin, 'DD/MM/YYYY').year(), endYear) : endYear
      const years = range(s, e + 1)

      const p = parcels.map((parcel) => {
        return years.map((year) => ({
          ...parcel,
          year,
          dateDebut: moment(year, 'YYYY').startOf('year').format(),
          dateFin: moment(year, 'YYYY').endOf('year').format(),
        }))
      })

      const parcelsWithQuotations = flatten(p)
        .filter(
          (parcel) =>
            moment(parcel.dateFin).isSameOrAfter(startDate) ||
            moment(parcel.dateDebut).isSameOrBefore(endDate),
        )
        .map((parcel) => ({
          ...parcel,
          contractId: Number(contractId),
          quotations: quotations.filter(
            (q) => q.IdLot === parcel.id && parcel.year === q.Cotation.CotAnnee,
          ),
        }))

      if (!byParcel.includes(Number(contractId))) {
        return [
          ...acc,
          ...years.map((year) => {
            const start = moment(year.toString(), 'YYYY').startOf('year')
            const end = moment(year.toString(), 'YYYY').endOf('year')
            return {
              byContract: true,
              year,
              contractId: Number(contractId),
              parcelsWithQuotations: parcelsWithQuotations.filter(
                (p) => !(moment(p.dateFin).isBefore(start) || moment(p.dateDebut).isAfter(end)),
              ),
            }
          }),
        ]
      }

      const syntheses = years.map((year) => ({
        synthese: true,
        year,
        contractId: Number(contractId),
        quotations: [],
      }))

      return [...acc, ...parcelsWithQuotations, ...syntheses]
    }, [])

    return { contracts, pageList: sortBy(pageList, 'year') }
  }, [
    byParcel,
    contractList,
    endDate,
    endYear,
    parcelsByContract,
    quotations,
    selection,
    startDate,
    startYear,
  ])

  const pageCount = pageList.length + 2

  const getImage = React.useCallback(
    (i) => () => {
      if (pageRef.current === null) return
      const elements = [...pageRef.current.querySelectorAll('.page')]
      pageIndex.current += elements.length
      Promise.all(elements.map((e) => html2canvas(e, { scale: 1.7 }))).then((canvas) => {
        const images = canvas.map((c) => c.toDataURL('image/jpeg', 0.9))

        canvas.forEach((c) => {
          if (printRef.current === null) return
          printRef.current.appendChild(c)
        })

        addImages(i, images, pageCount)

        if (i < pageCount - 1) {
          setIndex((index) => index + 1)
        } else {
          setDone(true)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageCount],
  )

  if (pageList.length === 0) return null
  const page = pageList[index - 2]

  return (
    <div>
      <div
        ref={printRef}
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '>*': { margin: '5px 0' },
        }}
      />

      {!done && (
        <div ref={pageRef}>
          {index === 0 ? (
            <Page1 onLoad={getImage(0)} clientName={clientName} />
          ) : index === 1 ? (
            <Page2 onLoad={getImage(1)} pageIndex={pageIndex.current} />
          ) : page.byContract ? (
            <ReportingPageContract
              contract={contracts[page.contractId]}
              parcels={page.parcelsWithQuotations}
              onLoad={getImage(index)}
              index={index}
              year={page.year}
              pageIndex={pageIndex.current}
            />
          ) : (
            <ReportingPage
              parcel={page}
              contract={contracts[page.contractId]}
              onLoad={getImage(index)}
              index={index}
              pageIndex={pageIndex.current}
            />
          )}
        </div>
      )}
    </div>
  )
}
